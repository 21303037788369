import RootStore from './RootStore'
import { transport, location, gaEvents, channel, sessionStorage } from '../../../../lib/services'
import { eventBus } from '../../../../lib/utils/dom'

const store = new RootStore({
  transport,
  location,
  gaEvents,
  channel,
  sessionStorage,
  eventBus
})

export default store
