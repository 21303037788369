import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { Page } from '../stores/UIStateStore'
import { useStores } from '../../../shared_components/Hooks'

const PasswordResetConfirm = () => {
  const { store: { userStore, uiStateStore } } = useStores()
  const signInButtonRef = useRef(null)

  useEffect(() => {
    signInButtonRef.current.focus()
  }, [])

  return (
    <form className='Form' onSubmit={userStore.handleResetPassword}>
      <div className='Form-wrap Form-wrap--extraSpace'>
        <div className='Text Text--modalTitle Text--alignCenter'>
          Password Reset
        </div>
        <div className='Form-row Form-row--smallSpace'>
          <div className='Text Text--a1 Text--lineHeight19px Text--alignCenter Text--sidePadding'>
            Thanks! We’ve sent a link with instructions for you to reset your password to
          </div>
        </div>
        <div className='Form-row Form-row--mediumSpace'>
          <div className='Text Text--a1 Text--lineHeight19px Text--alignCenter Text--fontWeightMedium Text--black'>
            {userStore.email}
          </div>
        </div>
        <button
          type='submit'
          className='Button Button--primary'
          ref={signInButtonRef}
          onClick={() => {
            uiStateStore.updateCurrentView(Page.signin)
          }}
        >
          Sign In
        </button>
      </div>
    </form>
  )
}

export default observer(PasswordResetConfirm)
