import React, { useEffect } from 'react'
import { Provider } from 'mobx-react'
import rootStoreInstance from '../stores/rootStoreInstance'
import ListingDetails from './ListingDetails'
import { withErrorBoundary } from '../../Shared/ErrorBoundary'

const StateFulListingDetails = props => {
  useEffect(()=>{
    rootStoreInstance.setInitialDataListingDetails(props)
  }, [props])

  return (
    <Provider store={rootStoreInstance}>
      <ListingDetails/>
    </Provider>
  )
}

export default withErrorBoundary(StateFulListingDetails)
