import React from 'react'
import { Provider } from 'mobx-react'
import SortingSelector from './SortingSelector'
import { rootStoreInstance } from '../stores'

const StatefulSortingSelector = () => (
  <Provider store={rootStoreInstance}>
    <SortingSelector />
  </Provider>
)

export default StatefulSortingSelector
