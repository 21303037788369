import React from 'react'
import PropTypes from 'prop-types'
import { withHandlers, compose } from 'recompose'

const enhance = compose(
  withHandlers({
    onRadioButtonChange: ({ onChange }) => e => {
      onChange(e.target.value)
    },
    isChecked: ({ value }) => option => {
      return value === option.value || (!value && !option.value)
    }
  })
)

const RectangleRadioButtonGroup = ({ options, onRadioButtonChange, name, legend, isChecked }) => (
  <fieldset className='RectangleRadioButtonGroup'>
    <legend className='Label Label--mb16'>
      {legend}
    </legend>
    <div className='RectangleRadioButtonGroup-radioInputs'>
      {
        options.map(option => (
          <div key={option.label} className='RectangleRadioButtonGroup-option'>
            <label className='RectangleRadioButtonGroup-label' htmlFor={`${name}-${option.value}`}>
              <input
                id={`${name}-${option.value}`}
                name={name}
                className='RectangleRadioButtonGroup-input'
                type='radio'
                checked={isChecked(option)}
                value={option.value}
                onChange={onRadioButtonChange}
                aria-checked={isChecked(option)}
              />
              <span className='RectangleRadioButtonGroup-content'>
                {option.label}
              </span>
            </label>
          </div>
        ))
      }
    </div>
  </fieldset>
)

RectangleRadioButtonGroup.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  legend: PropTypes.string.isRequired,
  isChecked: PropTypes.func,
  onRadioButtonChange: PropTypes.func.isRequired
}

export default enhance(RectangleRadioButtonGroup)
