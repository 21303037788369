import React from 'react'
import ViewMoreText from '../../Shared/ViewMoreText'
import { useStores } from '../../../shared_components/Hooks'
import { observer } from 'mobx-react'

const ListingDescription = () => {
  const { store: { hdpStore } } = useStores()

  return (
    <div className='Text Text--a1 Text--lineHeight21px' data-test-id='description-text'>
      <ViewMoreText length={450} htmlSafe={true}>
        {hdpStore.description}
      </ViewMoreText>
    </div>
  )
}

export default observer(ListingDescription)
