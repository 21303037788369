import React from 'react'
import { branch, renderComponent, renderNothing } from 'recompose'
import PropTypes from 'prop-types'
import cn from 'classnames'

const ControlsContainer = ({ prevButtonRef, nextButtonRef, controlsClassName }) => (
  <ul className={cn('SimpleSlider-controls', controlsClassName)}>
    <li>
      <button className='SimpleSlider-control SimpleSlider-control--prev' ref={prevButtonRef}>
        <span className='sr-only'>Previous Slide</span>
      </button>
    </li>
    <li>
      <button className='SimpleSlider-control SimpleSlider-control--next' ref={nextButtonRef}>
        <span className='sr-only'>Previous Slide</span>
      </button>
    </li>
  </ul>
)

ControlsContainer.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  settings: PropTypes.shape({
    items: PropTypes.number
  }),
  controlsClassName: PropTypes.string,
  prevButtonRef: PropTypes.object.isRequired,
  nextButtonRef: PropTypes.object.isRequired
}

export default branch(
  ({ settings, itemsCount }) => (settings.items < itemsCount),
  renderComponent(ControlsContainer),
  renderNothing
)()
