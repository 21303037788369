import React from 'react'
import PropTypes from 'prop-types'
import { PriceInput } from '../../../../Shared/Form'
import { priceLabelWithCutOff } from '../../../../../lib/utils/money'

const Price = ({ uiStateStore, valueStateStore, view }) => (
  <fieldset className='ListingForm-groupFieldset'>
    <legend className='ListingForm-groupLabel'>
      Price
    </legend>
    <div className='ListingForm-fields'>
      <div className='ListingForm-fieldRow'>
        <PriceInput
          priceExtremum={ uiStateStore.priceExtremum }
          values={valueStateStore.priceValues}
          onChange={valueStateStore.setInputValue}
          onReset={valueStateStore.resetInputs}
          handleTemplate={priceLabelWithCutOff}
          view={view}
        />
      </div>
    </div>
  </fieldset>
)

Price.propTypes = {
  valueStateStore: PropTypes.object.isRequired,
  uiStateStore: PropTypes.object.isRequired,
  view: PropTypes.string.isRequired
}

export default Price
