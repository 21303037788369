import React from 'react'
import { Provider } from 'mobx-react'
import rootStoreInstance from '../stores/rootStoreInstance'
import ListingDescription from './ListingDescription'
import ErrorBoundary from '../../Shared/ErrorBoundary'

const StateFulListingDescription = (props) => {
  rootStoreInstance.setInitialDataListingDescription(props)

  return (
    <ErrorBoundary>
      <Provider store={rootStoreInstance}>
        <ListingDescription/>
      </Provider>
    </ErrorBoundary>
  )
}

export default StateFulListingDescription
