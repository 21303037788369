import { action, observable, computed, makeObservable } from 'mobx'
import { isPresent } from '../../../lib/utils/collection'
import { makeId } from '../../../lib/utils/common'

class PriceHistoryStore {
  @observable priceHistories = []

  constructor({ gaEvents }) {
    makeObservable(this)

    this.gaEvents = gaEvents
  }

  @action('[PriceHistoryStore] Set Initial Data')
  setInitialData = ({ priceHistories }) => {
    this.priceHistories = priceHistories
  }

  @computed get collection() {
    return this.priceHistories.map((item, index) => {
      const isHidden = index > 3
      const id = makeId()
      return {...item, isHidden, id}
    })
  }

  @computed get hasHistory() {
    return isPresent(this.priceHistories)
  }

  @computed get hasDisclosure() {
    return this.priceHistories.length > 4
  }

  sendShowHistoryAnalytics = () => {
    this.gaEvents.Hdp.showSalePriceHistory()
  }
}

export default PriceHistoryStore
