import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import Form from 'react-formal'
import cn from 'classnames'
import * as R from 'ramda'
import { noop } from '../../../lib/utils/common'
import { signUpUserSchema } from '../../../schemas/user'
import { ErrorMessage } from '../../Shared/Form'
import { FillCheck, CloseUrl } from '../../Svg'

const CreateUserForm = ({
  deactivateContactForm,
  userStore,
  onFormChange,
  className,
  hasCloseButton,
  onSignIn,
  setFocusToButton = noop
}) => {
  const emailField = useRef(null)
  const passwordField = useRef(null)
  const formFields = useRef([emailField, passwordField])

  const focusFirstInvalidField = errors => {
    const fields = R.pluck('current', formFields.current)
    for (let field of fields) {
      if (field.name in errors) {
        field.focus()
        break
      }
    }
  }

  const handleInvalidSubmit = errors => {
    userStore.setErrors(errors)
    focusFirstInvalidField(errors)
  }

  useEffect(() => {
    global.requestAnimationFrame(() => {
      emailField.current.focus()
    })
  }, [])

  useEffect(() => {
    global.requestAnimationFrame(() => {
      focusFirstInvalidField(userStore.formErrors)
    })

    return () => {
      global.requestAnimationFrame(() => {
        setFocusToButton()
      })
    }
  }, [userStore.formErrors])

  return (
    <div className={cn('ContactForm', className)}>
      <div className='ContactForm-content'>
        {hasCloseButton &&
          <button type='button' className='ContactForm-closeButton' onClick={deactivateContactForm}>
            <img className='ContactForm-closeImage' src={CloseUrl} alt='close form'/>
          </button>
        }

        <Form className='Form'
          onSubmit={userStore.handleRegister}
          schema={signUpUserSchema}
          value={userStore.toForm}
          onChange={onFormChange}
          errors={userStore.formErrors}
          onInvalidSubmit={handleInvalidSubmit}
          onError={noop}
        >
          <div role='alert' aria-live='assertive'>
            <Form.Message for='unknownUser'>
              {errors => (
                <ErrorMessage errorClassName='ErrorText' containerClassName='Form-row--noSpace'>
                  {errors}
                </ErrorMessage>
              )}
            </Form.Message>
          </div>

          <div className='ContactForm-successIcon'>
            <FillCheck/>
          </div>

          <div className='ContactForm-successTitle'>
            Message Sent
          </div>

          <div className='Form-row Form-row--mediumSpace'>
            <h2 className='Text Text--a1 Text--lineHeight19px Text--alignCenter Text--sidePadding'>
              Just enter a password to finish creating your account
              to stay updated on this listing.
            </h2>
          </div>

          <div className='Form-row'>
            <label className='Label' htmlFor='email'>Email</label>
            <Form.Field
              id='email'
              type='email'
              name='email'
              errorClass='isInvalid'
              className='TextField'
              placeholder='your@email'
              ref={emailField}
              aria-describedby='emailError'
              aria-invalid={userStore.isFieldInvalid('email') || null}
            />
            <Form.Message className='ErrorText' for='email' id='emailError'/>
          </div>

          <div className='Form-row Form-row--mediumSpace'>
            <label className='Label' htmlFor='password'>Password</label>
            <Form.Field
              id='password'
              type='password'
              name='password'
              errorClass='isInvalid'
              className='TextField TextField--defaultFont'
              placeholder='your password'
              ref={passwordField}
              aria-describedby='passwordError'
              aria-invalid={userStore.isFieldInvalid('password') || null}
            />
            <Form.Message className='ErrorText' for='password' id='passwordError'/>
          </div>

          <div className='Form-row'>
            <div className='Text Text--modalBottom'>
              I agree to Out East’s
              {' '}
              <a href='/terms'
                target='_blank'
                className='Link Link--inline Link--simple'
              >
                Terms of Use
              </a>
            </div>
          </div>

          <div className='Form-row'>
            <Form.Submit type='submit' className='Button Button--primary Button--withoutBorder Button--size13'>
              Sign Up
            </Form.Submit>
          </div>
        </Form>

        <div className='Form-row Form-row--centredText '>
          <div className='Text Text--a1 Text--lineHeight19px Text--alignCenter'>
            If you already have an account, please
          </div>
          <button type='button' className='Button Button--link Button--height35' onClick={onSignIn}>
            Sign In
          </button>
        </div>
      </div>
    </div>
  )
}

CreateUserForm.propTypes = {
  deactivateContactForm: PropTypes.func.isRequired,
  userStore: PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    handleRegister: PropTypes.func.isRequired,
    toForm: MobxPropTypes.objectOrObservableObject.isRequired,
    formErrors: PropTypes.object.isRequired,
    setErrors: PropTypes.func.isRequired,
    isFieldInvalid: PropTypes.func.isRequired
  }).isRequired,
  onFormChange: PropTypes.func.isRequired,
  onSignIn: PropTypes.func.isRequired,
  className: PropTypes.string,
  hasCloseButton: PropTypes.bool,
  setFocusToButton: PropTypes.func
}

export default CreateUserForm
