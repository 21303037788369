import { observe, autorun } from 'mobx'
import * as R from 'ramda'
import { isServer } from '../../../../lib/utils/common'
import UIStateStore from './UIStateStore'
import ValueStateStore from './ValueStateStore'
import ListingTypeStore from './ListingTypeStore'
import SearchStateStore from './SearchStateStore'
import DepotStore from './DepotStore'

class RootStore {
  constructor(context) {
    this.uiStateStore = new UIStateStore(context)
    this.valueStateStore = new ValueStateStore({ ...context, root: this })
    this.listingTypeStore = new ListingTypeStore(context)
    this.searchStateStore = new SearchStateStore(context)
    this.depotStore = new DepotStore(context)

    this.setObservers()
  }

  reset() {
    this.valueStateStore.resetToInitialValues()
  }

  setInitialState(props) {
    this.uiStateStore.setInitialState(props)
    this.listingTypeStore.setInitialState(props)
    this.valueStateStore.setInitialState(props)
    this.depotStore.setInitialState(props)
    this.searchStateStore.setInitialState(props)
  }

  saveSearchInfo() {
    this.valueStateStore.saveSearchInfo()
  }

  setObservers() {
    autorun(
      () => {
        if (!isServer()) {
          this.valueStateStore.getSearchInfoDebounced()
        }
      }
    )

    observe(this.listingTypeStore, 'current', ({ newValue }) => {
      if (this.depotStore.data) {
        const { uiStateStore, valueStateStore, searchStateStore } = this.depotStore.data[newValue]

        this.uiStateStore.setState(uiStateStore)
        this.searchStateStore.setState(searchStateStore)
        this.valueStateStore.setState(valueStateStore)
      }
    })

    observe(this.valueStateStore, 'valuesForSubmit', ({ newValue, oldValue }) => {
      if (!R.equals(newValue, oldValue)) {
        this.valueStateStore.getSearchInfoDebounced()
      }
    })
  }
}

export default RootStore
