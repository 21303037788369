import React from 'react'
import { Provider } from 'mobx-react'
import rootStoreInstance from '../stores/rootStoreInstance'
import PriceHistory from './PriceHistory'
import { ErrorBoundary } from '../../Shared/ErrorBoundary'

const StateFulPriceHistory = props => {
  rootStoreInstance.setInitialDataPriceHistories(props)

  return (
    <ErrorBoundary>
      <Provider store={rootStoreInstance}>
        <PriceHistory/>
      </Provider>
    </ErrorBoundary>
  )
}

export default StateFulPriceHistory
