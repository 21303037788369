import React, { useEffect } from 'react'
import { Provider } from 'mobx-react'
import rootStoreInstance from '../stores/rootStoreInstance'
import StatusInfo from './StatusInfo'
import { withErrorBoundary } from '../../Shared/ErrorBoundary'

const StateFulStatusInfo = (props) => {
  useEffect(() => {
    rootStoreInstance.setInitialDataStatusInfo(props)
  }, [])

  return (
    <Provider store={rootStoreInstance}>
      <StatusInfo/>
    </Provider>
  )
}

export default withErrorBoundary(StateFulStatusInfo)
