import React from 'react'
import { useStores } from '../../../shared_components/Hooks'

const MapLink = () => {
  const { store: { photoGalleryUIStateStore } } = useStores()

  const activateMapView = () => {
    photoGalleryUIStateStore.changeContentType('map')
    document.body.classList.add('isModalActive')
  }

  return (
    <button
      className='Button Button--link Button--iconPrefix Button--iconLocation'
      onClick={activateMapView}
    >
      Map View
    </button>
  )
}

export default MapLink
