import { useRef, useEffect } from 'react'
import { onKey } from '../../../../lib/utils/dom'
import KEY from '../../../../lib/constants/key'

const useCloseCardHandler = callback => {
  const containerRef = useRef(null)

  const handleKeyDown = e => {
    onKey(e, KEY.ESC, () => {
      callback()
    })
  }

  useEffect(() => {
    const instance = containerRef.current
    instance.addEventListener('keydown', handleKeyDown)

    return () => {
      instance.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return { containerRef }
}

export default useCloseCardHandler
