import React, { useEffect } from 'react'
import { Provider } from 'mobx-react'
import { setDisplayName } from '../../Hocs'
import rootStoreInstance from '../stores/rootStoreInstance'
import TakeVideoTourButton from './Button'
import ErrorBoundary from '../../Shared/ErrorBoundary'
import { eventBus } from '../../../lib/utils/dom'

const StateFullTakeVideoTourButton = props => {
  useEffect(() => {
    rootStoreInstance.setInitialDataVideoTourButton(props)

    const resetModal = () => rootStoreInstance.uiStateStore.toggleVideoTourModal(false)
    eventBus.addEventListener('turbolinks:before-cache', resetModal)

    return () => {
      eventBus.removeEventListener('turbolinks:before-cache', resetModal)
    }
  }, [props])

  return (
    <ErrorBoundary>
      <Provider store={rootStoreInstance}>
        <TakeVideoTourButton/>
      </Provider>
    </ErrorBoundary>
  )
}

export default setDisplayName('StateFullTakeVideoTourButton')(StateFullTakeVideoTourButton)
