import React from 'react'
import PropTypes from 'prop-types'
import { compose, withHandlers } from 'recompose'
import { watchKey } from '../../../../lib/utils/dom'
import KEY from '../../../../lib/constants/key'

const enhance = compose(
  withHandlers({
    onKeyDown: ({ applyResultsHandler }) => e => {
      watchKey(e, KEY.RETURN, applyResultsHandler)
    }
  })
)

const SearchBarFooterActions = ({ cancelHandler, applyResultsHandler, onKeyDown, countResults }) => (
  <div className='SearchBarFooterActions'>
    <div className='SearchBarFooterActions-action'>
      <button type='button'
        className='Button Button--link Button--linkSecondary Button--height40'
        onClick={e => {
          e.stopPropagation()
          cancelHandler(e)
        }}
      >
        Cancel
      </button>
    </div>
    <div className='SearchBarFooterActions-action'>
      <button type='button'
        className='Button Button--primary Button--height40'
        onClick={applyResultsHandler}
        onKeyDown={onKeyDown}
      >
        See results {countResults && `(${countResults})`}
      </button>
    </div>
  </div>
)

SearchBarFooterActions.propTypes = {
  wrapperOptionsComponentClassName: PropTypes.string,
  cancelHandler: PropTypes.func.isRequired,
  applyResultsHandler: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  countResults: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
}

export default enhance(SearchBarFooterActions)
