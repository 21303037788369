import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import NoSavedItems from '../NoSavedItems'
import SearchLink from '../../SearchLink'

const SavedSearches = ({ searches, type }) => (
  <Fragment>
    {searches.length ? (
      <div className='SimpleList SimpleList--withDelimiter'>
        <ul className='SimpleList-contentWrapper SimpleList-contentWrapper--width302px'>
          {searches.map(search => (
            <li className='SimpleList-item' key={search.id}>
              <SearchLink url={search.url} className='SimpleList-link' title={search.title } />
            </li>)
          )}
        </ul>
      </div>
    ) : <NoSavedItems type={type}/>}
  </Fragment>

)

SavedSearches.propTypes = {
  searches: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired),
  type: PropTypes.string.isRequired
}

export default SavedSearches
