import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import { isPresent } from '../../../lib/utils/collection'
import { useStores } from '../../../shared_components/Hooks'
import SimpleSlider from '../../Shared/SimpleSlider'
import ImpressionCard from '../../Shared/ImpressionCard'

const sliderSettings = {
  speed: 250,
  loop: true,
  items: 3,
  slideBy: 1,
  nav: true,
  navAsThumbnails: true,
  gutter: 20,
  lazyload: true,
  controls: true,
  responsive: {
    320: {
      items: 1,
      gutter: 15,
      edgePadding: 1
    },
    767: {
      items: 2,
      gutter: 15,
      edgePadding: 1
    },
    1025: {
      items: 3,
      gutter: 20,
      edgePadding: 0
    }
  }
}

const Stories = () => {
  const {store: stories} = useStores()

  return (
    <Fragment>
      { isPresent(stories.collection) && (
        <SimpleSlider
          wrapperClassName='SimpleSlider-wrapper--impressionCards'
          controlsClassName='SimpleSlider-controls--impressionCards'
          settings={sliderSettings}
          ariaLabel='Stories'
        >
          { stories.collection.map((story, index) => (
            <li key={story.url} className='SimpleSlider-item' aria-live='polite' data-index={index + 1} >
              <ImpressionCard
                content={story}
                withDescriptionTruncate={true}
                withTitleTruncate={true}
              />
            </li>
          )) }
        </SimpleSlider>
      )}
    </Fragment>
  )
}

export default observer(Stories)
