import React from 'react'
import PropTypes from 'prop-types'

const AgentInfo = ({ fullName, companyName }) => (
  <div className='OpenListingCard-item OpenListingCard-item--width100px'>
    <div className='OpenListingCard-title'>Agent</div>
    <div className='OpenListingCard-text OpenListingCard-text--medium'>
      {fullName}
    </div>
    <div className='OpenListingCard-text'>{companyName}</div>
  </div>
)

AgentInfo.propTypes = {
  fullName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired
}

export default AgentInfo
