import React from 'react'
import { Provider } from 'mobx-react'
import { ErrorBoundary } from '../../Shared/ErrorBoundary'
import { transport } from '../../../lib/services'
import FormStore from './stores/FormStore'
import UserInfoForm from './UserInfoForm'

const store = new FormStore({ transport })

const StatefulUserInfoForm = props => {
  store.setInitialData(props)

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <UserInfoForm/>
      </Provider>
    </ErrorBoundary>
  )
}


export default StatefulUserInfoForm
