import { debounce } from '../../../lib/utils/common'
import { observable, makeObservable, runInAction, computed, toJS } from 'mobx'
import { isPresent } from '../../../lib/utils/collection'

class PropertyPolygonsStore {
  fetchPropertyPolygons = debounce(this._fetchPolygons, 100)
  @observable _polygons = {}

  constructor({ transport }) {
    makeObservable(this)

    this.transport = transport
  }

  @computed
  get hasPolygons() {
    return isPresent(this._polygons)
  }

  @computed
  get propertyPolygons() {
    return toJS(this._polygons)
  }

  // private
  _fetchPolygons(mapBounds) {
    this.transport.PropertyPolygons.get({ mapBounds })
      .then(result => {
        runInAction(() => {
          this._polygons = result
        })
      })
  }
}

export default PropertyPolygonsStore
