import React from 'react'
import { withProps, compose, lifecycle, withState } from 'recompose'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {observer} from 'mobx-react'
import Viewport from '../../Shared/Viewport'
import TinySliderReact from '../TinySliderReact'
import ControlsContainer from './ControlsContainer'
import Indicators from './Indicators'

const enhance = compose(
  withState('prevButton', 'setPrevButton', null),
  withState('nextButton', 'setNextButton', null),
  withState('navContainer', 'setNavContainer', null),
  withProps(({ children, settings, prevButton, nextButton, navContainer }) => ({
    itemsCount: React.Children.count(children),
    prevButtonRef: React.createRef(),
    nextButtonRef: React.createRef(),
    simpleSliderRef: React.createRef(),
    navContainerRef: React.createRef(),
    settings: {
      ...settings,
      prevButton,
      nextButton,
      navContainer
    }
  })),
  lifecycle({
    componentDidMount() {
      const {
        setPrevButton,
        setNextButton,
        prevButtonRef,
        nextButtonRef,
        setNavContainer,
        navContainerRef
      } = this.props

      setPrevButton(prevButtonRef.current)
      setNextButton(nextButtonRef.current)
      setNavContainer(navContainerRef.current)
    }
  }),
  observer,
)

const SimpleSlider = ({
  wrapperClassName,
  className,
  controlsClassName,
  settings,
  children,
  prevButtonRef,
  nextButtonRef,
  navContainerRef,
  itemsCount,
  ariaLabel,
  simpleSliderRef
}) => (
  <section
    className='SimpleSlider'
    aria-roledescription='carousel'
    aria-label={ariaLabel}
    ref={simpleSliderRef}
  >
    <div className='SimpleSlider-container'>
      <div className='SimpleSlider-customControls'>
        <ControlsContainer
          controlsClassName={controlsClassName}
          settings={settings}
          prevButtonRef={prevButtonRef}
          nextButtonRef={nextButtonRef}
          itemsCount={itemsCount}
        />
        <Viewport except='desktop'>
          <Indicators navContainerRef={navContainerRef} children={children} settings={settings}/>
        </Viewport>
      </div>
      <div className={cn('SimpleSlider-wrapper', wrapperClassName)}>
        <TinySliderReact
          className={cn('SimpleSlider-items', className)}
          settings={settings}
          children={children}
          simpleSliderRef={simpleSliderRef}
        />
        <Viewport except='desktop'>
          <div className='SimpleSlider-touchStub'/>
        </Viewport>
      </div>
      <Viewport only='desktop'>
        <Indicators navContainerRef={navContainerRef} children={children} settings={settings} />
      </Viewport>
    </div>
  </section>
)

SimpleSlider.propTypes = {
  settings: PropTypes.object,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  itemsCount: PropTypes.number.isRequired,
  controlsClassName: PropTypes.string,
  prevButtonRef: PropTypes.object.isRequired,
  nextButtonRef: PropTypes.object.isRequired,
  navContainerRef: PropTypes.object.isRequired,
  simpleSliderRef: PropTypes.object.isRequired,
  children: PropTypes.array,
  ariaLabel: PropTypes.string
}

export default enhance(SimpleSlider)
