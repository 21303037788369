import React, { useEffect } from 'react'
import { Provider } from 'mobx-react'
import rootStoreInstance from '../stores/rootStoreInstance'
import OpenListings from './OpenListings'
import { withErrorBoundary } from '../../Shared/ErrorBoundary'

const StateFulOpenListings = (props) => {
  useEffect(() => {
    rootStoreInstance.setInitialDataOpenListings(props)
  })

  return (
    <Provider store={rootStoreInstance}>
      <OpenListings/>
    </Provider>
  )
}

export default withErrorBoundary(StateFulOpenListings)
