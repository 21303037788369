import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withProps, withHandlers, compose } from 'recompose'
import { toJS } from 'mobx'
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react'
import Dropdown from '../../../Shared/Dropdown'
import { PriceInput } from '../../../Shared/Form'
import ListingTypeSelector from '../ListingTypeSelector'
import AdvancedFilter from '../AdvancedFilter'
import { priceLabelWithCutOff } from '../../../../lib/utils/money'
import SearchBarFooterActions from './SearchBarFooterActions'
import AreaSelectorActions from './AreaSelectorActions'
import { TreeTownSelect, CheckyOptionTemplate } from '../../../Shared/Form/TownSelect'
import { SelectPricePeriod } from '../SelectPricePeriod'
import RectangleRadioButtonGroup from '../../../Shared/Form/RectangleRadioButtonGroup'
import Checkbox from '../../../Shared/Checkbox'

const enhance = compose(
  inject('store'),
  withProps(({ store }) => ({
    uiStateStore: store.uiStateStore,
    searchStateStore: store.searchStateStore,
    listingTypeStore: store.listingTypeStore,
    valueStateStore: store.valueStateStore
  })),
  withHandlers(() => {
    const dropdownElements = {}

    return {
      registerDropdown: () => (name, el) => {
        if (el) {
          dropdownElements[name] = el
        }
      },
      cancelPriceDropdown: () => () => {
        dropdownElements.price.stateUpdaters.toggleActive()
      },
      cancelBedsBathsDropdown: () => () => {
        dropdownElements.bedsBaths.stateUpdaters.toggleActive()
      },
      cancelPricePeriodDropdown: () => () => {
        dropdownElements.pricePeriod.stateUpdaters.toggleActive()
      },
      onChangePricePeriod: ({ valueStateStore }) => value => {
        valueStateStore.setInputValue('pricePeriod', value.id)
      },
      onChangeYear: ({ valueStateStore }) => value => {
        valueStateStore.setInputValue('year', value)
      },
      onChangePrice: ({ valueStateStore }) => (name, value) => {
        valueStateStore.setInputValue(name, value)
      },
      onChangeTown: ({ valueStateStore }) => ({ value }) => {
        valueStateStore.setInputValue('townNames', value)
      },
      onRadioButtonChange: ({ valueStateStore }) => name => value => {
        valueStateStore.setInputValue(name, value)
      },
      onImmediateSubmit: ({ listingTypeStore, valueStateStore }) => () => {
        valueStateStore.immediateSubmit(listingTypeStore)
      }
    }
  }),
  observer,
)

const SearchBarDesktop = ({
  valueStateStore,
  listingTypeStore,
  uiStateStore,
  searchStateStore,
  registerDropdown,
  cancelPriceDropdown,
  cancelBedsBathsDropdown,
  cancelPricePeriodDropdown,
  onChangePricePeriod,
  onChangePrice,
  onChangeTown,
  onImmediateSubmit,
  onChangeYear,
  onRadioButtonChange
}) => (
  <div className='SearchBar'>
    <div className='SearchBar-input SearchBar-input--listingType'>
      <ListingTypeSelector
        currentListingType={listingTypeStore.current}
        listingTypes={listingTypeStore.listingTypes}
        onChange={
          lt => {
            listingTypeStore.setCurrent(lt)
            onImmediateSubmit()
          }
        }
      />
    </div>

    <div className='SearchBar-input SearchBar-input--towns'>
      <label className='SearchBar-label' htmlFor='towns'>AREAS</label>
      <TreeTownSelect
        id='towns'
        className='Multiselect--checky Multiselect--searchBar Multiselect--noPaddings'
        height={null}
        placeholder='Which area?'
        options={toJS(valueStateStore.treeTownOptions)}
        optionsById={valueStateStore.optionsById}
        value={toJS(valueStateStore.values.townNames)}
        selectedOptionsWithParents={valueStateStore.selectedOptionsWithParents}
        setSelectedOptionsGroup={valueStateStore.setSelectedOptionsGroup}
        selectedOptionsGroup={toJS(valueStateStore.selectedOptionsGroup)}
        onChange={onChangeTown}
        wrapperOptionsComponent={AreaSelectorActions}
        applyResultsHandler={onImmediateSubmit}
        countResults={valueStateStore.countResults}
        optionTemplate={CheckyOptionTemplate}
      />
    </div>

    {listingTypeStore.current === 'rentals' &&
    <div className='SearchBar-input'>
      <SelectPricePeriod
        id='price-period'
        label='Price Period'
        pricePeriodYears={uiStateStore.pricePeriodYears}
        selectedYear={valueStateStore.values.year}
        pricePeriods={uiStateStore.pricePeriods}
        selectedPricePeriod={valueStateStore.values.pricePeriod}
        onChangePricePeriod={onChangePricePeriod}
        onChangeYear={onChangeYear}
        onResetInputToInitialValue={valueStateStore.resetInputToInitialValue}
        registerDropdown={registerDropdown}
        footer={
          <SearchBarFooterActions
            cancelHandler={cancelPricePeriodDropdown}
            applyResultsHandler={onImmediateSubmit}
          />
        }
      />
    </div>
    }

    <div className='SearchBar-input'>
      <Dropdown
        id='price'
        className='Dropdown--searchBar'
        ref={registerDropdown.bind(null, 'price')}
        title={
          <Fragment>
            <div className='Dropdown-label'>Price</div>
            <div>{valueStateStore.priceTitle(uiStateStore.priceExtremum.cutOff)}</div>
          </Fragment>
        }
      >
        <div className='Dropdown-container'>
          <PriceInput
            priceExtremum={uiStateStore.priceExtremum}
            values={valueStateStore.priceValues}
            onChange={onChangePrice}
            onReset={valueStateStore.resetInputs}
            handleTemplate={priceLabelWithCutOff}
          />
        </div>
        <SearchBarFooterActions
          cancelHandler={cancelPriceDropdown}
          applyResultsHandler={onImmediateSubmit}
          countResults={valueStateStore.countResults}
        />
      </Dropdown>
    </div>

    {(listingTypeStore.current === 'rentals' || listingTypeStore.current === 'sales') &&
    <div className='SearchBar-input SearchBar-input--bedsBaths'>
      <Dropdown
        id='bedrooms'
        name='bedrooms'
        className='Dropdown--searchBar'
        title={
          <Fragment>
            <div className='Dropdown-label'>Bedrooms</div>
            <div className='sr-only'>Bedrooms and Bathrooms</div>
            <div>{valueStateStore.bedsBathsTitle}</div>
          </Fragment>
        }
        ref={registerDropdown.bind(null, 'bedsBaths')}
      >
        <div className='Dropdown-container Dropdown-container--stacked'>
          <div className='Dropdown-itemColumn'>
            <RectangleRadioButtonGroup
              name='beds'
              options={uiStateStore.bedsBathsOptions}
              onChange={onRadioButtonChange('beds')}
              value={valueStateStore.values.beds}
              legend='Bedrooms'
            />
          </div>
          <div className='Dropdown-itemColumn'>
            <RectangleRadioButtonGroup
              name='baths'
              options={uiStateStore.bedsBathsOptions}
              onChange={onRadioButtonChange('baths')}
              value={valueStateStore.values.baths}
              legend='Bathrooms'
            />
          </div>
        </div>
        <SearchBarFooterActions
          cancelHandler={cancelBedsBathsDropdown}
          applyResultsHandler={onImmediateSubmit}
        />
      </Dropdown>
    </div>
    }

    <div className='SearchBar-input'>
      <Checkbox
        name='openHouse'
        wrapperClassName='ListingForm-input--checkboxSearchBar'
        className='Checkbox--grayscale'
        onChange={({target}) => { valueStateStore.setInputValue('openHouse', target.checked) }}
        checked={valueStateStore.values.openHouse || false}
        label='Open House'
        labelClassName='Checkbox-label--size16'
        qaAdvancedOpenHouse
      />
    </div>

    <div className='SearchBar-input SearchBar-input--action'>
      <button
        type='submit'
        className='Button Button--primary Button--height40'
        onClick={onImmediateSubmit}
      >
        search
      </button>
    </div>

    <div className='SearchBar-input SearchBar-input--action'>
      <AdvancedFilter
        submit={onImmediateSubmit}
        reset={() => { valueStateStore.resetAllInputs() }}
      />
    </div>

    <div className='SearchBar-input SearchBar-input--last SearchBar-input--action'>
      {searchStateStore.search.saved ?
        <button
          type='button'
          className='Button Button--secondary Button--height40 Button--searchBell Button--iconPrefix'
          onClick={() => { searchStateStore.destroySearch() }}
        >
          Saved
        </button>
        :
        <button
          type='button'
          className='Button Button--secondary Button--height40 Button--searchBell Button--iconPrefix'
          onClick={() => {
            searchStateStore.saveSearch({ values: valueStateStore.valuesForSubmit })
          }}
        >
          Save search
        </button>
      }
    </div>
  </div>
)

SearchBarDesktop.propTypes = {
  uiStateStore: PropTypes.shape({
    priceExtremum: PropTypes.object.isRequired,
    pricePeriods: MobxPropTypes.objectOrObservableObject.isRequired,
    pricePeriodYears: PropTypes.array.isRequired,
    bedsBathsOptions: PropTypes.array
  }).isRequired,
  searchStateStore: PropTypes.shape({
    search: PropTypes.object.isRequired,
    destroySearch: PropTypes.func.isRequired,
    saveSearch: PropTypes.func.isRequired
  }),
  listingTypeStore: PropTypes.shape({
    listingTypes: PropTypes.object.isRequired,
    current: PropTypes.string.isRequired,
    setCurrent: PropTypes.func.isRequired
  }).isRequired,
  valueStateStore: PropTypes.shape({
    values: PropTypes.object.isRequired,
    selectedOptionsWithParents: PropTypes.array,
    selectedOptionsGroup: MobxPropTypes.arrayOrObservableArray,
    setSelectedOptionsGroup: PropTypes.func,
    optionsById: PropTypes.object.isRequired,
    towns: PropTypes.object.isRequired,
    bedsValueLimits: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired
    }).isRequired,
    bathValueLimits: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired
    }).isRequired,
    priceValues: PropTypes.array.isRequired,
    setInputValue: PropTypes.func.isRequired,
    debounceSubmit: PropTypes.func.isRequired,
    immediateSubmit: PropTypes.func.isRequired,
    resetAllInputs: PropTypes.func.isRequired,
    countResults: PropTypes.number.isRequired,
    treeTownOptions: PropTypes.array.isRequired,
    priceTitle: PropTypes.func.isRequired,
    resetInputs: PropTypes.func.isRequired,
    bedsBathsTitle: PropTypes.string.isRequired,
    valuesForSubmit: PropTypes.object.isRequired,
    resetInputToInitialValue: PropTypes.func.isRequired,
    year: PropTypes.string,
    pricePeriod: PropTypes.string
  }).isRequired,
  registerDropdown: PropTypes.func.isRequired,
  cancelPriceDropdown: PropTypes.func.isRequired,
  cancelBedsBathsDropdown: PropTypes.func.isRequired,
  cancelPricePeriodDropdown: PropTypes.func.isRequired,
  onChangePricePeriod: PropTypes.func.isRequired,
  onChangePrice: PropTypes.func.isRequired,
  onChangeTown: PropTypes.func.isRequired,
  onImmediateSubmit: PropTypes.func.isRequired,
  onChangeYear: PropTypes.func.isRequired,
  onRadioButtonChange: PropTypes.func
}

export default enhance(SearchBarDesktop)
