import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { hideIfNoData, withTextEllipsis } from '../../Hocs'
import { AllHtmlEntities } from 'html-entities'

const enhance = compose(
  withTextEllipsis('.ImpressionCard'),
  hideIfNoData(({ content }) => !content.description)
)

const Description = ({ content, textWithEllipsis, applyEllipsisFor, withTruncate }) => (
  <div className='ImpressionCard-description' ref={applyEllipsisFor}>
    { AllHtmlEntities.decode(withTruncate && textWithEllipsis || content.description) }
  </div>
)

Description.propTypes = {
  content: PropTypes.shape({
    description: PropTypes.string
  }).isRequired,
  textWithEllipsis: PropTypes.string,
  applyEllipsisFor: PropTypes.func.isRequired,
  withTruncate: PropTypes.bool.isRequired

}

export default enhance(Description)
