import React, { useRef } from 'react'
import { observer } from 'mobx-react'
import Sliders from './Sliders'
import ViewTypes from '../../../shared_components/ViewTypes'
import { useStores } from '../../../shared_components/Hooks'

const ImageGallery = () => {
  const { store: { photoGalleryUIStateStore, hdpStore } } = useStores()
  const mapButtonRef = useRef(null)

  if (photoGalleryUIStateStore.contentType) {
    return (
      <ViewTypes
        mapButtonRef={mapButtonRef}
        contentType={photoGalleryUIStateStore.contentType}
      />
    )
  }

  if (hdpStore.hasImages) {
    return <Sliders mapButtonRef={mapButtonRef}/>
  }

  // for SSR
  return null
}

export default observer(ImageGallery)

