/* eslint camelcase: 0 */
/* eslint no-undefined: 0 */
/* eslint no-console: 0 */

import { lens } from 'lorgnette'
import { observable, action, makeObservable } from 'mobx'
import { isDefined } from '../../../../lib/utils/common'
import { flashMessage } from '../../../../lib/services'

class SearchStateStore {
  @observable search = { id: null, saved: false }

  constructor({ transport, gaEvents, eventBus }) {
    makeObservable(this)

    this.transport = transport
    this.gaEvents = gaEvents

    eventBus.addEventListener('transport.Search.saveTask', ({ detail: { data } }) => {
      this.updateSearch(data)
    })
  }

  setInitialState({ initialState }) {
    this.setState(initialState.SearchStateStore)
  }

  @action('[SearchStore] Set state')
  setState = ({ search, listingClass }) => {
    this.search = search
    this.listingClass = listingClass
  }

  @action('[SearchStore] Set search')
  setSearch = search => {
    this.search = search
  }

  @action('[SearchStore] Update search')
  updateSearch = ({ search: { id } }) => {
    if (id) {
      this.setSearch({ id, saved: true })
      this.sendAnalytics({ category: this.listingClass, saved: true })
    }
  }

  @action('[SearchStore] Save search')
  saveSearch = ({ values }, viewport) => {
    this.transport.Search
      .save({
        search: {
          listingClass: this.listingClass,
          query: this.valuesForSubmit(values)
        }
      })
      .then(response => {
        if (response.success) {
          if (viewport === 'mobile') {
            this.setSearch({ ...this.search, saving: true })
            setTimeout(() => {
              this.updateSearch(response)
            }, 3000)
            return
          }

          this.updateSearch(response)
        } else {
          flashMessage.show({ text: response.message, type: 'error' })
        }
      })
      .catch(e => { console.error(e) })
  }

  @action('[SearchStore] Destroy search')
  destroySearch = () => {
    this.transport.Search
      .destroy(this.search.id)
      .then(() => {
        this.setSearch({ saved: false, id: null })
        this.sendAnalytics({ category: this.listingClass, saved: false })
      })
  }

  @action('[SearchStore] Toggle save search')
  toggleSaveSearch = (isActive, { values }) => {
    if (isActive) {
      this.saveSearch({ values })
    } else {
      this.destroySearch()
    }
  }

  sendAnalytics({ category, saved }) {
    const eventCategory = (`${category}_search`).toLowerCase()
    const eventLabel = saved ? 'save' : 'unsave'

    this.gaEvents.Srp.searchSave({ eventCategory, eventLabel })
  }

  valuesForSubmit(values) {
    return lens.prop('status').update(values, status => {
      const s = isDefined(status) ? status : []
      return s.length === 0 ? ['any'] : s
    })
  }
}

export default SearchStateStore
