import React, { Fragment } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import pluralize from 'pluralize'

const offsets = [
  {
    value: 0,
    label: 'Exact Dates'
  },
  {
    value: 1,
    label: '± 1 week'
  },
  {
    value: 2,
    label: '± 2 weeks'
  }
]

const nightsCount = range => {
  const { start, end } = range

  if (start && end) {
    const startDate = moment(start)
    const endDate = moment(end)
    const nights = moment.duration(endDate.diff(startDate)).asDays()
    const pluralizeNight = pluralize('night', nights)

    return `${startDate.format('MMM DD, YYYY')} - ${endDate.format('MMM DD, YYYY')} (${nights} ${pluralizeNight})`
  }

  return ''
}

const isDisabled = range => !(range.start && range.end)

const DateRangePickerFooter = ({ datesRange, selectOffset, applyDateRange }) => (
  <Fragment>
    <h3 className='Text Text--a2' role='status' aria-live='polite'>{nightsCount(datesRange)}</h3>

    <fieldset className='DateRangePicker-dateOffset'>
      <legend className='Label' id='select-dates-label'>
        Select dates extension
      </legend>

      <div className='DateRangePicker-rangeExtensionList'>
        {offsets.map((item, index) => (
          <div className='DateRangePicker-rangeExtension' key={item.value}>
            <label className='DateRangePicker-rangeExtensionLabel' htmlFor={`offset-${index}`}>
              <input
                type='radio'
                id={`offset-${index}`}
                name='date-offset'
                className='DateRangePicker-rangeExtensionInput'
                value={item.value}
                data-label={item.label}
                checked={datesRange.offset.value === item.value}
                onChange={selectOffset}
              />
              <span className='DateRangePicker-rangeExtensionContent'>{item.label}</span>
            </label>
          </div>
        ))}
      </div>
    </fieldset>

    <div className='DateRangePicker-actions'>
      <div className='DateRangePicker-action'>
        <button
          type='button'
          className={cn('Button Button--primary', { isDisabled: isDisabled(datesRange) })}
          onClick={applyDateRange}
          disabled={isDisabled(datesRange)}
        >
          Done
        </button>
      </div>
    </div>
  </Fragment>
)

DateRangePickerFooter.propTypes = {
  selectOffset: PropTypes.func,
  applyDateRange: PropTypes.func,
  offsets: PropTypes.array,
  datesRange: PropTypes.shape({
    offset: PropTypes.object
  })
}

export default DateRangePickerFooter
