import { inject, observer } from 'mobx-react'
import * as R from 'ramda'
import { cond, renderComponent } from '../../Hocs'
import ContactOwner from '../ContactOwner'
import ContactAgent from '../ContactAgent'
import { CONTACT_FORM_TYPE } from '../../../lib/utils/stores'

const Contact = R.compose(
  inject('store'),
  observer,
  cond(
    ({ store }) => store.uiStateStore.contactType,
    {
      [CONTACT_FORM_TYPE.agent]: renderComponent(ContactAgent),
      [CONTACT_FORM_TYPE.owner]: renderComponent(ContactOwner)
    }
  )
)

Contact.displayName = 'Contact'

export default Contact()
