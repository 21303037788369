/* eslint no-var: 0 */
/* eslint vars-on-top: 0 */
import ReactOnRails from 'react-on-rails'
import buildComponentsRegister from '../../../config/webpack/utils/buildComponentsRegister.js'

// Polyfills
require('fetch-xhr')
require('core-js/stable')

// MobX configure
let mobx = require('mobx')
mobx.configure({ enforceActions: 'observed' })

// Build the components register from app/client/ folder recursively using webpack require
let componentsRegister = buildComponentsRegister(require.context('components', true, /\.js$/))

ReactOnRails.register(componentsRegister)
