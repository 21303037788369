import RootStore from './RootStore'
import { transport, sessionStorage, gaEvents, location, history, userInfo } from '../../../lib/services'
import { isViewport } from '../../../lib/utils/viewport'
import { eventBus } from '../../../lib/utils/dom'

export default new RootStore({
  transport,
  location,
  isViewport,
  sessionStorage,
  gaEvents,
  history,
  userInfo,
  eventBus
})
