import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { redirect } from '../../lib/utils/common'
import { transport, userInfo } from '../../lib/services'
import Modal from '../Shared/Modal'

const SignOut = ({ linkClassName }) => {
  const [isActive, setActive] = useState(false)
  const closeModal = () => { setActive(false) }
  const openModal = () => { setActive(true) }
  const signOut = () => {
    transport.User.signOut()
      .then(() => userInfo.clear())
      .then(() => { redirect('/') })
  }

  return (
    <Fragment>
      <button
        key='signOutButton'
        className={classNames('Button Button--link', linkClassName)}
        onClick={openModal}
      >
        Sign Out
      </button>
      <Modal
        isOpen={isActive}
        onRequestClose={closeModal}
        ariaHideApp={false}
        mainAction={signOut}
        key='signOutModal'
      >
        <div className='SignOut'>
          <div className='SignOut-content'>
            <h4 className='SignOut-title'>Sign Out</h4>
            <p className='SignOut-subTitle'>Are you sure you want to sign out?</p>
            <button className='Button Button--primary' onClick={signOut}>Sign Out</button>
            <div className='SignOut-cancel'>
              <button className='Button Button--link' onClick={closeModal}>Cancel</button>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>)
}

SignOut.propTypes = {
  linkClassName: PropTypes.string
}

export default SignOut
