import React from 'react'
import { Provider } from 'mobx-react'
import rootStoreInstance from '../stores/rootStoreInstance'
import CtaInfo from './CtaInfo'

export default () => (
  <div>
    <Provider store={rootStoreInstance}>
      <CtaInfo />
    </Provider>
  </div>
)
