import React, { useRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { CONTACT_FORM_STATE } from '../../../lib/utils/stores'
import { useRegisterGlobalActions } from '../../../shared_components/Hooks'

const Button = ({ setContactFormState, buttonRef }) => {
  const innerRef = useRef(null)

  const handleClick = () => {
    setContactFormState(CONTACT_FORM_STATE.makeContact)
  }

  useImperativeHandle(buttonRef, () => ({
    setFocus: () => {
      global.requestAnimationFrame(() => {
        innerRef.current.focus()
      })
    }
  }))

  useRegisterGlobalActions({
    context: { handleClick },
    key: 'ContactOwnerButton',
    actions: ['handleClick']
  })

  return (
    <button ref={innerRef} className='Button Button--primary' onClick={handleClick}>
      Contact Owner
    </button>
  )
}

Button.propTypes = {
  buttonRef: PropTypes.object.isRequired,
  setContactFormState: PropTypes.func.isRequired
}

export default observer(Button)
