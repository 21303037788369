import React from 'react'
import { compose, withProps, withHandlers } from 'recompose'

const buildTabID = year => `price-period-${year}-tab`
const buildPanelID = year => `price-period-${year}-panel`

const enhance = compose(
  withProps(({ selectedYear, pricePeriodYears }) => ({
    tabs: pricePeriodYears.map(year => (
      {
        tabId: buildTabID(year),
        panelId: buildPanelID(year),
        ref: React.createRef(),
        label: year,
        value: Number(year),
        isActive: Number(year) === selectedYear
      }))
  })),
  withHandlers({
    tabAction: ({ onChangeYear, onResetInputToInitialValue }) => tab => {
      onChangeYear(tab.value)
      onResetInputToInitialValue('pricePeriod')
    }
  }),
  withProps(({
    selectedYear,
    selectedPricePeriod,
    pricePeriods,
    pricePeriodYears
  }) => {
    const year = selectedYear && selectedYear.toString()
    const fallbackYear = pricePeriodYears[0]

    if (pricePeriodYears.includes(year)) {
      return {
        pricePeriodOptions: pricePeriods[year],
        pricePeriodSelectedOption: selectedPricePeriod || pricePeriods[year][0].id,
        pricePeriodYear: year
      }
    }

    return {
      pricePeriodOptions: pricePeriods[fallbackYear],
      pricePeriodSelectedOption: '',
      pricePeriodYear: fallbackYear
    }
  }),
  withProps(({ pricePeriodOptions, pricePeriodSelectedOption, pricePeriodYear }) => {
    const periodName = pricePeriodOptions.find(({ id }) => id === pricePeriodSelectedOption)

    return {
      selectedValuesLabel: `${periodName.shortName} ${pricePeriodYear}`
    }
  })
)

export { enhance as default, buildTabID, buildPanelID }
