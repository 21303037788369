import React from 'react'
import PropTypes from 'prop-types'
import { CheckySelect } from '../../../../Shared/Form'
import { identity } from '../../../../../lib/utils/selectors'

const Features = ({ uiStateStore, valueStateStore, isLand, children }) => (
  <fieldset className='ListingForm-groupFieldset'>
    <legend className='ListingForm-groupLabel'>
      Features
    </legend>
    <div className='ListingForm-fieldRow ListingForm-fieldRow--withColumns ListingForm-fieldRow--pb0'>
      <div className='ListingForm-fieldColumn ListingForm-fieldColumn--mobileRow'>
        <div className='Label' id='view-label'>
          View
        </div>
        <div className='ListingForm-input'>
          <CheckySelect
            id='view'
            name='amenityView'
            className='CheckySelect--stoneBorder'
            optionIdentity={identity}
            selectedOptionsTemplate={v => (v.join(', '))}
            optionTemplate={identity}
            options={valueStateStore.viewAmenities}
            value={valueStateStore.viewAmenityValue}
            placeholder='Any'
            onChange={values => {
              valueStateStore.viewAmenities.forEach(a => {
                valueStateStore.setInputValue(a, values.includes(a))
              })
            }}
            withScrollIntoView
          />
        </div>
      </div>
      {!isLand && (
        <div className='ListingForm-fieldColumn ListingForm-fieldColumn--mobileColumn'>
          <div className='Label' id='homeType-label'>
          Home Type
          </div>
          <div className='ListingForm-input'>
            <CheckySelect
              id='home-type'
              name='unitTypes'
              className='CheckySelect--stoneBorder CheckySelect--scrollContent'
              options={uiStateStore.unitTypesCollection}
              value={valueStateStore.values.unitTypes}
              placeholder='Any'
              onChange={values => { valueStateStore.setInputValue('unitTypes', values.map(({id}) => id)) }}
              withScrollIntoView
            />
          </div>
        </div>
      )}
    </div>
    {children}
  </fieldset>
)

Features.propTypes = {
  valueStateStore: PropTypes.object.isRequired,
  uiStateStore: PropTypes.object.isRequired,
  isLand: PropTypes.bool,
  children: PropTypes.object
}

export default Features
