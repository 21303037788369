import { CaptchaStore, UserStore } from '../../Shared/stores'
import ContactAgentUIStateStore from './ContactAgentUIStateStore'
import ContactAgentFormStore from './ContactAgentFormStore'
import { CONTACT_CAPTCHA_KEY } from '../../../lib/config/recaptcha'

class RootStore {
  constructor(context) {
    this.uiStateStore = new ContactAgentUIStateStore(context)
    this.contactAgentFormStore = new ContactAgentFormStore(context)
    this.contactCaptchaStore = new CaptchaStore({...context, captchaKey: CONTACT_CAPTCHA_KEY})
    this.userStore = new UserStore(context)
  }

  reset() {
    this.uiStateStore.reset()
  }

  setInitialDataContactAgent = data => {
    this.contactAgentFormStore.setInitialData(data)
  }
}

export default RootStore
