import { observable, action, computed, makeObservable } from 'mobx'
import { priceLabelWithCutOff } from '../../../../lib/utils/money'
import { isDefined } from '../../../../lib/utils/common'
import { SALES_TYPE, RENTALS_TYPE } from '../../../Shared/stores/constants'

class UIStateStore {
  @observable searchType = ''
  @observable towns = null
  @observable priceExtremum = {}
  @observable allPricePeriods = []
  @observable bedsTitle = 'Any Beds'
  @observable priceTitle = 'Any Price'

  bedsOptions = []

  constructor() {
    makeObservable(this)
  }

  @computed
  get isSalesForm() {
    return this.searchType === SALES_TYPE
  }

  @computed
  get isRentalsForm() {
    return this.searchType === RENTALS_TYPE
  }

  @action('[UIStateStore] Set Initial State')
  setInitialState({ initialState }) {
    const uiStateStore = initialState[this.searchType].uiStateStore

    this.priceExtremum = uiStateStore.priceExtremum
    this.bedsOptions = uiStateStore.bedsBathsOptions

    if (uiStateStore.pricePeriods) {
      this.allPricePeriods = uiStateStore.pricePeriods
      this.pricePeriodYears = Object.keys(uiStateStore.pricePeriods)
    }
  }

  @action('[UIStateStore] Set search type')
  setSearchType = value => {
    this.searchType = value
  }

  @action('[UIStateStore] Update BedsTitle')
  updateBedsTitle = beds => {
    this.bedsTitle = beds ? `${beds}+ Beds` : 'Any Beds'
  }

  @action('[UIStateStore] Update PriceTitle')
  updatePriceTitle = ([ priceFrom, priceTo ]) => {
    if (isDefined(priceFrom) || isDefined(priceTo)) {
      const { histogramMin, histogramMax } = this.priceExtremum

      if (priceFrom !== histogramMin || priceTo !== histogramMax) {
        this.priceTitle = [priceFrom || histogramMin, priceTo || histogramMax]
          .map(v => priceLabelWithCutOff(v, histogramMax)).join(' - ')
      } else {
        this.priceTitle = 'Any Price'
      }
    } else {
      this.priceTitle = 'Any Price'
    }
  }
}

export default UIStateStore
