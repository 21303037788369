import { observable, action, computed, makeObservable } from 'mobx'

class ListingTypeStore {
  @observable current = null

  listingTypes = {
    sales: {
      title: 'Sales',
      baseUrl: '/for-sale',
      searchUrl: '/search_sales',
      countResultsUrl: '/api/oe/search_sales',
      homeUrl: '/'
    },
    rentals: {
      title: 'Rentals',
      baseUrl: '/for-rent',
      searchUrl: '/search_rentals',
      countResultsUrl: '/api/oe/search_rentals',
      homeUrl: '/rentals'
    },
    lands: {
      title: 'Lands',
      baseUrl: '/for-sale/land',
      searchUrl: '/search_sales',
      countResultsUrl: '/api/oe/search_lands',
      homeUrl: '/sales'
    }
  }

  constructor() {
    makeObservable(this)
  }

  @action('[ListingTypeStore] Set initial state')
  setInitialState({ initialState }) {
    this.current = initialState.ListingTypeStore.current
  }

  @action('[ListingTypeStore] Set current listing type')
  setCurrent = value => {
    this.current = value
  }

  @computed
  get currentName() {
    const currentListingType = this.listingTypes[this.current]
    return currentListingType ? currentListingType.title : ''
  }
}

export default ListingTypeStore
