import React, { useEffect } from 'react'
import { Provider } from 'mobx-react'
import rootStoreInstance from '../stores/rootStoreInstance'
import Contact from './Contact'
import ErrorBoundary from '../../Shared/ErrorBoundary'
import { useTurbolinksReload } from '../../../shared_components/Hooks'

const StateFulContact = props => {
  useEffect(()=>{
    rootStoreInstance.setInitialDataContactOwner(props)
    rootStoreInstance.setInitialDataContactAgent(props)
  }, [props])

  useTurbolinksReload({ store: rootStoreInstance })

  return (
    <ErrorBoundary>
      <Provider store={rootStoreInstance}>
        <Contact hasCloseButton={true}/>
      </Provider>
    </ErrorBoundary>
  )
}

export default StateFulContact
