import initializeRouter from '../../lib/utils/router'
import { Page as AuthPage } from './stores/UIStateStore'

const start = store => {
  const routes = {
    '/users/password/edit': () => {
      store.uiStateStore.openModal(AuthPage.edit, {shouldCloseOnOverlayClick: false})
    },
    '/users/sign_in': () => {
      store.uiStateStore.openModal(AuthPage.signin, {shouldCloseOnOverlayClick: false})
    },
    '/users/sign_up': () => {
      store.uiStateStore.openModal(AuthPage.signup, {shouldCloseOnOverlayClick: false})
    },
    '/users/invitation/edit': () => {
      store.uiStateStore.openModal(AuthPage.setPassword, {shouldCloseOnOverlayClick: false})
    }
  }
  initializeRouter(routes)
}

export default start
