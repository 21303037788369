import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { setPropTypes, compose, withProps } from 'recompose'
import Select from '../../../Shared/Form/Select'

const identity = i => i

const enhance = compose(
  inject('store'),
  withProps(({store}) => ({
    valueState: store.valueStateStore,
    listingType: store.listingTypeStore,
    uiState: store.uiStateStore
  })),
  setPropTypes({
    valueState: PropTypes.object.isRequired,
    listingType: PropTypes.object.isRequired,
    uiState: PropTypes.object.isRequired
  }),
  observer
)

const SortingSelector = enhance(({ valueState, listingType, uiState }) => (
  <Select
    id='order'
    name='order'
    label='Sort by:'
    options={uiState.orderOptions}
    optionIdentity={identity}
    selectedOptionTemplate={identity}
    optionTemplate={identity}
    value={valueState.values.order || uiState.orderOptions[0]}
    className={'Select--noBorder Select--srp'}
    onChange={value => {
      valueState.setInputValue('order', value)
      valueState.submit(listingType)
    }}
  />
))

export default SortingSelector
