import React from 'react'
import PropTypes from 'prop-types'

const facebookUrl = listingUrl => (
  'https://www.facebook.com/sharer/sharer.php?u=' + listingUrl
)

const twitterUrl = listingUrl => (
  'http://twitter.com/home?status=' + listingUrl
)

const SocialLinks = ({ listingUrl }) => (
  <div className='ShareListing-socialLinks'>
    <a
      className='ShareListing-socialLink ShareListing-socialLink--facebook'
      href={facebookUrl(listingUrl)}
      target='_blank'
    >
      <span className='sr-only'>Facebook</span>
    </a>
    <a
      className='ShareListing-socialLink ShareListing-socialLink--twitter'
      href={twitterUrl(listingUrl)} target='_blank'
    >
      <span className='sr-only'>Twitter</span>
    </a>
  </div>
)

SocialLinks.propTypes = {
  listingUrl: PropTypes.string.isRequired
}

export default SocialLinks
