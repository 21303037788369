import React from 'react'
import PropTypes from 'prop-types'
import { useTurbolinksReload } from '../../../shared_components/Hooks'
import ErrorBoundary from '../../Shared/ErrorBoundary'
import SearchResultsMap from '../../Shared/SearchResultsMap'
import { rootStoreInstance } from '../SearchBar/stores'

const StatefulSearchMap = ({
  header,
  collection,
  useHandDrawing = true,
  usePropertyPolygons = true
}) => {
  useTurbolinksReload({ store: rootStoreInstance, event: 'turbolinks:load' })

  const { listingTypeStore, valueStateStore } = rootStoreInstance

  const handleBoundsChanged = value => {
    valueStateStore.mapBounds = value
  }

  const handleSubmitSearch = () => {
    valueStateStore.mapSearchSubmit(listingTypeStore)
  }

  const handleSearchPolygonChanged = value => {
    valueStateStore.setSearchPolygon(value)
  }

  return (
    <ErrorBoundary>
      <div className='SearchMap'>
        <div className='SearchMap-container'>
          <SearchResultsMap
            header={header}
            collection={collection}

            mapBounds={valueStateStore.values.mapBounds}
            searchPolygon={valueStateStore.values.searchPolygon}
            onSubmitSearch={handleSubmitSearch}
            onBoundsChanged={handleBoundsChanged}
            onSearchPolygonChanged={handleSearchPolygonChanged}
            useHandDrawing={useHandDrawing}
            usePropertyPolygons={usePropertyPolygons}
          />
        </div>
      </div>
    </ErrorBoundary>
  )
}

StatefulSearchMap.propTypes = {
  collection: PropTypes.array,
  header: PropTypes.string.isRequired,
  useHandDrawing: PropTypes.bool,
  usePropertyPolygons: PropTypes.bool
}

export default StatefulSearchMap
