import React from 'react'
import { PropTypes } from 'prop-types'
import { compose, withHandlers, withProps, defaultProps, withStateHandlers, lifecycle } from 'recompose'
import * as R from 'ramda'
import { sanitizeID } from '../../../../lib/utils/string'
import { numberOrString } from '../../../../lib/propTypes'
import { onKey, watchKey } from '../../../../lib/utils/dom'
import { iterator } from '../../../../lib/utils/collection'
import KEY from '../../../../lib/constants/key'
import { noop } from '../../../../lib/utils/common'
import { getId, getName, getChildren } from '../../../../lib/utils/selectors'
import { withScrollToActiveOption } from '../../../Hocs'
import Option from '../Select/Option'

const getCurrentOption = ({ forcedSelectedOptionID, options, optionIdentity, value }) => {
  const optionID = forcedSelectedOptionID || value

  return options.find(o => optionIdentity(o) === optionID)
}

const getInitialState = props => {
  const currentOption = getCurrentOption(props)

  return {
    selectedOption: currentOption,
    highlightedOption: currentOption,
    optionsContainerHeight: 0
  }
}

const enhance = compose(
  defaultProps({
    optionIdentity: getId,
    optionTemplate: getName,
    onChange: noop,
    wrapperOptionsComponent: (getChildren),
    size: 3
  }),
  withStateHandlers(
    props => getInitialState(props),
    {
      setHighlighted: () => option => ({ highlightedOption: option }),
      setSelected: () => option => ({
        selectedOption: option,
        highlightedOption: option
      }),
      setOptionsContainerHeight: () => value => ({ optionsContainerHeight: value })
    }
  ),
  withProps(({ selectedOption, highlightedOption, options, optionIdentity }) => {
    const findIndex = item => options.findIndex(o => o === item)
    const getSanitizedID = R.tryCatch(R.pipe(optionIdentity, sanitizeID), () => null)

    return {
      selectedOptionIndex: findIndex(selectedOption),
      highlightedOptionIndex: findIndex(highlightedOption),
      activeDescendant: getSanitizedID(highlightedOption) || getSanitizedID(selectedOption)
    }
  }),
  withProps(({ options, highlightedOptionIndex }) => ({
    optionsIterator: iterator(options, highlightedOptionIndex)
  })),
  withHandlers(({ setHighlighted, onChange, setSelected }) => {
    const setOption = option => {
      setHighlighted(option)
      onChange(option)
      setSelected(option)
    }

    return {
      nextActiveOption: ({ optionsIterator }) => () => {
        let option = optionsIterator.getNext()

        if (!option.isDisabled) {
          setOption(option)
        } else {
          option = optionsIterator.getPrevious()
        }
      },
      prevActiveOption: ({ optionsIterator }) => () => {
        const option = optionsIterator.getPrevious()
        setOption(option)
      },
      handleSelect: () => option => () => {
        setOption(option)
      },
      handleApply: ({ selectedOption }) => () => {
        setOption(selectedOption)
      }
    }
  }),
  withHandlers({
    onKeyDown: ({ nextActiveOption, prevActiveOption, handleApply }) => e => {
      onKey(e, KEY.DOWN, nextActiveOption)
      onKey(e, KEY.UP, prevActiveOption)
      watchKey(e, KEY.RETURN, handleApply)
    },
    onClick: ({ handleApply }) => () => {
      handleApply()
    }
  }),
  withScrollToActiveOption,
  withHandlers(() => {
    let firstOption = null

    return {
      setRefOption: ({ setActiveOptionRef }) => o => el => {
        if (!firstOption) { firstOption = el}
        // setActiveOptionRef came from withScrollToActiveOption HOC
        setActiveOptionRef(o)(el)
      },
      getOption: () => () => firstOption
    }
  }),
  lifecycle({
    componentDidMount() {
      const size = this.props.size
      const option = this.props.getOption()
      const optionHeight = option ? this.props.getOption().clientHeight : 0

      this.props.setOptionsContainerHeight(optionHeight * size)
    },
    componentDidUpdate(prevProps) {
      if (prevProps.options !== this.props.options) {
        const currentOption = getCurrentOption(this.props)
        this.props.setHighlighted(currentOption)
        this.props.setSelected(currentOption)
      }
    }
  })
)

const ListBox = props => {
  const {
    id,
    options,
    onKeyDown,
    highlightedOption,
    optionIdentity,
    optionTemplate,
    handleSelect,
    setOptionsRef,
    selectedOption,
    setRefOption,
    optionsContainerHeight,
    wrapperOptionsComponent,
    ariaLabel,
    ariaLabelledBy
  } = props

  const WrapperOptionsComponent = wrapperOptionsComponent
  return (
    <div className='ListBox'>
      <WrapperOptionsComponent {...props}>
        <ul
          id={id}
          role='listbox'
          className='ListBox-listOptions'
          ref={setOptionsRef}
          onKeyDown={onKeyDown}
          tabIndex='0'
          style={{ maxHeight: optionsContainerHeight }}
          aria-activedescendant={sanitizeID(optionIdentity(selectedOption))}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
        >
          {
            options.map(option => {
              return (
                <Option
                  id={sanitizeID(optionIdentity(option))}
                  key={optionIdentity(option)}
                  option={option}
                  optionTemplate={optionTemplate}
                  optionIdentity={optionIdentity}
                  onSelect={handleSelect}
                  isHighlighted={option === highlightedOption}
                  isActive={option === selectedOption}
                  activeRef={setRefOption}
                />
              )
            })
          }
        </ul>
      </WrapperOptionsComponent>
    </div>
  )
}

ListBox.defaultProps = {
  optionIdentity: getId,
  optionTemplate: getName,
  onChange: noop,
  ariaLabel: null,
  ariaLabelledBy: null
}

ListBox.propTypes = {
  id: numberOrString,
  options: PropTypes.oneOfType([
    PropTypes.array
  ]).isRequired,
  size: PropTypes.number,
  selectedOption: PropTypes.any,
  onKeyDown: PropTypes.func.isRequired,
  setRefOption: PropTypes.func,
  highlightedOption: PropTypes.object,
  optionIdentity: PropTypes.func,
  optionTemplate: PropTypes.func,
  handleSelect: PropTypes.func,
  setOptionsRef: PropTypes.func.isRequired,
  optionsContainerHeight: PropTypes.number,
  wrapperOptionsComponent: PropTypes.func,
  ariaLabel: PropTypes.string,
  ariaLabelledBy: PropTypes.string
}
export default enhance(ListBox)
