import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useStores } from '../../../shared_components/Hooks'
import SalesForm from './SalesForm'
import RentalsForm from './RentalsForm'
import { SALES_TYPE, RENTALS_TYPE } from '../../Shared/stores/constants'
import Tabs from '../../Shared/Tabs'
import TabPanel from '../../Shared/Tabs/TabPanel'

const CompactSearchBar = () => {
  const { store: { uiStateStore } } = useStores()

  const tabs = [
    {
      tabId: 'search-sales-tab',
      panelId: 'search-sales-panel',
      type: SALES_TYPE,
      ref: React.createRef(),
      label: 'Sales',
      isActive: uiStateStore.isSalesForm
    },
    {
      tabId: 'search-rentals-tab',
      panelId: 'search-rentals-panel',
      type: RENTALS_TYPE,
      ref: React.createRef(),
      label: 'Rentals',
      isActive: uiStateStore.isRentalsForm
    }
  ]

  const tabAction = tab => uiStateStore.setSearchType(tab.type)

  return (
    <div className='CompactSearchBar'>
      <Tabs
        tabs={tabs}
        tabAction={tabAction}
        tabsWrapperClassName='CompactSearchBar-selector'
        tabClassName='CompactSearchBar-link'
        ariaLabel='Start Searching Listings'
      />
      <Fragment>
        {
          uiStateStore.isSalesForm ? (
            <TabPanel id={tabs[0].panelId} labelledBy={tabs[0].tabId}>
              <SalesForm/>
            </TabPanel>
          ) : (
            <TabPanel id={tabs[1].panelId} labelledBy={tabs[1].tabId}>
              <RentalsForm/>
            </TabPanel>
          )
        }
      </Fragment>
    </div>
  )
}

CompactSearchBar.propTypes = {
  uiStateStore: PropTypes.shape({
    isSalesForm: PropTypes.bool.isRequired,
    isRentalsForm: PropTypes.bool.isRequired,
    setSearchType: PropTypes.func.isRequired
  })
}

export default observer(CompactSearchBar)
