import React from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../../shared_components/Hooks'

const CtaInfo = () => {
  const { store: { pricePeriodStore } } = useStores()

  return (
    <div className='Hdp-ctaInfo'>
      <div className='Text Text--a1 Text--slateColor Text--fontWeightMedium'>
        {pricePeriodStore.currentPrice}
      </div>
      <div className='Hdp-ctaInfoLeftSpace'>
        <div className='Text Text--a1 Text--slateColor Text--smallLeftSpace'>
          {pricePeriodStore.currentPeriod}
        </div>
      </div>
    </div>
  )
}

export default observer(CtaInfo)
