import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '../../../../Shared/Checkbox'

const Amenities = ({ valueStateStore }) => (
  <fieldset className='ListingForm-groupFieldset'>
    <legend className='Label'>
      Amenities
    </legend>
    <div className='ListingForm-fieldRowGrid'>
      {valueStateStore.amenities.map(amenity => (
        <Checkbox
          key={amenity}
          name={amenity}
          wrapperClassName='ListingForm-input--checkbox'
          className='Checkbox--grayscale'
          onChange={({target}) => { valueStateStore.setInputValue(amenity, target.checked)}}
          checked={valueStateStore.values[amenity] || false}
        />
      ))}
    </div>
  </fieldset>
)

Amenities.propTypes = {
  valueStateStore: PropTypes.object.isRequired
}

export default Amenities
