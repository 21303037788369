import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import { useRegisterGlobalActions, useStores } from '../../../shared_components/Hooks'
import ShareListingModal from './ShareListingModal'

const ShareListing = () => {
  const { store: { uiStateStore } } = useStores()

  const openModal = () => {
    uiStateStore.toggleShareListingModal(true)
  }

  useRegisterGlobalActions({
    context: { openModal },
    key: 'ShareListing',
    actions: ['openModal']
  })

  return (
    <Fragment>
      <button
        className='Button Button--iconPrefix Button--iconShare Button--secondary Button--focusVisible'
        onClick={openModal}
      >
        Share
      </button>
      <ShareListingModal/>
    </Fragment>
  )
}

export default observer(ShareListing)
