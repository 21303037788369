/* eslint camelcase: 0 */

import { observable, action, makeObservable } from 'mobx'
import { mapGuidesToClient } from '../../../../lib/dataMappings/guides'

class GuidesStore {
  constructor({ transport, isViewport }) {
    makeObservable(this)

    this.transport = transport
    this.isViewport = isViewport
  }

  @observable collection = []

  fetchCollection = blogApiDomain => {
    this.transport.Guides.getCollection(blogApiDomain, {
      featured_on_main: 1,
      order: 'random',
      limit: this.isViewport('mobile') ? 6 : 4
    }).then(
      action('[GuidesStore] Fetch guides collection', collection => {
        if (collection) {
          this.collection = mapGuidesToClient(collection)
        }
      })
    )
  }
}

export default GuidesStore
