import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import { transport, channel } from '../../../lib/services'
import { eventBus } from '../../../lib/utils/dom'
import { fromServerToClient } from '../../../lib/utils/collection'
import ListingStore from './stores/ListingsStore'
import ErrorBoundary from '../../Shared/ErrorBoundary'
import Viewport from '../../Shared/Viewport'
import SearchResultsMapDesktop from './SearchResultsMapDesktop'
import SearchResultsMapMobile from './SearchResultsMapMobile'
import MapStore from './stores/MapStore'

const store = new ListingStore({
  transport,
  channel,
  eventBus
})
const mapStore = new MapStore()

const SearchResultsMap = ({
  header,
  collection,
  cardPreviewClass,
  layout,

  mapBounds,
  searchPolygon,
  onSubmitSearch,
  onBoundsChanged,
  onSearchPolygonChanged,
  useHandDrawing,
  usePropertyPolygons
}) => {
  store.setInitialState({
    collection: fromServerToClient(collection),
    header: header
  })

  useEffect(() => {
    store.updateListingViewedStatus()
  }, [])

  return (
    <ErrorBoundary>
      <Viewport only='desktop'>
        <SearchResultsMapDesktop
          header={header}
          cardPreviewClass={cardPreviewClass}
          layout={layout}
          mapBounds={mapBounds}
          searchPolygon={searchPolygon}
          onSubmitSearch={onSubmitSearch}
          onBoundsChanged={onBoundsChanged}
          onSearchPolygonChanged={onSearchPolygonChanged}
          useHandDrawing={useHandDrawing}
          usePropertyPolygons={usePropertyPolygons}

          store={store}
          mapStore={mapStore}
        />
      </Viewport>
      <Viewport except='desktop'>
        <SearchResultsMapMobile
          header={header}
          cardPreviewClass={cardPreviewClass}
          mapBounds={mapBounds}
          searchPolygon={searchPolygon}
          onSubmitSearch={onSubmitSearch}
          onBoundsChanged={onBoundsChanged}
          onSearchPolygonChanged={onSearchPolygonChanged}
          useHandDrawing={useHandDrawing}
          usePropertyPolygons={usePropertyPolygons}

          store={store}
          mapStore={mapStore}
        />
      </Viewport>
    </ErrorBoundary>
  )
}

SearchResultsMap.propTypes = {
  header: PropTypes.string,
  collection: MobxPropTypes.arrayOrObservableArray,
  cardPreviewClass: PropTypes.string,
  layout: PropTypes.string,
  mapBounds: MobxPropTypes.objectOrObservableObject,
  searchPolygon: MobxPropTypes.arrayOrObservableArray,
  onSubmitSearch: PropTypes.func,
  onBoundsChanged: PropTypes.func,
  onSearchPolygonChanged: PropTypes.func,
  useHandDrawing: PropTypes.bool,
  usePropertyPolygons: PropTypes.bool
}

SearchResultsMap.defaultProps = {
  cardPreviewClass: '',
  header: ''
}

export default SearchResultsMap
