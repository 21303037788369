import React, { useState, Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes, observer } from 'mobx-react'
import Modal from '../../Shared/Modal'
import RoundCloseButton from '../../../shared_components/Shared/RoundCloseButton'
import Map from './MapContainer'
import ListingsMarkerClusterer from './ListingsMarkerClusterer'
import MapControls from '../../../shared_components/Map/MapControls'
import DrawLayer from './DrawLayer'
import PropertyPolygons from '../../../shared_components/Map/PropertyPolygons'
import polygons from '../../../lib/config/polygons.json'
import { Polygon } from '@react-google-maps/api'
import { polygonBaseStyle } from './constants'

const SearchResultsMapMobile = ({
  header,
  cardPreviewClass,
  mapBounds,
  searchPolygon,
  onSubmitSearch,
  onBoundsChanged,
  onSearchPolygonChanged,
  useHandDrawing,
  store,
  mapStore,
  usePropertyPolygons
}) => {
  const [isActive, setIsActive] = useState(false)
  const containerRef = useRef(null)

  const openModal = () => setIsActive(true)
  const closeModal = () => setIsActive(false)

  return (
    <Fragment>
      <Modal
        isOpen={isActive}
        onRequestClose={closeModal}
        closeButtonTemplate={RoundCloseButton}
        ariaHideApp={false}
      >
        <div className='MapContainer' ref={containerRef}>
          <div className='MapContainer-innerBox'>
            <Map
              markers={store.collection}
              mapBounds={mapBounds}
              onBoundsChanged={onBoundsChanged}
              onZoomChanged={mapStore.setZoom}
              googleMapOptions={{ zoomControl: false }}
            >
              {store.highlightedAreas.map((polygon, i) => {
                return polygons[polygon]
                  ? <Polygon
                    key={i}
                    options={polygonBaseStyle}
                    path={polygons[polygon].points}
                  />
                  : null
              })}

              <ListingsMarkerClusterer
                store={store}
                cardPreviewClass={cardPreviewClass}
              />

              <MapControls className='MapControls-mapView'/>

              {useHandDrawing && (
                <DrawLayer
                  mapStore={mapStore}
                  header={header}
                  searchPolygon={searchPolygon}
                  onSubmitSearch={onSubmitSearch}
                  onSearchPolygonChanged={onSearchPolygonChanged}

                  containerRef={containerRef}
                />
              )}

              {usePropertyPolygons && <PropertyPolygons/>}
            </Map>
            <div id='listingCard' className='MapView-listingPreviewCard'/>
          </div>
        </div>
      </Modal>

      <button onClick={openModal} className='MapViewButton MapViewButton--kbdOnlyFocus'>
        <div className='MapViewButton' tabIndex='-1'>Map View</div>
      </button>
    </Fragment>
  )
}

SearchResultsMapMobile.propTypes = {
  header: PropTypes.string,
  cardPreviewClass: PropTypes.string,
  mapBounds: MobxPropTypes.objectOrObservableObject,
  searchPolygon: MobxPropTypes.arrayOrObservableArray,
  onSubmitSearch: PropTypes.func,
  onBoundsChanged: PropTypes.func,
  onSearchPolygonChanged: PropTypes.func,
  useHandDrawing: PropTypes.bool,
  usePropertyPolygons: PropTypes.bool,
  store: PropTypes.shape({
    collection: MobxPropTypes.arrayOrObservableArray,
    highlightedAreas: MobxPropTypes.arrayOrObservableArray
  }).isRequired,
  mapStore: PropTypes.shape({
    setZoom: PropTypes.func.isRequired
  }).isRequired
}

SearchResultsMapMobile.defaultProps = {
  cardPreviewClass: ''
}

export default observer(SearchResultsMapMobile)
