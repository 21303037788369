import { isServer } from './common'

const isValidPath = (name, paths) => (
  name in paths
)

const router = paths => {
  if (isServer()) {
    return
  }

  const pathName = global.location.pathname

  if (isValidPath(pathName, paths)) {
    paths[pathName].call(null)
  }
}

export default router
