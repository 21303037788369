/* eslint no-console: 0 */

import { observable, action, makeObservable, toJS, computed } from 'mobx'
import { lens } from 'lorgnette'
import * as R from 'ramda'
import { fromServerToClient, isPresent } from '../../../../lib/utils/collection'
import { toPolygonName } from '../../../../lib/dataMappings/search'
import { listingCaptchaKey } from '../../../../lib/config/recaptcha'
import { SRP_FORM_VALUE_CHANNEL } from '../../../../lib/constants/channels'
import { fetchFromSessionStorage } from '../../../../lib/utils/browserStorage'
import { VIEWED_LISTING_ID } from '../../../../lib/constants/storageKeys'

const activeItemLens = listing => lens.firstOf(l => l.id === listing.id)

class ListingStore {
  @observable collection = []
  @observable activeItem = null
  @observable hoveredListingId = null
  @observable highlightedAreas = []

  isAllowedRequest = true

  constructor({ transport, channel, eventBus }) {
    makeObservable(this)

    this.transport = transport
    this.channel = channel
    this.channel.init(SRP_FORM_VALUE_CHANNEL)
    this.channel.take(SRP_FORM_VALUE_CHANNEL, this.handleFormChange)

    eventBus.addEventListener('transport.Listing.updateSavedStatus', this.updateListingSaveStatus)
    eventBus.addEventListener('transport.Listing.updateSavedStatusTask', this.updateListingSaveStatus)
    eventBus.addEventListener('send-contact', this.setListingSavedStatus)
  }

  @action('[ListingStore] Set Initial State')
  setInitialState(props) {
    this.header = props.header
    this.collection = fromServerToClient(props).collection
  }

  handleFormChange = ({ value }) => {
    if (isPresent(value.fullTownNames)) {
      this.setHighlightedAreas(value.fullTownNames.map(toPolygonName))
    }
  }

  @action markAsActive(listing) {
    this.activeItem = listing
  }

  @action
  markAsNoActive = () => {
    this.activeItem = null
  }

  @action updateCollection(listings) {
    this.collection = listings
  }

  @action setHoveredListingId = listingId => {
    this.hoveredListingId = parseInt(listingId, 10)
  }

  @action('[ListingStore] update listing viewed status')
  updateListingViewedStatus = () => {
    const viewedListings = fetchFromSessionStorage(VIEWED_LISTING_ID) || {}

    const updatedCollection = this.collection
      .map(listing => viewedListings[listing.id] ? { ...listing, viewed: true } : listing)

    this.updateCollection(updatedCollection)
  }

  @action('[ListingStore] update listing save status')
  updateListingSaveStatus = ({ detail: { data } }) => {
    const listing = fromServerToClient(data)
    const activeItem = toJS(R.find(R.propEq('id', listing.id))(this.collection))
    const links = activeItem ? activeItem.links : listing.links

    this.activeItem = {
      ...activeItem,
      links: { ...links, saveAction: listing.links.saveAction },
      saved: listing.saved
    }

    this.collection = activeItemLens(data).set(this.collection, this.activeItem)
  }

  @action('[ListingStore] set listing saved status')
  setListingSavedStatus = ({ detail: { listingId } }) => {
    activeItemLens({ id: listingId })
      .get(this.collection)
      .then(listing => {
        if (listing.saved) {
          this.activeItem = listing
          return
        }

        this.updateSaveStatusTask(listing)
      })
  }

  @action('[ListingStore] update listing save status')
  setHighlightedAreas = value => {
    this.highlightedAreas = value
  }

  @computed
  get activeItemID() {
    return this.activeItem && this.activeItem.id
  }

  updateSaveStatus = listing => {
    const { links: { saveAction: { url, method } } } = listing
    if (this.isAllowedRequest) {
      this.isAllowedRequest = false
      this.transport.Listing.updateSavedStatus({ url, method, captchaKey: listingCaptchaKey(listing) })
        .then(() => { this.isAllowedRequest = true })
        .catch(error => { console.log(error) })
    }
  }

  updateSaveStatusTask = listing => {
    const { links: { saveAction: { url, method } } } = listing
    if (this.isAllowedRequest) {
      this.isAllowedRequest = false
      this.transport.Listing.updateSavedStatusTask({ url, method, captchaKey: listingCaptchaKey(listing) })
        .then(() => { this.isAllowedRequest = true })
        .catch(error => { console.log(error) })
    }
  }
}

export default ListingStore
