import { observable, action, makeObservable } from 'mobx'

class UIStateStore {
  @observable priceExtremum
  @observable isActive = false
  bedsBathsOptions = []

  constructor() {
    makeObservable(this)
  }

  setInitialState({ initialState }) {
    this.setState(initialState.UIStateStore, initialState.ValueStateStore)
  }

  @action('[UIStateStore] Set state')
  setState = (props, {values} = {}) => {
    const {
      priceExtremum,
      orderOptions,
      pricePeriods = {},
      unitTypesCollection = [],
      statusesCollection = [],
      bedsBathsOptions,
      pricePeriodsDictionary = {}
    } = props

    if (values && values.pricePeriod) {
      const { pricePeriod = 'average_monthly_price', year = '' } = values
      const dictionaryPricePeriod = pricePeriodsDictionary[pricePeriod]
      const isHistoricPrice = !Object.keys(pricePeriods).includes(year.toString())

      if (isHistoricPrice) {
        pricePeriods[year] = [{
          id: pricePeriod,
          isDisabled: true,
          name: dictionaryPricePeriod ? dictionaryPricePeriod.name : 'Invalid Period',
          shortName: dictionaryPricePeriod ? dictionaryPricePeriod.short_name : 'Invalid',
          year: year
        }]
      }
    }

    this.priceExtremum = priceExtremum
    this.orderOptions = orderOptions
    this.pricePeriods = pricePeriods
    this.pricePeriodYears = Object.keys(pricePeriods)
    this.unitTypesCollection = unitTypesCollection
    this.statusesCollection = statusesCollection
    this.bedsBathsOptions = bedsBathsOptions
  }

  @action('[UIStateStore] Open modal') openModal = () => {
    this.isActive = true
  }

  @action('[UIStateStore] Close modal') closeModal = () => {
    this.isActive = false
  }
}

export default UIStateStore
