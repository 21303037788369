import { compose, withHandlers } from 'recompose'
import { observer } from 'mobx-react'
import { withContactFormHandlers } from './utils'
import CreateUserForm from '../../Shared/ContactForm/CreateUserForm'
import { invokeAction } from '../../../lib/globalActions'

const enhance = compose(
  withContactFormHandlers(),
  withHandlers({
    onFormChange: ({ userStore }) => (value, [name]) => {
      userStore.setField(name, value[name])
    },
    onSignIn: ({deactivateContactForm}) => () => {
      deactivateContactForm()
      invokeAction('AuthModal', 'openModal')
    }
  }),
  observer
)

export default enhance(CreateUserForm)
