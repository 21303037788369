import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { compose, withHandlers, withProps, setPropTypes } from 'recompose'
import { inject, observer } from 'mobx-react'
import MediaVideoType from '../MediaVideoType'
import RoundCloseButton from '../Shared/RoundCloseButton'

const enhance = compose(
  inject('store'),
  withProps(({ store }) => ({
    videoTour: store.hdpStore.videoTour,
    changeContentType: store.photoGalleryUIStateStore.changeContentType
  })),
  setPropTypes({
    changeContentType: PropTypes.func.isRequired
  }),
  withHandlers({
    deactivateMapView: ({ changeContentType }) => () => {
      changeContentType('')
      document.body.classList.remove('isModalActive')
    }
  }),
  observer
)

const VideoViewType = ({ videoTour, deactivateMapView }) => (
  <Fragment>
    <MediaVideoType
      videoId={videoTour.videoId}
      mediaType={videoTour.mediaType}
      className='VideoTour--hdp'
    />
    <RoundCloseButton className='RoundCloseButton--videoTour' onClick={deactivateMapView} />
  </Fragment>
)

VideoViewType.propTypes = {
  videoTour: PropTypes.shape({
    videoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mediaType: PropTypes.string.isRequired
  }).isRequired,
  deactivateMapView: PropTypes.func.isRequired
}

export default enhance(VideoViewType)
