import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import routes from '../../../lib/config/routes'
import { flashMessage, gaEvents } from '../../../lib/services'

const AddToBasket = ({ listingId, listingClass, store }) => {
  const [inBasket, setInBasket] = useState(false)

  useEffect(() => {
    setInBasket(store.checkSelectedItem({
      itemId: listingId,
      itemType: listingClass
    }))
  }, [store.selectedItems])

  const toggleBasket = () => {
    store.toggleSelectedItems({
      isItemChecked: !inBasket,
      itemType: listingClass,
      item: { id: listingId }
    }).then(r => {
      if (r.success) {
        flashMessage.show({
          type: 'success',
          text: inBasket
            ? 'Listing removed from the cart.'
            : 'Listing added to the cart.',
          linkText: 'Go to cart',
          linkUrl: routes.showBasket
        })
      }
    }).finally(gaEvents.Hdp.addToCart)
  }

  return (
    <button
      role='switch'
      aria-checked={inBasket}
      className='Button
      Button--autoSized
      Button--focusVisible
      Button--secondary
      Button--iconPrefix
      Button--iconCartPeach'
      onClick={toggleBasket}
    >
      {inBasket ? 'Remove' : 'Add to Cart'}
    </button>
  )
}

AddToBasket.propTypes = {
  listingId: PropTypes.number.isRequired,
  listingClass: PropTypes.string.isRequired,
  store: PropTypes.shape({
    toggleSelectedItems: PropTypes.func.isRequired,
    checkSelectedItem: PropTypes.func.isRequired,
    selectedItems: PropTypes.array.isRequired
  }).isRequired
}

export default observer(AddToBasket)
