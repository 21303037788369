import React from 'react'
import { observer } from 'mobx-react'
import Modal from '../../../Shared/Modal'
import PricePeriodTable from './PricePeriodTable'
import Tabs from '../../../Shared/Tabs'
import { useStores } from '../../../../shared_components/Hooks'

const PricePeriodModal = () => {
  const { store: { uiStateStore, pricePeriodStore } } = useStores()

  const tabs = pricePeriodStore.pricePeriodYears.map(year => (
    {
      tabId: `price-period-${year}-tab`,
      panelId: `price-period-${year}-panel`,
      ref: React.createRef(),
      label: year,
      isActive: year === pricePeriodStore.selectedPeriodYear
    }))

  const activeTab = tabs.find(tab => tab.label === pricePeriodStore.selectedPeriodYear) || {}

  const tabAction = tab => pricePeriodStore.setSelectedPeriodYear(tab.label)

  const closeModal = () => {
    uiStateStore.togglePricePeriodModal(false)
  }

  const handleClick = () => {
    pricePeriodStore.setCurrentPeriodInfo()
    uiStateStore.togglePricePeriodModal(false)
  }

  return (
    <Modal
      isOpen={uiStateStore.isPricePeriodModalOpen}
      onRequestClose={closeModal}
      shouldReturnFocusAfterClose={true}
      ariaHideApp={false}
      aria={{
        label: 'Pricing and Availability modal'
      }}
    >
      <div className='PricePeriodModal'>
        <div className='PricePeriodModal-content'>

          <h2 className='PricePeriodModal-title'>
            Pricing and Availability
          </h2>

          <div className='PricePeriodModal-nav'>
            <Tabs
              tabs={tabs}
              tabAction={tabAction}
              tabsWrapperClassName='PricePeriodNav'
              tabClassName='PricePeriodNav-year'
              ariaLabel='Price period modal'
            />
          </div>

          {tabs.length > 1 ? (
            <div
              className='PricePeriodModal-table'
              role='tabpanel'
              id={activeTab.panelId}
              aria-labelledby={activeTab.tabId}
            >
              <PricePeriodTable/>
            </div>
          ) : (
            <div className='PricePeriodModal-table'>
              <PricePeriodTable/>
            </div>
          )}
          <div className='PricePeriodModal-actions'>
            <div className='PricePeriodModal-action'>
              <button className='Button Button--secondary' onClick={closeModal}>
                Cancel
              </button>
            </div>
            <div className='PricePeriodModal-action'>
              <button className='Button Button--primary' onClick={handleClick}>
                Select dates
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default observer(PricePeriodModal)
