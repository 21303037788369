import React from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../../shared_components/Hooks'
import { isPresent } from '../../../lib/utils/collection'
import SimpleSlider from '../../Shared/SimpleSlider'
import { Viewport } from '../../Shared/Viewport'
import ImpressionCard from '../../Shared/ImpressionCard'

const sliderSettings = {
  speed: 250,
  loop: false,
  items: 1,
  slideBy: 1,
  nav: true,
  navAsThumbnails: true,
  gutter: 15,
  lazyload: true,
  controls: true,
  edgePadding: 1
}

const Guides = () => {
  const { store: GuidesStore } = useStores()

  return (
    <div className='GuideCards'>
      <Viewport except='mobile'>
        <ul className='GuideCards-collection'>
          { GuidesStore.collection.map(guide => (
            <li key={guide.url} className='GuideCards-card'>
              <ImpressionCard className='ImpressionCard--horizontal' content={guide} withDescriptionTruncate={true} />
            </li>
          )) }
        </ul>
      </Viewport>
      <Viewport only='mobile'>
        {isPresent(GuidesStore.collection) && (
          <SimpleSlider
            wrapperClassName='SimpleSlider-wrapper--impressionCards'
            controlsClassName='SimpleSlider-controls--impressionCards'
            settings={sliderSettings}
            ariaLabel='Guides'
          >
            { GuidesStore.collection.map((guide, index) => (
              <li key={guide.url} className='SimpleSlider-item GuideCards-card' data-index={index + 1}>
                <ImpressionCard content={guide} withDescriptionTruncate={true} />
              </li>
            )) }
          </SimpleSlider>
        )}
      </Viewport>
    </div>
  )
}

export default observer(Guides)
