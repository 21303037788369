import { action, observable, computed, toJS, makeObservable } from 'mobx'

class FormStore {
  @observable name = null
  @observable email = null
  @observable phone = null
  @observable errors = {}
  @observable isShowUpdatedCheck = false

  constructor({ transport }) {
    makeObservable(this)

    this.transport = transport
  }

  getFormattedPhoneValue = v => {
    if (this.phone !== null && this.phone.length > v.length) {
      return v
    }

    return v
      .replace(/\D/ig, '')
      .replace(/^(\d{1,3})/ig, '($1')
      .replace(/^\D*(\d{3})/ig, '($1)')
      .replace(/(\(\d+\))(\d{1,3})/ig, '$1 $2')
      .replace(/\D*(\d{3})\D*(\d{3})\D*(\d{1,4}).*/, '($1) $2-$3')
  }

  getFormattedName = v => (
    v.replace(/[^(a-z-' )]/ig, '').replace(/(\W)\1+/g, '$1')
  )

  @computed
  get toForm() {
    return {
      name: this.name,
      email: this.email,
      phone: this.phone
    }
  }

  @computed
  get formErrors() {
    return toJS(this.errors)
  }

  @action('[UserInfoFormStore] Set Initial Data')
  setInitialData = ({ email, name, phone, source }) => {
    this.name = name
    this.email = email
    this.phone = phone
    this.source = source
  }

  @action('[UserInfoFormStore] setErrors')
  setErrors = v => {
    this.errors = v
  }

  @action('[UserInfoFormStore] setField')
  setField = (name, value) => {
    this[name] = value
    this.resetErrors()
  }

  @action('[UserStore] reset errors') resetErrors = () => {
    this.errors = {}
  }

  @action('[UserStore] toggle updated check') showUpdatedCheck = v => {
    this.isShowUpdatedCheck = v
  }

  submit = () => {
    const data = {
      user: this.toForm,
      source: this.source
    }

    this.transport.User.edit(data)
      .then(resp => {
        if (resp && resp.errors) {
          this.setErrors(resp.errors)
          this.showUpdatedCheck(false)
        } else {
          this.showUpdatedCheck(true)
        }
      })
      .finally(()=>{
        setTimeout(() => {
          this.showUpdatedCheck(false)
        }, 5000)
      })
  }
}

export default FormStore
