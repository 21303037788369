import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'
import { isEmpty } from '../../../lib/utils/collection'
import Viewport from '../../Shared/Viewport'
import { useStores } from '../../../shared_components/Hooks'

const ListingsNavigation = () => {
  const { store } = useStores()

  const visitNeighborListing = ({ direction, url }) => e => {
    e.preventDefault()
    store.visitNeighborListing({ direction, url })
  }

  return (
    store.isActive
      ? (<div className='ListingsNavigation'>
        <div className='ListingsNavigation-content'>
          <div className='ListingsNavigation-item'>
            <a
              className={cn('Link Link--secondary Link--iconPrefix Link--iconArrowLeft',
                { isDisabled: isEmpty(store.prevListingUrl) })}
              href={store.prevListingUrl}
              onClick={visitNeighborListing({ direction: 'prev', url: store.prevListingUrl })}
            >
              Prev
            </a>
          </div>
          <div className='ListingsNavigation-item ListingsNavigation-item--backToSearch'>
            <a className='Link Link--secondary' href={store.backToSearchResultsUrl}>
              <Viewport except='mobile'>
                {`(${store.displayIndex} of ${store.totalCount}) Back to Search`}
              </Viewport>
              <Viewport only='mobile'>
                {`(${store.displayIndex} of ${store.totalCount}) To Search`}
              </Viewport>
            </a>
          </div>
          <div className='ListingsNavigation-item ListingsNavigation-item--pullRight'>
            <a
              className={cn('Link Link--secondary Link--iconPostfix Link--iconArrowRight',
                { isDisabled: isEmpty(store.nextListingUrl) })}
              href={store.nextListingUrl}
              onClick={visitNeighborListing({ direction: 'next', url: store.nextListingUrl })}
            >
              Next
            </a>
          </div>
        </div>
      </div>)
      : null
  )
}

export default observer(ListingsNavigation)
