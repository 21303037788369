import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import MapView from '../Map/MapView'
import { useStores } from '../Hooks'
import MarkerClusterNearbyListings from '../Map/MapView/MarkerClusterNearbyListings'
import PropertyPolygons from '../Map/PropertyPolygons'

export const MapViewType = ({ mapButtonRef }) => {
  const { store: { mapStore, photoGalleryUIStateStore } } = useStores()

  return (
    <MapView
      mapStore={mapStore}
      changeContentType={photoGalleryUIStateStore.changeContentType}
      mapButtonRef={mapButtonRef}
    >
      <Fragment>
        <MarkerClusterNearbyListings mapStore={mapStore}/>
        <PropertyPolygons/>
      </Fragment>
    </MapView>
  )
}

MapViewType.propTypes = {
  mapButtonRef: PropTypes.object
}

export default MapViewType
