import React, { useEffect } from 'react'
import { Provider } from 'mobx-react'
import rootStoreInstance from '../stores/rootStoreInstance'
import ShareListing from './ShareListing'
import { withErrorBoundary } from '../../Shared/ErrorBoundary'

const StateFulShareListing = (props) => {
  useEffect(() => {
    rootStoreInstance.setInitialDataShareListing(props)
  }, [props])

  return (
    <Provider store={rootStoreInstance}>
      <ShareListing/>
    </Provider>
  )
}

export default withErrorBoundary(StateFulShareListing)
