import React from 'react'
import PropTypes from 'prop-types'
import { compose, withHandlers } from 'recompose'
import { CheckySelect, NumberInput } from '../../../../Shared/Form'
import Checkbox from '../../../../Shared/Checkbox'
import RectangleRadioButtonGroup from '../../../../Shared/Form/RectangleRadioButtonGroup'

const enhance = compose(
  withHandlers({
    onRadioButtonChange: ({ valueStateStore }) => name => value => {
      valueStateStore.setInputValue(name, value)
    }
  })
)

const PropertyDetails = ({ uiStateStore, valueStateStore, isLand, onRadioButtonChange }) => (
  <fieldset className='ListingForm-groupFieldset'>
    <legend className='ListingForm-groupLabel'>
      Property Details
    </legend>
    <div className='ListingForm-fields'>
      {!isLand && (
        <div className='ListingForm-fieldRow'>
          <RectangleRadioButtonGroup
            name='beds'
            options={uiStateStore.bedsBathsOptions}
            onChange={onRadioButtonChange('beds')}
            value={valueStateStore.values.beds}
            legend='Bedrooms'
          />
        </div>
      )}
      {!isLand && (
        <div className='ListingForm-fieldRow'>
          <RectangleRadioButtonGroup
            name='baths'
            options={uiStateStore.bedsBathsOptions}
            onChange={onRadioButtonChange('baths')}
            value={valueStateStore.values.baths}
            legend='Bathrooms'
          />
        </div>
      )}
      <div className='ListingForm-fieldRow'>
        <label className='Label Label--inline' htmlFor='acreage'>
          Acreage
        </label>
        <div className='ListingForm-input' data-qa-advanced-acr-input>
          <NumberInput
            id='acreage'
            name='acreage'
            options={{min: 0, max: 20}}
            value={valueStateStore.acreageValue}
            onChange={valueStateStore.setInputValue}
          />
        </div>
      </div>
      <div className='ListingForm-fieldRow'>
        <label className='Label Label--inline' htmlFor='sqft'>
          Estimated Sq. Ft.
        </label>
        <div className='ListingForm-input' data-qa-advanced-sqft-input>
          <NumberInput
            id='sqft'
            name='sqft'
            options={{min: 0, max: 10000, step: 500}}
            value={valueStateStore.sqftValue}
            onChange={valueStateStore.setInputValue}
          />
        </div>
      </div>
    </div>
    <div className='ListingForm-fieldRow ListingForm-fieldRow--withColumns ListingForm-fieldRow--pb0'>
      <div className='ListingForm-fieldColumn'>
        <div className='Label' id='listingStatus-label'>
          Listing Status
        </div>
        <div className='ListingForm-input' data-qa-advanced-listing-status-checkbox>
          <CheckySelect
            id='listingStatus'
            name='status'
            className='CheckySelect--stoneBorder'
            options={uiStateStore.statusesCollection}
            value={valueStateStore.values.status || []}
            placeholder='Any'
            onChange={values => { valueStateStore.setInputValue('status', values.map(({id}) => id)) }}
            withScrollIntoView
          />
        </div>
      </div>
      <div className='ListingForm-fieldColumn'>
        <div className='ListingForm-fieldRow ListingForm-fieldRow--pb0'>
          <div className='ListingForm-highlight'>
            <Checkbox
              name='byAgent'
              wrapperClassName='ListingForm-input--checkbox ListingForm-input--singleCheckbox'
              className='Checkbox--grayscale'
              onChange={({target}) => { valueStateStore.setInputValue('byAgent', target.checked) }}
              checked={valueStateStore.values.byAgent || false}
              label='By Agent'
              additionalLabel='NEW'
              qaAdvancedByAgent
            />
          </div>
        </div>
        <div className='ListingForm-fieldRow'>
          <div className='ListingForm-highlight'>
            <Checkbox
              name='byOwner'
              wrapperClassName='ListingForm-input--checkbox ListingForm-input--singleCheckbox'
              className='Checkbox--grayscale'
              onChange={({target}) => { valueStateStore.setInputValue('byOwner', target.checked) }}
              checked={valueStateStore.values.byOwner || false}
              label='By Owner'
              additionalLabel='NEW'
              qaAdvancedByOwner
            />
          </div>
        </div>
      </div>
    </div>
    <div className='ListingForm-fieldRow'>
      <Checkbox
        name='openHouse'
        wrapperClassName='ListingForm-input--checkbox'
        className='Checkbox--grayscale'
        onChange={({target}) => { valueStateStore.setInputValue('openHouse', target.checked) }}
        checked={valueStateStore.values.openHouse || false}
        label='Open House'
        qaAdvancedOpenHouse
      />
    </div>
  </fieldset>
)

PropertyDetails.propTypes = {
  onRadioButtonChange: PropTypes.func,
  valueStateStore: PropTypes.object.isRequired,
  uiStateStore: PropTypes.object.isRequired,
  isLand: PropTypes.bool
}

export default enhance(PropertyDetails)
