import React from 'react'
import { inject, observer } from 'mobx-react'
import { withProps, compose } from 'recompose'
import formPropTypes from './formPropTypes'
import Location from './Filters/Location'
import Price from './Filters/Price'
import PropertyDetails from './Filters/PropertyDetails'
import Amenities from './Filters/Amenities'
import Features from './Filters/Features'
import RentalPeriod from './Filters/RentalPeriod'
import HouseRules from './Filters/HouseRules'

const enhance = compose(
  inject('store'),
  withProps(({ store }) => ({
    uiStateStore: store.uiStateStore,
    valueStateStore: store.valueStateStore
  })),
  observer
)

const RentalForm = ({
  uiStateStore,
  valueStateStore,
  tabConfig
}) => {
  return (
    <div className='ListingForm' role='tabpanel' id={tabConfig.panelId} aria-labelledby={tabConfig.tabId}>
      <div className='ListingForm-group'>
        <Location valueStateStore={valueStateStore} />
        <div className='tablet-viewport'>
          <RentalPeriod uiStateStore={uiStateStore} valueStateStore={valueStateStore} view='tablet' />
        </div>
        <div className='tablet-viewport zindex-minimal'>
          <Price valueStateStore={valueStateStore} uiStateStore={uiStateStore} view='tablet' />
        </div>
        <PropertyDetails valueStateStore={valueStateStore} uiStateStore={uiStateStore} />
        <div className='desktop-viewport'>
          <HouseRules valueStateStore={valueStateStore} view='desktop'/>
        </div>
      </div>
      <div className='ListingForm-group'>
        <div className='desktop-viewport'>
          <RentalPeriod uiStateStore={uiStateStore} valueStateStore={valueStateStore} view='desktop' />
        </div>
        <div className='desktop-viewport'>
          <Price valueStateStore={valueStateStore} uiStateStore={uiStateStore} view='desktop' />
        </div>
        <Features uiStateStore={uiStateStore} valueStateStore={valueStateStore}>
          <Amenities valueStateStore={valueStateStore} />
        </Features>
        <div className='tablet-viewport'>
          <HouseRules valueStateStore={valueStateStore} view='tablet' />
        </div>
      </div>
    </div>
  )
}

RentalForm.propTypes = {
  ...formPropTypes
}

export default enhance(RentalForm)
