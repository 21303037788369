import React from 'react'
import { Provider } from 'mobx-react'
import rootStoreInstance from '../stores/rootStoreInstance'
import ImageGallery from './ImageGallery'
import ErrorBoundary from '../../Shared/ErrorBoundary'
import { useTurbolinksReload } from '../../../shared_components/Hooks'

const StateFulImageGallery = props => {
  rootStoreInstance.setInitialDataImageGallery(props)
  useTurbolinksReload({ store: rootStoreInstance })

  return (
    <ErrorBoundary>
      <Provider store={rootStoreInstance}>
        <ImageGallery/>
      </Provider>
    </ErrorBoundary>
  )
}

export default StateFulImageGallery
