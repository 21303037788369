import React from 'react'
import PropTypes from 'prop-types'
import { HeartFillUrl } from '../../../../shared_components/Svg'
import { PlaceHolderHouseUrl } from '../../../Svg'
import { imageUrlPropType } from '../../../../lib/propTypes'
import { useReplaceBrokenImage } from '../../../../shared_components/Hooks'

const SavedListingItem = ({ listing }) => {
  const { src, source, imgRef } = useReplaceBrokenImage({
    src: listing.imageUrl.jpeg,
    source: { srcSet: listing.imageUrl.webp, type: 'image/webp' },
    fallback: { src: PlaceHolderHouseUrl, type: 'image/png' }
  })

  return (
    <div className='CardsPreview-card'>
      <a className='CardsPreview-link' href={listing.url}>
        <div className='CardsPreview-picture'>
          <picture>
            <source srcSet={source.srcSet} type={source.type}/>
            <img
              ref={imgRef}
              src={src}
              className='CardsPreview-image'
              alt=''
            />
          </picture>
        </div>
        <div className='CardsPreview-info'>
          <div className='CardsPreview-title'>
            {listing.title}
          </div>
          <div className='CardsPreview-address'>
            <div className='CardsPreview-street'>
              {listing.address || '(Undisclosed Address)'}
            </div>
            <div className='CardsPreview-region'>
              {listing.townName}
            </div>
          </div>
        </div>
        <img
          className='CardsPreview-icon'
          alt='Saved Item Indicator'
          src={HeartFillUrl}/>
      </a>
    </div>
  )
}

SavedListingItem.propTypes = {
  listing: PropTypes.shape({
    id: PropTypes.number.isRequired,
    imageUrl: imageUrlPropType,
    title: PropTypes.string.isRequired,
    address: PropTypes.string,
    townName: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
}

export default SavedListingItem
