import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { withTextEllipsis } from '../../Hocs'
import Description from './Description'
import Label from './Label'
import Category from './Category'
import { AllHtmlEntities } from 'html-entities'

const ImpressionCard = ({ content, withTitleTruncate, withDescriptionTruncate, textWithEllipsis, ...props }) => (
  <div
    className={cn('ImpressionCard', props.className)}
  >
    <a
      href={content.url}
      className='ImpressionCard-link'
      role={props.role || null}
      aria-roledescription={props.roleDescription || null}
      target='_blank'
    >
      <span className='sr-only'>
        {AllHtmlEntities.decode(withTitleTruncate && textWithEllipsis || content.title)}
      </span>
    </a>
    <div className='ImpressionCard-blocks'>
      <div className='ImpressionCard-imageContainer ImpressionCard-block'>
        <div className='ImpressionCard-aspectRatioContent'>
          <img
            className='ImpressionCard-image'
            alt={AllHtmlEntities.decode(withTitleTruncate && textWithEllipsis || content.title)}
            src={content.imageUrl} loading='lazy'
          />
        </div>
      </div>
      <div className='ImpressionCard-block ImpressionCard-block--text'>
        <h3 className='ImpressionCard-title' ref={props.applyEllipsisFor}>
          { AllHtmlEntities.decode(withTitleTruncate && textWithEllipsis || content.title) }
        </h3>
        <Description content={content} withTruncate={withDescriptionTruncate} />
        <Category content={content} />
        <Label content={content} />
      </div>
    </div>
  </div>
)

ImpressionCard.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    category: PropTypes.shape({
      link: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  }).isRequired,
  textWithEllipsis: PropTypes.string,
  className: PropTypes.string,
  applyEllipsisFor: PropTypes.func.isRequired,
  withDescriptionTruncate: PropTypes.bool,
  withTitleTruncate: PropTypes.bool,
  role: PropTypes.string,
  roleDescription: PropTypes.string
}

ImpressionCard.defaultProps = {
  withDescriptionTruncate: false,
  withTitleTruncate: false
}

export default withTextEllipsis('.ImpressionCard')(ImpressionCard)
