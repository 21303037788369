/* eslint react/no-unknown-property: 0 */
/* eslint no-shadow: 0 */

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Form from 'react-formal'
import * as R from 'ramda'
import cn from 'classnames'
import routes from '../../../lib/config/routes'
import { onFormChange } from './handlers'
import { signUpUserSchema } from '../../../schemas/user'
import { Captcha, ErrorMessage, InfoMessage } from '../../Shared/Form'
import { Page } from '../stores/UIStateStore'
import { noop } from '../../../lib/utils/common'
import { useStores } from '../../../shared_components/Hooks'
import useCaptchaHandlers from '../../Shared/Form/Captcha/useCaptchaHandlers'

const SignUp = ({ tabs }) => {
  const { store: { userStore, uiStateStore, signUpCaptchaStore } } = useStores()
  const { captchaRef, resetCaptcha } = useCaptchaHandlers()
  const emailFieldRef = useRef(null)
  const passwordFieldRef = useRef(null)

  useEffect(() => {
    signUpCaptchaStore.checkCaptchaActive()
  }, [])

  const tabConfig = R.find(R.propEq('page', Page.signup))(tabs)

  const handleFormChange = onFormChange({ userStore })

  const onFormSubmit = () => {
    userStore
      .handleRegister({ captcha: signUpCaptchaStore.toForm })
      .then(() => {
        signUpCaptchaStore.checkCaptchaActive()
      })
      .then(() => {
        signUpCaptchaStore.resetRecaptchaResponse()
        resetCaptcha()
      })
      .then(() => {
        if (userStore.formErrors) {
          emailFieldRef.current.focus()
        }
      })
  }

  const setFocus = errors => {
    if ('email' in errors) {
      emailFieldRef.current.focus()
    } else if ('password' in errors) {
      passwordFieldRef.current.focus()
    }
  }

  return (
    <div role='tabpanel' id={tabConfig.panelId} aria-labelledby={tabConfig.tabId}>
      <Form className='Form Form--authentication'
            onSubmit={onFormSubmit}
            schema={signUpUserSchema}
            value={userStore.toForm}
            onChange={handleFormChange}
            errors={userStore.formErrors}
            onInvalidSubmit={errors => {
              userStore.setErrors(errors)
              setFocus(errors)
            }}
            onError={noop}
      >
        <div className='Form-wrap Form-wrap--extraSpace'>
          <Form.Message for='unknownUser'>
            {errors => <ErrorMessage id='errorMessage'>{errors}</ErrorMessage>}
          </Form.Message>

          <InfoMessage isActive={!userStore.formErrors.unknownUser}>
            Sign up for an Out East account to stay up to date on your searches,
            save your favorite listings, and more.
          </InfoMessage>

          <div className='Form-row'>
            <label className='Label' htmlFor='email'>Email</label>
            <Form.Field
              id='email'
              type='email'
              name='email'
              errorClass='isInvalid'
              className='TextField'
              placeholder='your@email'
              ref={emailFieldRef}
              aria-describedby='errorMessage signUpEmailError'
              aria-invalid={'email' in userStore.formErrors || null}
              aria-required='true'
              autoComplete='email'
            />
            <Form.Message id='signUpEmailError' className='ErrorText' for='email'/>
          </div>

          <div className='Form-row Form-row--mediumSpace'>
            <p className='sr-only' id='passwordRequirements'>At least 8 characters</p>
            <label className='Label' htmlFor='password'>Password</label>
            <Form.Field
              id='password'
              type='password'
              name='password'
              errorClass='isInvalid'
              className='TextField TextField--defaultFont'
              placeholder='your password'
              ref={passwordFieldRef}
              aria-describedby='errorMessage signUpPasswordError passwordRequirements'
              aria-invalid={'password' in userStore.formErrors || null}
              aria-required='true'
              autoComplete='password'
            />
            <Form.Message id='signUpPasswordError' className='ErrorText' for='password'/>
          </div>

          <Captcha
            isCaptchaActive={signUpCaptchaStore.isCaptchaActive}
            onChange={signUpCaptchaStore.updateRecaptchaResponse}
            setCaptchaRef={captchaRef}
          />

          <Form.Submit
            type='submit'
            className={cn('Button Button--primary Button--withoutBorder Button--size13', {
              isDisabled: !signUpCaptchaStore.isCaptchaValid
            })}
            disabled={!signUpCaptchaStore.isCaptchaValid}
          >
            Sign Up
          </Form.Submit>

          <div className='Form-alternativeLabel'>
          <span className='Text Text--a1 Text--lineHeight19px'>
            OR
          </span>
          </div>

          <a className='Button Button--google' data-method='post' href={routes.googleAuthPath}>
            Continue With Google
          </a>

          {uiStateStore.withAppleAuth &&
            <a className='Button Button--apple' data-method='post' href={routes.appleAuthPath}>
              Continue With Apple
            </a>
          }

          <div className='Form-row Form-row--paddingTop12px'>
            <div className='Text Text--modalBottom Text--alignCenter'>
              I agree to Out East’s
              {' '}
              <a href='/terms'
                 target='_blank'
                 className='Link Link--inline Link--simple'
              >
                Terms of Use
              </a>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

SignUp.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default observer(SignUp)
