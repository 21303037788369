import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { imageUrlPropType } from '../../../lib/propTypes'
import SavedItemsContent from './SavedItemsContent'
import Dropdown from '../Dropdown/Dropdown'

const SavedItemsDropdown = ({ listings, searches, isActive, withHover, id }) => (
  <Dropdown
    id={id}
    className='Dropdown--savedItems'
    withHover={withHover}
    title={
      <Fragment>
        <span>Saved Items</span>
        <div
          role='presentation'
          className={classNames(
            'Header-savedListingsIndicator Header-savedListingsIndicator--inAction',
            { isActive }
          )}
          data-sprinkle='saved-listings-indicator'
        >
          {isActive && <span className='sr-only'>You have new saved items</span>}
        </div>
      </Fragment>
    }
  >
    <SavedItemsContent listings={listings} searches={searches}/>
  </Dropdown>
)

SavedItemsDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  listings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    imageUrl: imageUrlPropType,
    title: PropTypes.string.isRequired,
    address: PropTypes.string,
    townName: PropTypes.string.isRequired
  }).isRequired),
  searches: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired),
  isActive: PropTypes.bool,
  withHover: PropTypes.bool
}

export default SavedItemsDropdown
