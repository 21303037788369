import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import * as R from 'ramda'
import { camelize } from 'humps'
import { onKey, watchKey } from '../../lib/utils/dom'
import KEY from '../../lib/constants/key'
import { delay } from '../../lib/utils/common'
import { cyclicIterator } from '../../lib/utils/collection'

const items = collection => cyclicIterator(collection)

const FooterTown = ({
  name,
  saleTownUrl,
  rentalTownUrl
}) => {
  const controllerRef = useRef(null)
  const contentRef = useRef(null)
  const [isActive, setIsActive] = useState(false)
  const salesField = useRef(null)
  const rentalField = useRef(null)
  const [refs, setRefs] = useState(null)

  const controlsId = `${camelize(name)}-controls`

  const setFocusToController = () => {
    controllerRef.current.focus()
  }

  const onKeyDownController = e => {
    onKey(e, KEY.SPACE, () => setIsActive(true))
    onKey(e, KEY.RETURN, () => setIsActive(true))
    onKey(e, KEY.DOWN, () => {
      setIsActive(true)
      delay(0).then(() => {
        R.head(refs).focus()
      })
    })
    onKey(e, KEY.UP, () => {
      setIsActive(true)
      delay(0).then(() => {
        R.last(refs).focus()
      })
    })
    onKey(e, KEY.ESC, () => setIsActive(false))
  }

  useEffect(() => {
    const refs = [salesField.current, rentalField.current]
    setRefs(refs)

    const links = items(refs)

    const onKeyDownContent = e => {
      onKey(e, KEY.DOWN, () => {
        links.getNext().focus()
      })
      onKey(e, KEY.UP, () => {
        links.getPrevious().focus()
      })
      onKey(e, KEY.ESC, () => {
        setIsActive(false)
        setFocusToController()
      })
      watchKey(e, KEY.TAB, () => {
        delay(0).then(() => {
          const { activeElement } = document

          if (!refs.includes(activeElement)) {
            setIsActive(false)
            setFocusToController()
          }
        })
      })
    }

    contentRef.current.addEventListener('keydown', onKeyDownContent)

    return () => {
      contentRef.current.removeEventListener('keydown', onKeyDownContent)
    }
  }, [])

  return (
    <div className={cn('Town', { isActive })}>
      <button id={name}
        ref={controllerRef}
        className='Town-name'
        aria-haspopup='true'
        aria-controls={controlsId}
        tabIndex='0'
        aria-expanded={isActive || null}
        onKeyDown={onKeyDownController}
      >
        <h2 tabIndex='-1'>{name}</h2>
      </button>
      <ul id={controlsId}
        ref={contentRef}
        className='Town-links'
        role='menu'
        aria-labelledby={name}
      >
        <li role='presentation' className='Town-listingType'>
          <a role='menuitem' className='Town-link' href={saleTownUrl} ref={salesField}>Sales</a>
        </li>
        <li role='presentation' className='Town-listingType'>
          <a role='menuitem' className='Town-link' href={rentalTownUrl} ref={rentalField}>Rentals</a>
        </li>
      </ul>
    </div>
  )
}

FooterTown.propTypes = {
  name: PropTypes.string.isRequired,
  saleTownUrl: PropTypes.string.isRequired,
  rentalTownUrl: PropTypes.string.isRequired
}

export default FooterTown
