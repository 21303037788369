import React, { Fragment, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useStores } from '../../../../shared_components/Hooks'
import { CONTACT_FORM_TYPE } from '../../../../lib/utils/stores'
import { noop } from '../../../../lib/utils/common'
import { Viewport } from '../../../Shared/Viewport'
import PhotoSlider from './PhotoSlider'
import LightBox from '../../../../shared_components/LightBox'
import Carousel from './Carousel'
import LightBoxHeader from './LightBoxHeader'
import Modal from '../../../Shared/Modal/Modal'

const renderContactFormHeader = {
  [CONTACT_FORM_TYPE.owner]: () => (
    <div className='ContactForm-title'>
      Contact Owner
    </div>
  ),
  [CONTACT_FORM_TYPE.agent]: contactFormStore => (
    <Fragment>
      <div className='ContactForm-title'>
        Contact Agent
      </div>
      <div className='Text Text--a2 Text--lineHeight24px'>
        {contactFormStore.agentName} - {contactFormStore.companyName}
      </div>
    </Fragment>
  )
}

const Sliders = ({ mapButtonRef }) => {
  const {
    store: {
      hdpStore,
      uiStateStore,
      photoGalleryUIStateStore,
      contactAgentFormStore,
      contactOwnerFormStore
    }
  } = useStores()
  const [photoSlider, setPhotoSlider] = useState(null)
  const [photoCarousel, setPhotoCarousel] = useState(null)
  const labelId = 'lightbox-label'

  const registerPhotoSliderRef = useCallback(
    ref => {
      setPhotoSlider(ref)
    }
  )

  const registerPhotoCarouselRef = useCallback(ref => {
    setPhotoCarousel(ref)
  })

  const photoSliderGoTo = useCallback(index => {
    if (index < hdpStore.images.length) {
      photoSlider.slickGoTo(index)
    }
  })

  const closeLightBoxActive = useCallback(() => {
    photoGalleryUIStateStore.toggleLightBoxActive(false)
    if (photoGalleryUIStateStore.currentImageIndex >= hdpStore.images.length) {
      photoGalleryUIStateStore.reset()
      photoSliderGoTo(photoGalleryUIStateStore.currentImageIndex)
    }
  })

  return (
    <Fragment>
      {!photoGalleryUIStateStore.isLightBoxActive && (
        <div className='Sliders'>
          <div className='Sliders-photoSlider'>
            <PhotoSlider
              registerPhotoSliderRef={registerPhotoSliderRef}
              photoCarousel={photoCarousel}
              photoSlider={photoSlider}
              mapButtonRef={mapButtonRef}
            />
          </div>
          <Viewport only='desktop'>
            <div className='Sliders-carousel'>
              <Carousel
                registerPhotoCarouselRef={registerPhotoCarouselRef}
                photoSlider={photoSlider}
                photoCarousel={photoCarousel}
              />
            </div>
          </Viewport>
        </div>
      )}
      <Modal
        isOpen={photoGalleryUIStateStore.isLightBoxActive}
        onRequestClose={closeLightBoxActive}
        ariaHideApp={false}
        shouldReturnFocusAfterClose={true}
        overlayClassName='Modal Modal--hdpLightBox Modal--fullScreen'
        closeButtonTemplate={noop}
        aria={{ labelledby: labelId }}
      >
        <LightBox
          addressOrTitle={hdpStore.addressOrTitle}
          toggleLightBoxActive={closeLightBoxActive}
          header={
            <LightBoxHeader
              addressOrTitle={hdpStore.addressOrTitle}
              contactFormStore={uiStateStore.contactType === CONTACT_FORM_TYPE.agent
                ? contactAgentFormStore
                : contactOwnerFormStore}
              contactLabel={uiStateStore.contactType === CONTACT_FORM_TYPE.agent ? 'Contact Agent' : 'Contact Owner'}
              renderContactFormHeader={renderContactFormHeader[uiStateStore.contactType]}
              toggleShareListingModal={uiStateStore.toggleShareListingModal}
              updateSaveStatus={hdpStore.updateSaveStatus}
              isListingSaved={hdpStore.isListingSaved}
              contactTo={hdpStore.contactTo}
              labelId={labelId}
            />
          }
        />
      </Modal>
    </Fragment>
  )
}

Sliders.propTypes = {
  mapButtonRef: PropTypes.object
}

export default observer(Sliders)
