import React from 'react'
import PropTypes from 'prop-types'
import SelectPricePeriodForModal from '../../SelectPricePeriod/SelectPricePeriodForModal'

const RentalPeriod = ({ uiStateStore, valueStateStore, view }) => (
  <fieldset className='ListingForm-groupFieldset'>
    <legend className='ListingForm-groupLabel'>
      Rental Period
    </legend>
    <div className='ListingForm-fields'>
      <div className='ListingForm-fieldRow'>
        <div className='ListingForm-input ListingForm-input--fullWidth ListingForm-input--height50'>
          <SelectPricePeriodForModal
            id={`rental-period-${view}`}
            label='Price Period'
            pricePeriodYears={uiStateStore.pricePeriodYears}
            selectedYear={valueStateStore.values.year}
            pricePeriods={uiStateStore.pricePeriods}
            selectedPricePeriod={valueStateStore.values.pricePeriod}
            onChangePricePeriod={value => {
              valueStateStore.setInputValue('pricePeriod', value.id)
            }}
            onChangeYear={value => {
              valueStateStore.setInputValue('year', value)
            }}
            onResetInputToInitialValue={valueStateStore.resetInputToInitialValue}
          />
        </div>
      </div>
    </div>
  </fieldset>
)

RentalPeriod.propTypes = {
  valueStateStore: PropTypes.object.isRequired,
  uiStateStore: PropTypes.object.isRequired,
  view: PropTypes.string
}

export default RentalPeriod
