import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'
import PricePeriodModal from './PricePeriodModal'
import { CalendarAvailableUrl, CalendarUnAvailableUrl } from '../../Svg'
import { useStores } from '../../../shared_components/Hooks'

const PricePeriod = () => {
  const { store: { uiStateStore, pricePeriodStore } } = useStores()

  const handleClick = () => {
    uiStateStore.togglePricePeriodModal(true)
  }

  return (
    <Fragment>
      <div key='PricePeriod' className='PricePeriod'>
        <div className='PricePeriod-description'>
          <div className='PricePeriod-iconContainer'>
            <img src={pricePeriodStore.isAvailableIcon ? CalendarAvailableUrl : CalendarUnAvailableUrl}
              className='PricePeriod-icon'
              alt=''
            />
          </div>
          <div className='PricePeriod-title'>
            {pricePeriodStore.currentPeriod}
          </div>
          <div className='PricePeriod-action'>
            <button
              className='Button Button--link Button--focusVisible Button--unsetWhiteSpace'
              onClick={handleClick}
            >
              Pricing and Availability
            </button>
          </div>
        </div>
        <div className='PricePeriod-priceInfo'>
          <h3 className='Title Title--secondary'>
            {pricePeriodStore.currentPrice}
          </h3>
          {pricePeriodStore.statusInfo &&
            <div className={cn('Pill Pill--leftSpace', {
              isActive: pricePeriodStore.statusInfo.type === 'positive',
              isInactive: pricePeriodStore.statusInfo.type === 'negative'
            })}>
              <div className='Pill-label Pill-label--status'>{pricePeriodStore.statusInfo.label}</div>
            </div>
          }
        </div>
      </div>
      <PricePeriodModal key='PricePeriodModal'/>
    </Fragment>
  )
}

export default observer(PricePeriod)
