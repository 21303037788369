import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import ContactButton from '../../../Shared/ContactForm/ContactButton'
import FlashMessages from '../../FlashMessages'

const LightBoxHeader = ({
  addressOrTitle,
  toggleShareListingModal,
  updateSaveStatus,
  isListingSaved,
  contactFormStore,
  contactLabel,
  renderContactFormHeader,
  contactTo,
  labelId
}) => (
  <Fragment>
    <FlashMessages className='InfoBar--lightBoxMessage'/>

    <h1 className='LightBox-address' id={labelId}>{addressOrTitle}</h1>

    <ul className='LightBox-actionList'>
      <li className='LightBox-actionListItem'>
        <button onClick={updateSaveStatus}
          className={cn('LightBox-action LightBox-action--iconHeart', { isActive: isListingSaved })}
        >
          {isListingSaved ? 'Saved' : 'Save'}
        </button>
      </li>

      <li className='LightBox-actionListItem'>
        <button className='LightBox-action LightBox-action--iconShare' onClick={() => {toggleShareListingModal(true)}}>
          Share
        </button>
      </li>

      {contactTo.showButton &&
      <li className='LightBox-actionListItem'>
        <ContactButton contactFormStore={contactFormStore} renderHeader={renderContactFormHeader}>
          {({ openModal }) => (
            <button className='Button Button--primary' onClick={openModal}>
              {contactLabel}
            </button>
          )}
        </ContactButton>
      </li>
      }
    </ul>
  </Fragment>
)

LightBoxHeader.propTypes = {
  addressOrTitle: PropTypes.string,
  toggleShareListingModal: PropTypes.func.isRequired,
  contactFormStore: PropTypes.object.isRequired,
  contactLabel: PropTypes.string.isRequired,
  renderContactFormHeader: PropTypes.func.isRequired,
  updateSaveStatus: PropTypes.func.isRequired,
  isListingSaved: PropTypes.bool,
  labelId: PropTypes.string.isRequired,
  contactTo: PropTypes.shape({
    showButton: PropTypes.bool,
    type: PropTypes.string.isRequired
  })
}

export default LightBoxHeader
