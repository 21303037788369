import * as R from 'ramda'
import { cond, renderComponent, renderNothing } from '../../../../components/Hocs'
import { CONTACT_FORM_STATE } from '../../../../lib/utils/stores'
import ContactForm from './ContactFormWrapper'
import CreateUserFormWrapper from './CreateUserFormWrapper'

const ContactModalContent = R.compose(
  cond(
    ({ contactFormState }) => contactFormState,
    {
      [CONTACT_FORM_STATE.inactive]: renderNothing,
      [CONTACT_FORM_STATE.makeContact]: renderComponent(ContactForm),
      [CONTACT_FORM_STATE.createUser]: renderComponent(CreateUserFormWrapper)
    }
  )
)

export default ContactModalContent()


