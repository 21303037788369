import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import Form from 'react-formal'
import cn from 'classnames'
import * as R from 'ramda'
import { isPresent } from '../../../../lib/utils/collection'
import { noop } from '../../../../lib/utils/common'
import { shareListingSchema } from '../../../../schemas/hdpPage'
import { useStores } from '../../../../shared_components/Hooks'
import { useCaptchaHandlers } from '../../../Shared/Form/Captcha'
import { Captcha } from '../../../Shared/Form'

const ShareListingForm = () => {
  const {
    store: { flashMessageStore, shareListingFormStore, shareListingCaptchaStore }
  } = useStores()

  const emailField = useRef(null)
  const recipientEmailField = useRef(null)
  const requiredFields = useRef([emailField, recipientEmailField])
  const { captchaRef, resetCaptcha } = useCaptchaHandlers()

  const handleChangeEmail = e => {
    shareListingFormStore.validateDomain(e.target.value)
  }

  const focusFirstInvalidField = errors => {
    const fields = R.pluck('current', requiredFields.current)
    for (let field of fields) {
      if (field.name in errors) {
        global.requestAnimationFrame(() => {
          field.focus()
        })
        break
      }
    }
  }

  const handleSubmit = () => {
    shareListingFormStore.submit({ captcha: shareListingCaptchaStore.toForm })
      .then(() => {
        shareListingCaptchaStore.checkCaptchaActive()
      })
      .then(() => {
        shareListingCaptchaStore.resetRecaptchaResponse()
        resetCaptcha()

        const errors = shareListingFormStore.formErrors

        if (isPresent(errors)) {
          focusFirstInvalidField(errors)
        }
      })
      .finally(() => {
        setTimeout(() => {
          flashMessageStore.removeFlashMessage(shareListingFormStore.shareListingMessage)
        }, 5000)
      })
  }

  const handleInvalidSubmit = errors => {
    shareListingFormStore.setErrors(errors)
    focusFirstInvalidField(errors)
  }

  const handleFormChange = (value, [name]) => {
    shareListingFormStore.setField(name, value[name])
  }

  const isInvalid = name => shareListingFormStore.isFieldInvalid(name)

  useEffect(() => {
    shareListingCaptchaStore.checkCaptchaActive()

    return () => {
      shareListingFormStore.setErrors({})
    }
  }, [])

  return (
    <Form
      className='Form'
      onSubmit={handleSubmit}
      schema={shareListingSchema}
      value={shareListingFormStore.toForm}
      onChange={handleFormChange}
      errors={shareListingFormStore.formErrors}
      onError={noop}
      onInvalidSubmit={handleInvalidSubmit}
    >
      <div role='alert' aria-live='assertive'>
        <Form.Message for={['error', 'base']}>
          {errors => <p className='ErrorText ErrorText--marginBottom10'>{errors}</p>}
        </Form.Message>

        <p role='status' aria-live='polite' className='ErrorText ErrorText--warning ErrorText--marginBottom10'>
          {shareListingFormStore.domainError}
        </p>
      </div>

      <div className='sr-only' role='alert' aria-live='assertive'>
        {isPresent(shareListingFormStore.formErrors) && 'Errors found'}
      </div>

      <div className='Form-row'>
        <label className='Label' htmlFor='email'>Your Email</label>
        <Form.Field
          id='email'
          name='email'
          type='email'
          required
          placeholder='Your Email'
          className={cn('TextField', { isInvalid: isInvalid('email') })}
          ref={emailField}
          aria-required='true'
          aria-invalid={isInvalid('email') || null}
          aria-errormessage={isInvalid('email') ? 'emailError' : null}
          autoComplete='email'
        />
        <Form.Message id='emailError' className='ErrorText' for='email' role='alert' aria-live='assertive'/>
      </div>

      <div className='Form-row'>
        <label className='Label' htmlFor='recipientEmail'>Share With</label>
        <Form.Field
          id='recipientEmail'
          name='recipientEmail'
          type='email'
          required
          placeholder='Recipient Email'
          className={cn('TextField', { isInvalid: isInvalid('recipientEmail') })}
          onChange={handleChangeEmail}
          ref={recipientEmailField}
          aria-required='true'
          aria-invalid={isInvalid('recipientEmail') || null}
          aria-errormessage={isInvalid('recipientEmail') ? 'recipientEmail' : null}
          autoComplete='email'
        />
        <Form.Message id='recipientEmail' className='ErrorText' for='recipientEmail'/>
      </div>

      <div className='Form-row'>
        <label className='Label' htmlFor='message'>Message (optional)</label>
        <Form.Field
          as='textarea'
          id='message'
          name='body'
          placeholder='Check out this home I found on Out East!'
          className={cn('Textarea', { 'Textarea--shareForm': shareListingCaptchaStore.isCaptchaActive })}
        />
      </div>

      <Captcha
        isCaptchaActive={shareListingCaptchaStore.isCaptchaActive}
        onChange={shareListingCaptchaStore.updateRecaptchaResponse}
        setCaptchaRef={captchaRef}
      />

      <Form.Submit type='submit' className='Button Button--primary Button--withoutBorder'>
        Send request
      </Form.Submit>
    </Form>
  )
}

export default observer(ShareListingForm)
