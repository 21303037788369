import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'mobx-react'
import Stories from './Stories'
import StoriesStore from './stores/StoriesStore'
import { withErrorBoundary } from '../../Shared/ErrorBoundary'
import { transport } from '../../../lib/services'

const StatefulStories = ({ blogApiDomain }) => {
  const storiesStore = new StoriesStore({ transport })

  useEffect(() => {
    storiesStore.fetchCollection(blogApiDomain || '')
  }, [blogApiDomain])

  return (
    <Provider store={storiesStore}>
      <Stories />
    </Provider>
  )
}

StatefulStories.propTypes = {
  blogApiDomain: PropTypes.string
}

export default withErrorBoundary(StatefulStories)
