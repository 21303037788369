/* eslint camelcase: 0 */

import { action, observable, computed, toJS, makeObservable } from 'mobx'
import { SHARE_EMAIL, SHARE_RECIPIENT_EMAIL, CONTACT_EMAIL } from '../../../lib/services/userInfo'
import { renameKeys } from '../../../lib/utils/collection'
import { debounce, noop } from '../../../lib/utils/common'

class ShareListingFormStore {
  @observable email = ''
  @observable recipientEmail = ''
  @observable body = ''
  @observable listingId = ''
  @observable listingClass = ''
  /**
   * Errors structure
   * @typedef {Object} errors
   * @property {string} error - Captcha error message
   * @property {string} base - DENYLISTED_DOMAINS email error
   * @property {Object} errors - Form validation errors
   */
  @observable errors = {}

  @observable shareListingMessage = {}
  /**
   * @type {string} - Known bad domains warning
   */
  @observable domainError = ''

  constructor({ transport, gaEvents, userInfo }) {
    makeObservable(this)

    this.transport = transport
    this.userInfo = userInfo
    this.gaEvents = gaEvents
  }

  @computed
  get toForm() {
    return {
      email: this.email,
      recipientEmail: this.recipientEmail,
      body: this.body
    }
  }

  @computed
  get formErrors() {
    return toJS(this.errors)
  }

  @computed
  get listingType() {
    return `${this.listingClass.toLowerCase()}_listing`
  }

  @action('[ShareListingFormStore] Set Initial Data')
  setInitialData = ({ email, listingId, listingClass }) => {
    this.email = email
      || this.userInfo.getInfo(SHARE_EMAIL)
      || this.userInfo.getInfo(CONTACT_EMAIL)
    this.recipientEmail = this.userInfo.getInfo(SHARE_RECIPIENT_EMAIL)
      || this.recipientEmail
    this.listingId = listingId
    this.listingClass = listingClass
  }

  @action('[ShareListingFormStore] setErrors')
  setErrors = v => {
    this.errors = v
  }

  @action('[ShareListingFormStore] setDomainErrors')
  setDomainError = v => {
    this.domainError = v
  }

  @action('[ShareListingFormStore] setField')
  setField = (name, value) => {
    this[name] = value
    this.errors = {}
  }

  isFieldInvalid = fieldName => {
    return fieldName in this.formErrors
  }

  @action('[ShareListingFormStore] toggle listingId')
  toggleListingId = id => {
    this.listingId = id
  }

  @action('[ShareListingFormStore] validateDomain')
  validateEmailDomain = value => {
    this.transport.User.validateEmailDomain(value)
      .then(response => {
        if (response.errors) {
          this.setDomainError(response.errors.domain)
        } else {
          this.setDomainError('')
        }
      })
      .catch(noop)
  }

  validateDomain = debounce(value => {
    this.validateEmailDomain(value)
  }, 500)

  @action('[ShareListingFormStore] submit')
  submit = (additionalData = {}) => {
    const data = {
      ...additionalData,
      shareForm: {
        ...this.toForm,
        listingId: this.listingId,
        listingClass: this.listingClass
      }
    }

    return this.transport.Listing.sendSharedMessage(data).then(
      action('[ShareListingFormStore] send share listing message', response => {
        if (response.errors) {
          const errors = renameKeys({ recipients: 'recipientEmail' })(response.errors)
          this.setErrors(errors)
        } else if (response.error) {
          this.setErrors({ ...this.errors, error: response.error })
        } else {
          this.setErrors({})
          this.shareListingMessage = { text: response.success, type: 'success' }
          this.gaEvents.Hdp.shareSubmit(this.listingType)
          this.userInfo
            .setInfo(SHARE_EMAIL, this.email)
            .setInfo(SHARE_RECIPIENT_EMAIL, this.recipientEmail)
        }
      })
    )
  }

  sendAnalyticsModalOpen = () => {
    this.gaEvents.Hdp.shareFormOpen(this.listingType)
  }
}

export default ShareListingFormStore
