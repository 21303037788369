/* eslint max-len: 0 */
import React, { Fragment } from 'react'
import Form from 'react-formal'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import { simpleRentalsSearchSchema } from '../../../../schemas/searches'
import { priceLabelWithCutOff } from '../../../../lib/utils/money'
import { noop } from '../../../../lib/utils/common'
import { getValue } from '../../../../lib/utils/selectors'
import { useStores } from '../../../../shared_components/Hooks'
import Dropdown from '../../../Shared/Dropdown/Dropdown'
import { TreeTownSelect, CheckyOptionTemplate, MapOptionsTemplate } from '../../../Shared/Form/TownSelect'
import { PriceInputHome as PriceInput } from '../../../Shared/Form'
import SelectPricePeriod from '../../../Srp/SearchBar/SelectPricePeriod/SelectPricePeriod'
import RectangleRadioButtonGroup from '../../../Shared/Form/RectangleRadioButtonGroup'

const RentalsForm = () => {
  const { store: { searchStore, uiStateStore } } = useStores()

  const handleChangePricePeriod = value => {
    searchStore.setField('pricePeriod', value.id)
  }

  const handleChangeYear = value => {
    searchStore.setField('year', value)
  }

  const handleSubmit = values => {
    searchStore.submit(values)
  }

  const handleOnChange = (values, [name]) => {
    searchStore.setField(name, values[name])
  }

  return (
    <Form
      className='CompactSearchBar-inputs'
      onSubmit={handleSubmit}
      schema={simpleRentalsSearchSchema}
      value={searchStore.toForm}
      onChange={handleOnChange}
      errors={{}}
      onError={noop}
    >
      <div className='CompactSearchBar-inputGroup'>
        <div className='CompactSearchBar-input CompactSearchBar-input--towns'>
          <label className='CompactSearchBar-label Label Label--smaller' htmlFor='towns'>AREAS</label>
          <Form.Field
            className='Multiselect--checky Multiselect--compactSearchBar'
            id='towns'
            name='towns'
            as={TreeTownSelect}
            optionTemplate={CheckyOptionTemplate}
            wrapperOptionsComponent={MapOptionsTemplate}
            height={null}
            options={toJS(searchStore.treeTownOptions)}
            optionsById={searchStore.optionsById}
            selectedOptionsWithParents={searchStore.selectedOptionsWithParents}
            setSelectedOptionsGroup={searchStore.setSelectedOptionsGroup}
            selectedOptionsGroup={toJS(searchStore.selectedOptionsGroup)}
            placeholder='Which area?'
            mapFromValue={getValue}
          />
        </div>
        <div className='CompactSearchBar-input CompactSearchBar-input--periods'>
          <Form.Field
            id='price-period'
            name='pricePeriod'
            label='Price Period'
            as={SelectPricePeriod}
            placeholder='Any Periods'
            pricePeriodYears={uiStateStore.pricePeriodYears}
            selectedYear={searchStore.year}
            pricePeriods={toJS(uiStateStore.allPricePeriods)}
            selectedPricePeriod={searchStore.pricePeriod}
            onChangePricePeriod={handleChangePricePeriod}
            onChangeYear={handleChangeYear}
            onResetInputToInitialValue={searchStore.resetFieldToInitialValue}
            className='Dropdown--compactSearchBar'
          />
        </div>
      </div>
      <div className='CompactSearchBar-inputGroup'>
        <div className='CompactSearchBar-input CompactSearchBar-input--price'>
          <Dropdown
            id='Prices'
            className='Dropdown--compactSearchBar'
            title={
              <Fragment>
                <div className='Dropdown-label'>Price</div>
                <div>{uiStateStore.priceTitle}</div>
              </Fragment>
            }
          >
            <Form.Field
              className='PriceInput--compact'
              name='prices'
              as={PriceInput}
              priceExtremum={uiStateStore.priceExtremum}
              handleTemplate={priceLabelWithCutOff}
            />
          </Dropdown>
        </div>
        <div className='CompactSearchBar-input CompactSearchBar-input--beds'>
          <Dropdown
            id='Bedrooms'
            className='Dropdown--compactSearchBar Dropdown--mobileContentFromRight'
            title={
              <Fragment>
                <div className='Dropdown-label'>Bedrooms</div>
                {uiStateStore.bedsTitle}
              </Fragment>
            }
          >
            <div className='Dropdown-container Dropdown-container--stacked'>
              <Form.Field
                name='beds'
                as={RectangleRadioButtonGroup}
                options={uiStateStore.bedsOptions}
                legend='Bedrooms'
              />
            </div>
          </Dropdown>
        </div>
        <div className='CompactSearchBar-input CompactSearchBar-input--submit'>
          <Form.Submit type='submit' className='Button Button--primary Button--autoSized'>
            SEE HOMES
          </Form.Submit>
        </div>
      </div>
    </Form>
  )
}

export default observer(RentalsForm)
