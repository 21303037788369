import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import Modal from '../Shared/Modal/Modal'
import AuthContent from './AuthContent'
import { Page } from './stores/UIStateStore'
import AuthHeader from './AuthContent/AuthHeader'
import { useStores, useRegisterGlobalActions } from '../../shared_components/Hooks'

const AuthModal = () => {
  const { store: { uiStateStore } } = useStores()

  const tabs = [
    {
      tabId: 'sign-in-tab',
      panelId: 'sign-in-panel',
      type: 'signIn',
      ref: React.createRef(),
      label: 'Sign In',
      isActive: uiStateStore.currentView === Page.signin,
      page: Page.signin
    },
    {
      tabId: 'sign-up-tab',
      panelId: 'sign-up-panel',
      type: 'signUp',
      ref: React.createRef(),
      label: 'Sign Up',
      isActive: uiStateStore.currentView === Page.signup,
      page: Page.signup
    }
  ]

  const openModal = () => {
    uiStateStore.openModal()
  }

  const openSignUpModal = () => {
    uiStateStore.openModal(Page.signup)
  }

  useRegisterGlobalActions({
    context: { openModal, openSignUpModal },
    key: 'AuthModal',
    actions: ['openModal', 'openSignUpModal']
  })

  const shouldShowHeader = uiStateStore.currentView === Page.signin || uiStateStore.currentView === Page.signup

  return (
    <Fragment>
      <div className='Header-actionWrapper'>
        <button className='Header-actionName Header-actionName--mobile' onClick={openModal}>
          Sign In
        </button>
        <button className='Header-actionName Header-actionName--mobile' onClick={openSignUpModal}>
          Sign Up
        </button>
      </div>
      <Modal
        {...uiStateStore.modalSettings}
        isOpen={uiStateStore.isModalOpen}
        onRequestClose={uiStateStore.closeModal}
        ariaHideApp={false}
        portalClassName='ReactModalPortal ReactModalPortal--auth'
        aria={{ label: uiStateStore.currentModalLabel }}
      >
        <div className='sr-only' aria-live='polite' aria-atomic='true'>{uiStateStore.currentModalLabel}</div>
        { shouldShowHeader && <AuthHeader tabs={tabs}/> }
        <AuthContent tabs={tabs}/>
      </Modal>
    </Fragment>
  )
}

export default observer(AuthModal)
