import React, { memo, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { useStores } from '../../../../shared_components/Hooks'
import { noop } from '../../../../lib/utils/common'
import KEY from '../../../../lib/constants/key'
import Arrow from '../../../../shared_components/Arrow'
import SliderIndicator from '../../../../shared_components/SliderIndicator'
import { PlaceHolderHouseUrl } from '../../../Svg'
import MapButton from './MapButton'
import VideoTourButton from '../../../../shared_components/VideoTourButton'

const PhotoSlider = ({
  mapButtonRef,
  photoCarousel,
  registerPhotoSliderRef,
  photoSlider
}) => {
  const { store: { hdpStore, photoGalleryUIStateStore } } = useStores()
  const photoSliderRef = useRef(null)

  useEffect(() => {
    const handleArrows = (e) => {
      if (e.key === KEY.LEFT) {
        photoSlider.slickPrev()
      }
      if (e.key === KEY.RIGHT) {
        photoSlider.slickNext()
      }
    }

    const slides = photoSliderRef.current.querySelectorAll('.slick-slide')
    photoSliderRef.current.addEventListener('keydown', handleArrows)

    slides.forEach((slide) => {
      slide.setAttribute('aria-live', 'polite')
    })

    return () => {
      if (photoSliderRef.current) {
        photoSliderRef.current.removeEventListener('keydown', handleArrows)
      }
    }
  }, [photoSlider])

  const activateLightBox = useCallback(e => {
    e.stopPropagation()
    e.preventDefault()
    photoGalleryUIStateStore.toggleLightBoxActive(true)
  })

  const activateVideoView = useCallback(() => {
    photoGalleryUIStateStore.changeContentType('video')
  })

  const settings = {
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    asNavFor: photoCarousel,
    className: 'PhotoSlider-container',
    nextArrow: <Arrow screenReaderText='Next Slide'/>,
    prevArrow: <Arrow screenReaderText='Previous Slide'/>,
    initialSlide: photoGalleryUIStateStore.currentImageIndex,
    afterChange: index => {
      photoGalleryUIStateStore.updateCurrentImage(index)
    }
  }

  const slideDescription = photoGalleryUIStateStore.images
    .find((_, index) => index === photoGalleryUIStateStore.currentImageIndex).caption || ''

  return (
    <div
      className='PhotoSlider'
      ref={photoSliderRef}
      role='region'
      aria-label='Listing photo'
      aria-roledescription='slider'
    >
      <div className='sr-only' aria-live='polite' aria-atomic='true'>
        {
          `Photo slider slide ${photoGalleryUIStateStore.currentImageIndex + 1}
        of ${photoGalleryUIStateStore.images.length}, 
        ${hdpStore.addressOrTitle}${slideDescription && ', ' + slideDescription}`
        }
      </div>
      <Slider ref={registerPhotoSliderRef} {...settings} >
        {photoGalleryUIStateStore.images.map((media, index) => (
          <div
            key={media.id}
            className='PhotoSlider-slideContainer'
            role='button'
            aria-label={`${hdpStore.addressOrTitle} ${media.caption || ''}`}
            onClick={activateLightBox}
            onKeyDown={noop}
            tabIndex='-1'
          >
            <picture>
              <source srcSet={media.carousel.webp} type='image/webp'/>
              <img
                src={media.carousel.jpeg}
                className='PhotoSlider-image'
                onError={() => {
                  photoGalleryUIStateStore.replaceImageUrl(index, PlaceHolderHouseUrl)
                }}
                alt={`${hdpStore.addressOrTitle} ${media.caption || ''}`}
                loading={index === 0 ? null : 'lazy'}
              />
            </picture>
          </div>
        ))}
      </Slider>
      <SliderIndicator totalCount={photoGalleryUIStateStore.images.length}/>
      <button
        className='PhotoSlider-expandButton'
        onClick={activateLightBox}
      >
        <div className='PhotoSlider-expandButtonInner'>
          <span className='sr-only'>Full Screen</span>
        </div>
      </button>
      <div className='PhotoSlider-controlsPreview'>
        {hdpStore.videoTour && <VideoTourButton onClick={activateVideoView}/>}
        <MapButton mapButtonRef={mapButtonRef}/>
      </div>
    </div>
  )
}

PhotoSlider.propTypes = {
  registerPhotoSliderRef: PropTypes.func,
  mapButtonRef: PropTypes.object,
  photoCarousel: PropTypes.object,
  photoSlider: PropTypes.object
}

export default memo(PhotoSlider)
