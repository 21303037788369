import React, { useEffect } from 'react'
import { Provider } from 'mobx-react'
import { transport, sessionStorage, location, gaEvents } from '../../../lib/services'
import ListingsNavigationStore from './stores/ListingsNavigationStore'
import ListingsNavigation from './ListingsNavigation'
import { withErrorBoundary } from '../../Shared/ErrorBoundary'

const StatefulListingsNavigation = props => {
  const listingsNavigationStore = new ListingsNavigationStore({ transport, sessionStorage, location, gaEvents })

  useEffect(()=>{
    listingsNavigationStore.setInitialData(props)
  }, [props])

  return (
    <Provider store={listingsNavigationStore}>
      <ListingsNavigation />
    </Provider>
  )
}

export default withErrorBoundary(StatefulListingsNavigation)
