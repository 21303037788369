import { flatten, isEmpty, uniqBy } from './collection'
import { isDefined } from './common'
import { numberShortFormat, withCurrency } from './money'

export const numericField = ({
  key,
  descriptionPrefix = key,
  emptyValueDescription = null
}) => values => {
  if (values[key]) {
    return `${descriptionPrefix} ${values[key]}`
  }

  return emptyValueDescription
}

export const priceField = ({ emptyValueDescription = 'Any Price' }) => (_, { valueStateStore }) => {
  const { priceValues } = valueStateStore
  const [priceFrom, priceTo] = priceValues

  if (isDefined(priceFrom) && isDefined(priceTo)) {
    return `${withCurrency(numberShortFormat(priceFrom))} - ${withCurrency(numberShortFormat(priceTo))}`
  }

  return emptyValueDescription
}

export const policyField = ({ key, defaultPolicy = 'Any' }) => values => {
  const policyValue = values[key]

  if (policyValue && policyValue !== defaultPolicy) {
    return policyValue
  }

  return null
}

export const stringCollectionField = ({ key, emptyValueDescription = null }) => values => {
  if (values[key]) {
    return values[key]
  }

  return emptyValueDescription
}

export const periodFieldByYear = ({ key, contextKey }) => (values, context) => {
  const year = values.year

  if (values[key]) {
    const pricePeriod = context[contextKey][year].find(pp => pp.id === values[key])
    return pricePeriod ? pricePeriod.shortName : null
  }

  return null
}

export const optionsField = ({ key, contextKey, emptyValueDescription = null }) => (values, context) => {
  if (values[key]) {
    return values[key]
      .map(status => context[contextKey].find(s => s.id === status))
      .filter(v => v)
      .map(status => status.name)
  }

  return emptyValueDescription
}

export const contextField = ({ contextKey, descriptionPrefix = '' }) => (_, context) => {
  if (context[contextKey]) {
    return descriptionPrefix + context[contextKey]
  }

  return null
}

export const jsonField = ({ key }) => (values, context) => (
  JSON.stringify(values[key] || context[key])
)

export const filtersToDescriptionsSchema = {
  listingType: contextField({ contextKey: 'listingType' }),
  beds: numericField({ key: 'beds', descriptionPrefix: 'Beds', emptyValueDescription: 'Any Beds' }),
  baths: numericField({ key: 'baths', descriptionPrefix: 'Baths', emptyValueDescription: 'Any Baths' }),
  acreage: numericField({ key: 'acreage', descriptionPrefix: 'Acreage', emptyValueDescription: 'Any Acreage' }),
  sqft: numericField({ key: 'sqft', descriptionPrefix: 'Sqft', emptyValueDescription: 'Any Sqft' }),
  priceFrom: priceField({}),
  priceTo: priceField({}),
  petPolicy: policyField({ key: 'petPolicy' }),
  smokingPolicy: policyField({ key: 'smokingPolicy' }),
  amenityNames: stringCollectionField({ key: 'amenityNames', emptyValueDescription: 'Any Amenities' }),
  townNames: stringCollectionField({ key: 'townNames', emptyValueDescription: 'Any Location' }),
  pricePeriods: periodFieldByYear({ key: 'pricePeriod', contextKey: 'pricePeriods' }),
  status: optionsField({ key: 'status', contextKey: 'statusesCollection', emptyValueDescription: 'Any Status' }),
  unitTypes: optionsField({
    key: 'unitTypes',
    contextKey: 'unitTypesCollection',
    emptyValueDescription: 'Any Unit Type'
  })
}

export const filtersToJSONSchema = {
  listingType: jsonField({ key: 'listingType' }),
  beds: jsonField({ key: 'beds' }),
  baths: jsonField({ key: 'baths' }),
  acreage: jsonField({ key: 'acreage' }),
  sqft: jsonField({ key: 'sqft' }),
  priceFrom: jsonField({ key: 'priceFrom' }),
  priceTo: jsonField({ key: 'priceTo' }),
  petPolicy: jsonField({ key: 'petPolicy' }),
  smokingPolicy: jsonField({ key: 'smokingPolicy' }),
  amenityNames: jsonField({ key: 'amenityNames' }),
  townNames: jsonField({ key: 'townNames' }),
  pricePeriods: jsonField({ key: 'pricePeriods' }),
  status: jsonField({ key: 'status' }),
  unitTypes: jsonField({ key: 'unitTypes' })
}

const filtersDescriptionMapping = schema => (values, context) => (
  flatten(
    uniqBy(
      Object.keys(schema)
        .map(filter => schema[filter](values, context))
        .filter(v => !isEmpty(v)),
      x => x
    )
  )
)

export default filtersDescriptionMapping
