import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { imageUrlPropType } from '../../../../lib/propTypes'
import SavedListingItem from './SavedListingItem'
import NoSavedItems from '../NoSavedItems'

const SavedListings = ({ listings, type }) => (
  <Fragment>
    {listings.length ? (
      <div className='CardsPreview'>
        <div className='CardsPreview-cards CardsPreview-cards--width302px'>
          {listings.map(listing => (
            <SavedListingItem key={listing.id} listing={listing} />)
          )}
        </div>
      </div>
    ) : <NoSavedItems type={type} />}
  </Fragment>

)

SavedListings.propTypes = {
  listings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    imageUrl: imageUrlPropType,
    title: PropTypes.string.isRequired,
    address: PropTypes.string,
    townName: PropTypes.string.isRequired
  }).isRequired),
  type: PropTypes.string.isRequired
}

export default SavedListings
