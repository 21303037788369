import React from 'react'
import { hideIfNoData } from '../../Hocs'

const Category = hideIfNoData(({ content }) => !content.category)(
  ({ content }) => (
    <a className='ImpressionCard-categoryUrl' href={content.category.link} target='_blank'>
      { content.category.name }
    </a>
  )
)

export default Category
