import React, { memo, useEffect } from 'react'
import { Provider } from 'mobx-react'
import rootStoreInstance from '../stores/rootStoreInstance'
import SavedButton from './SavedButton'
import { withErrorBoundary } from '../../Shared/ErrorBoundary'
import PropTypes from 'prop-types'

const StateFulSavedButton = (props) => {
  useEffect(() => {
    rootStoreInstance.setInitialDataSavedButton(props)
  }, [props])

  return (
    <Provider store={rootStoreInstance}>
      <SavedButton variant={props.variant}/>
    </Provider>
  )
}

StateFulSavedButton.propTypes = {
  variant: PropTypes.string
}

export default withErrorBoundary(memo(StateFulSavedButton))
