/* eslint-disable camelcase */
import { observable, action, makeObservable } from 'mobx'
import { mapStoriesToClient } from '../../../../lib/dataMappings/stories'

class StoriesStore {
  @observable collection = []

  constructor({ transport }) {
    makeObservable(this)

    this.transport = transport
  }

  fetchCollection = blogApiDomain => {
    this.transport.Stories.getCollection(blogApiDomain, {
      featured_on_main: 1,
      limit: 6
    }).then(
      action('[StoriesStore] Fetch stories collection', collection => {
        this.collection = collection ? mapStoriesToClient(collection) : []
      })
    )
  }
}

export default StoriesStore
