import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Tabs from '../../Shared/Tabs/Tabs'
import { useStores } from '../../../shared_components/Hooks'

const AuthHeader = ({ tabs }) => {
  const { store: { uiStateStore } } = useStores()
  const tabAction = tab => uiStateStore.updateCurrentView(tab.page)

  return (
    <div className='SimpleTabs'>
      <Tabs
        tabs={tabs}
        tabAction={tabAction}
        tabsWrapperClassName='SimpleTabs-header SimpleTabs-header--modal'
        tabClassName='SimpleTabs-item'
        ariaLabel='Auth modal'
        withAutoFocus
      />
    </div>
  )
}

AuthHeader.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    tabId: PropTypes.string.isRequired,
    panelId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    ref: PropTypes.object.isRequired,
    label: PropTypes.string,
    isActive: PropTypes.bool,
    page: PropTypes.string
  }).isRequired)
}

export default observer(AuthHeader)
