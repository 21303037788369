import * as yup from 'yup'
import Messages from './messages'

export const shareListingSchema = yup.object({
  email: yup.string().nullable()
    .email(Messages.email.valid)
    .required(Messages.email.required),
  recipientEmail: yup.string()
    .email(Messages.recipientEmail.valid)
    .required(Messages.recipientEmail.required),
  body: yup.string()
})
