import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'mobx-react'
import Guides from './Guides'
import GuidesStore from './stores/GuidesStore'
import { transport } from '../../../lib/services'
import { isViewport } from '../../../lib/utils/viewport'
import { withErrorBoundary } from '../../Shared/ErrorBoundary'

const StatefulGuides = ({ blogApiDomain }) => {
  const guidesStore = new GuidesStore({ transport, isViewport })

  useEffect(() => {
    guidesStore.fetchCollection(blogApiDomain || '')
  }, [blogApiDomain])

  return (
    <Provider store={guidesStore}>
      <Guides />
    </Provider>
  )
}

StatefulGuides.propTypes = {
  blogApiDomain: PropTypes.string
}

export default withErrorBoundary(StatefulGuides)
