import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'
import AvailableIcon from './AvailableIcon'
import NotAvailableIcon from './NotAvailableIcon'
import { getPeriodsByYear } from '../utils'
import { useStores } from '../../../../shared_components/Hooks'

const priceInfo = price => (
  price || '—'
)

const PricePeriodTable = () => {
  const { store: { pricePeriodStore } } = useStores()

  const handleClick = v => () => {
    pricePeriodStore.setSelectedPeriod(v)
  }

  return (
    <table className='PricePeriodTable'>
      <thead>
        <tr className='PricePeriodTable-headRow'>
          <th className='PricePeriodTable-header PricePeriodTable-header--period' scope='col'>Rental Period</th>
          <th className='PricePeriodTable-header' scope='col'>Price</th>
          <th className='PricePeriodTable-header PricePeriodTable-header--avail' scope='col'>
            <div className='exclude-mobile-viewport'>Availability</div>
            <div className='mobile-viewport'>AVAIL</div>
          </th>
        </tr>
      </thead>

      <tbody className='PricePeriodTable-body'>
        {getPeriodsByYear(pricePeriodStore.selectedPeriodYear, pricePeriodStore.prices).map((period, index) => (
          <tr key={index} className='PricePeriodTable-row'>
            <th className='PricePeriodTable-cell PricePeriodTable-cell--period' scope='row'>
              <div className={cn('Radio Radio--mediumLabel', { isDisabled: !period.availability })}>
                <label className='Radio-label' htmlFor={`period-${index}`}>
                  <input
                    id={`period-${index}`}
                    name='period'
                    type='radio'
                    defaultChecked={pricePeriodStore.currentPeriod === period.name}
                    disabled={!period.availability}
                    className='Radio-input'
                    onClick={handleClick(period)}
                  />
                  <span className='Radio-icon'/>
                  {period.name}
                </label>
              </div>
            </th>

            <td className='PricePeriodTable-cell PricePeriodTable-cell--textCenter'>
              <div className={cn('PricePeriodTable-priceValue', { isDisabled: !period.availability })}>
                {priceInfo(period.price)}
              </div>
            </td>

            <td className='PricePeriodTable-cell PricePeriodTable-cell--textCenter'>
              {period.availability ? <AvailableIcon/> : <NotAvailableIcon/>}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default observer(PricePeriodTable)
