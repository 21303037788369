import { inject, observer } from 'mobx-react'
import * as R from 'ramda'
import { renderComponent, cond } from '../../Hocs'
import { Page } from '../stores/UIStateStore'
import SignIn from './SignIn'
import SignUp from './SignUp'
import PasswordResetConfirm from './PasswordResetConfirm'
import PasswordReset from './PasswordReset'
import PasswordEdit from './PasswordEdit'
import PasswordSet from './PasswordSet'

const enhance = R.compose(
  inject('store'),
  observer
)

const AuthContent = cond(
  ({ store }) => store.uiStateStore.currentView,
  {
    [Page.signin]: renderComponent(SignIn),
    [Page.signup]: renderComponent(SignUp),
    [Page.confirm]: renderComponent(PasswordResetConfirm),
    [Page.forgot]: renderComponent(PasswordReset),
    [Page.edit]: renderComponent(PasswordEdit),
    [Page.setPassword]: renderComponent(PasswordSet)
  }
)()

export default enhance(AuthContent)
