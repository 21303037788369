import { observe } from 'mobx'
import UIStateStore from './UIStateStore'
import DraftListingStore from './DraftListingStore'

class RootStore {
  constructor(context) {
    this.uiStateStore = new UIStateStore()
    this.draftListingStore = new DraftListingStore(context)
    this.setObservers()
  }

  setInitialData = data => {
    this.draftListingStore.setInitialData(data)
    this.uiStateStore.setInitialData(data)
  }

  setObservers() {
    observe(this.uiStateStore, 'isModalOpen', ({ newValue }) => {
      if(!newValue) {
        this.draftListingStore.resetErrors()
        this.draftListingStore.resetValue()
      }
    })
  }
}

export default RootStore
