/* eslint camelcase: 0 */
/* eslint no-undefined: 0 */

import { action, makeObservable, observable, observe } from 'mobx'
import { CONTACT_FORM_STATE } from '../../../lib/utils/stores'

class ContactAgentUIStateStore {
  @observable contactFormState = CONTACT_FORM_STATE.inactive

  constructor({ gaEvents }) {
    makeObservable(this)

    this.gaEvents = gaEvents

    this.setObservers()
  }

  @action('[withContactForm] Reset')
  reset = () => {
    this.contactFormState = CONTACT_FORM_STATE.inactive
  }

  @action('[withContactForm] set contactFormState')
  setContactFormState = v => {
    this.contactFormState = v
  }

  setObservers() {
    observe(this, 'contactFormState', ({ newValue }) => {
      if (newValue === CONTACT_FORM_STATE.makeContact) {
        this.gaEvents.AgentPage.contactFormOpen()
      }
    })
  }
}

export default ContactAgentUIStateStore
