import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'mobx-react'
import TownGuide from './TownGuide'
import TownGuideStore from './stores/TownGuideStore'
import { withErrorBoundary } from '../../Shared/ErrorBoundary'
import { transport } from '../../../lib/services'

const StatefulTownGuide = ({blogApiDomain, townShortName}) => {
  const townGuideStore = new TownGuideStore({ transport })

  useEffect(() => {
    townGuideStore.fetchData(blogApiDomain || '', townShortName)
  }, [blogApiDomain, townShortName])

  return (
    <Provider store={townGuideStore}>
      <TownGuide/>
    </Provider>
  )
}

StatefulTownGuide.propTypes = {
  blogApiDomain: PropTypes.string,
  townShortName:PropTypes.string
}

export default withErrorBoundary(StatefulTownGuide)
