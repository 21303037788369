import React from 'react'
import { hideIfNoData } from '../../Hocs'

const Label = hideIfNoData(({ content }) => !content.label)(
  ({ content }) => (
    <div className='ImpressionCard-label'>
      { content.label }
    </div>
  )
)

export default Label
