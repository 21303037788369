import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'
import OpenListingSlider from './OpenListingSlider'
import { useStores } from '../../../shared_components/Hooks'

export const OpenListings = () => {
  const { store: { uiStateStore } } = useStores()

  const activateOpenListings = () => {
    uiStateStore.toggleShownOpenListings()
  }

  return (
    <div className='InfoBar'>
      <div className='InfoBar-content InfoBar-content--centerContent InfoBar-content--height54px'>
        <div className='InfoBar-text'>
          <span className='Text Text--a1 Text--fontWeightMedium Text--lineHeight16px'>
            This listing has been posted by more than one agent
          </span>
        </div>
        <div className='InfoBar-link'>
          <span className='InfoBar-linkPosition'>
            <button className='Button Button--link' onClick={activateOpenListings}>
              {uiStateStore.isShownOpenListings ? 'View less' : 'View more'}
            </button>
            <div className={cn('InfoBar-popover', { isActive: uiStateStore.isShownOpenListings })}>
              <div className='Popover'>
                <div className='Popover-arrow'/>
                <div className='Popover-content Popover-content--agentsSlider'>
                  {uiStateStore.isShownOpenListings && <OpenListingSlider/>}
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  )
}

export default observer(OpenListings)
