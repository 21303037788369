import React, { useEffect, useMemo } from 'react'
import { Provider } from 'mobx-react'
import { transport, sessionStorage, gaEvents, location, userInfo } from '../../lib/services'
import { isViewport } from '../../lib/utils/viewport'
import RootStore from './stores/RootStore'
import ContactButton from '../Shared/ContactForm/ContactButton'
import { withErrorBoundary } from '../Shared/ErrorBoundary'

const StateFulContactAgent = props => {
  const rootStore = useMemo(() => {
    const rootStoreInstance = new RootStore({
      transport,
      isViewport,
      sessionStorage,
      gaEvents,
      location,
      userInfo
    })
    rootStoreInstance.setInitialDataContactAgent(props)
    return rootStoreInstance
  }, [])

  useEffect(() => {
    const resetStore = () => {
      rootStore.reset()
    }
    document.addEventListener('turbolinks:before-cache', resetStore)
    return () => {
      document.removeEventListener('turbolinks:before-cache', resetStore)
    }
  }, [rootStore])

  return(
    <Provider store={rootStore}>
      <ContactButton contactFormStore={rootStore.contactAgentFormStore}>
        {({ openModal }) => (
          <button className='Button Button--primary'
            onClick={openModal}>
            Contact agent
          </button>
        )}
      </ContactButton>
    </Provider>
  )
}

export default withErrorBoundary(StateFulContactAgent)
