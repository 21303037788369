import React from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../../shared_components/Hooks'
import cn from 'classnames'
import { isEmpty } from '../../../lib/utils/collection'

const StatusInfo = () => {
  const { store: { hdpStore } } = useStores()

  return (
    !isEmpty(hdpStore.statusInfo)
      ? <div className={cn('Pill Pill--leftSpace', {
        isActive: hdpStore.statusInfo.type === 'positive',
        isInactive: hdpStore.statusInfo.type === 'negative'
      })}
      >
        <div className='Pill-label Pill-label--status'>
          {hdpStore.statusInfo.label}
        </div>
      </div>
      : null
  )
}

export default observer(StatusInfo)
