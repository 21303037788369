import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { noop } from '../../../../lib/utils/common'
import { childrenPropType } from '../../../../lib/propTypes'
import selectPeriodEnhance, { buildTabID, buildPanelID } from './selectPeriodEnhance'
import Dropdown from '../../../Shared/Dropdown'
import ListBox from '../../../Shared/Form/ListBox'
import Tabs from '../../../Shared/Tabs/Tabs'

const SelectPricePeriod = ({
  id,
  label,
  tabs,
  tabAction,
  selectedValuesLabel,
  pricePeriodOptions,
  pricePeriodSelectedOption,
  pricePeriodYear,
  onChangePricePeriod,
  registerDropdown,
  footer
}) => (
  <Dropdown
    id={id}
    name={id}
    className='Dropdown--advancedFilters'
    title={
      <Fragment>
        <div id={`${id}-label`} className='sr-only'>{label}</div>
        <div>{selectedValuesLabel}</div>
      </Fragment>
    }
    ref={registerDropdown.bind(null, 'pricePeriod')}
    withAutofocus={false}
  >
    <div className='Dropdown-container Dropdown-container--stacked Dropdown-container--withoutPadding'>
      <Tabs
        tabs={tabs}
        tabAction={tabAction}
        tabsWrapperClassName='PricePeriodNav'
        tabClassName='PricePeriodNav-year'
        ariaLabel='Select year'
        withAutoFocus
      />
      <div role='tabpanel'
        id={buildPanelID(pricePeriodYear)}
        aria-labelledby={buildTabID(pricePeriodYear)}
      >
        <ListBox
          options={pricePeriodOptions}
          value={pricePeriodSelectedOption}
          onChange={onChangePricePeriod}
          optionClassName='Option Option--large'
          size={7}
          ariaLabelledBy={`${id}-label`}
        />
      </div>
    </div>
    {footer && footer}
  </Dropdown>
)

SelectPricePeriod.defaultProps = {
  registerDropdown: noop
}

SelectPricePeriod.propTypes = {
  id: PropTypes.string.isRequired,
  pricePeriodOptions: PropTypes.array.isRequired,
  pricePeriodSelectedOption: PropTypes.string,
  pricePeriodYear: PropTypes.string.isRequired,
  onChangePricePeriod: PropTypes.func.isRequired,
  label: PropTypes.string,
  selectedValuesLabel: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    tabId: PropTypes.string.isRequired,
    panelId: PropTypes.string.isRequired,
    ref: PropTypes.object.isRequired,
    label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.number.isRequired,
    isActive: PropTypes.bool
  }).isRequired),
  tabAction: PropTypes.func.isRequired,
  registerDropdown: PropTypes.func,
  footer: childrenPropType
}

export default selectPeriodEnhance(SelectPricePeriod)
