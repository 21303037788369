/* eslint react/no-unknown-property: 0 */
/* eslint no-shadow: 0 */
/* eslint jsx-a11y/no-autofocus: 0 */

import React, { useRef } from 'react'
import { observer } from 'mobx-react'
import Form from 'react-formal'
import { onFormChange } from './handlers'
import { ErrorMessage } from '../../Shared/Form'
import { resetPasswordSchema } from '../../../schemas/user'
import { noop } from '../../../lib/utils/common'
import { useStores } from '../../../shared_components/Hooks'

const PasswordEdit = () => {
  const { store: { userStore, uiStateStore } } = useStores()
  const passwordRef = useRef(null)
  const passwordConfirmationRef = useRef(null)

  const handleFormChange = onFormChange({ userStore })

  const setFocusOnFirstError = errors => {
    if (errors.password) {
      passwordRef.current.focus()
    } else if (errors.passwordConfirmation) {
      passwordConfirmationRef.current.focus()
    }
  }

  return (
    <Form
      className='Form Form--authentication'
      onSubmit={userStore.handleEditPassword}
      schema={resetPasswordSchema}
      value={userStore.toForm}
      onChange={handleFormChange}
      errors={userStore.formErrors}
      onError={noop}
      onInvalidSubmit={errors => {
        setFocusOnFirstError(errors)
        userStore.setErrors(errors)
      }}
    >
      <div className='Form-wrap Form-wrap--extraSpace'>
        <div className='Form-row Form-row--smallSpace'>
          <div className='Text Text--modalTitle Text--alignCenter'>
            Reset Your Password
          </div>
        </div>

        <Form.Message for='unknownUser'>
          {errors => <ErrorMessage>{errors}</ErrorMessage>}
        </Form.Message>

        <div className='Form-row'>
          <label className='Label' htmlFor='password'>New Password</label>
          <Form.Field
            id='password'
            type='password'
            errorClass='isInvalid'
            className='TextField TextField--defaultFont'
            name='password'
            ref={passwordRef}
            aria-describedby='password-error-message'
            aria-invalid={'password' in userStore.formErrors || null}
            autoFocus
          />
          <Form.Message className='ErrorText' for='password' id='password-error-message'/>
        </div>

        <div className='Form-row Form-row--largeSpace'>
          <label className='Label' htmlFor='passwordConfirmation'>Password Confirmation</label>
          <Form.Field
            id='passwordConfirmation'
            type='password'
            errorClass='isInvalid'
            className='TextField TextField--defaultFont'
            name='passwordConfirmation'
            ref={passwordConfirmationRef}
            aria-describedby='password-confirm-error-message'
            aria-invalid={'passwordConfirmation' in userStore.formErrors || null}
          />
          <Form.Message className='ErrorText' for='passwordConfirmation' id='password-confirm-error-message'/>
        </div>

        <div className='Form-row Form-row--mediumSpace'>
          <Form.Submit type='submit' className='Button Button--primary Button--withoutBorder'>
            Submit
          </Form.Submit>
        </div>

        <div className='Form-row Form-row--centredText'>
          <button onClick={uiStateStore.closeModal} className='Button Button--link'>
            Cancel
          </button>
        </div>
      </div>
    </Form>
  )
}

export default observer(PasswordEdit)
