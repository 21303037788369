import { action, observable, makeObservable } from 'mobx'
import { isEmptyObject } from '../../../lib/utils/common'

class PricePeriodStore {
  @observable defaultPeriod = ''
  @observable currentPrice = ''
  @observable currentPeriod = ''
  @observable selectedPeriod = {}
  @observable currentPeriodYear = null
  @observable selectedPeriodYear = null
  @observable isAvailableIcon = false
  @observable isDefaultTextButton = true
  @observable statusInfo = {}

  constructor() {
    makeObservable(this)
  }

  @action('[PricePeriodStore] Set Initial Data')
  setInitialData = ({ currentPrice, currentPeriod, statusInfo, periodYears, year, prices, isAvailableIcon }) => {
    this.currentPrice = currentPrice
    this.currentPeriod = this.defaultPeriod = currentPeriod
    this.pricePeriodYears = periodYears
    this.currentPeriodYear = this.selectedPeriodYear = parseInt(year, 10)
    this.prices = prices
    this.statusInfo = statusInfo
    this.isAvailableIcon = isAvailableIcon
  }

  @action('[PricePeriodStore] set current price period')
  setSelectedPeriodYear = v => {
    this.selectedPeriodYear = v
  }

  @action('[PricePeriodStore] Set selected period')
  setSelectedPeriod = v => {
    this.selectedPeriod = v
  }

  @action('[PricePeriodStore] Set current period info')
  setCurrentPeriodInfo = () => {
    if (!isEmptyObject(this.selectedPeriod)) {
      this.currentPrice = this.selectedPeriod.price
      this.currentPeriod = this.selectedPeriod.name
      this.isDefaultTextButton = false
      this.currentPeriodYear = this.selectedPeriodYear
    }
  }

  @action('[PricePeriodStore] Update Info')
  updateInfo = ({ statusInfo }) => {
    this.statusInfo = statusInfo
  }
}

export default PricePeriodStore
