import React from 'react'
import PropTypes from 'prop-types'
import { childrenPropType } from '../../../lib/propTypes'

const TabPanel = ({ id, labelledBy, children }) => (
  <div id={id} aria-labelledby={labelledBy} role='tabpanel'>
    {children}
  </div>
)

TabPanel.propTypes = {
  id: PropTypes.string.isRequired,
  labelledBy: PropTypes.string.isRequired,
  children: childrenPropType
}

export default TabPanel
