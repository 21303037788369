import React, { useState, useRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import * as R from 'ramda'
import { cond, renderComponent, renderNothing } from '../../Hocs'
import { CONTACT_FORM_STATE } from '../../../lib/utils/stores'
import Button from './Button'
import ContactOwnerForm from './ContactOwnerForm'
import StateFulCreateUser from '../CreateUserForm'

const Content = R.compose(
  observer,
  cond(
    ({ store }) => store.uiStateStore.contactFormState,
    {
      [CONTACT_FORM_STATE.inactive]: renderNothing,
      [CONTACT_FORM_STATE.makeContact]: renderComponent(ContactOwnerForm),
      [CONTACT_FORM_STATE.createUser]: renderComponent(StateFulCreateUser)
    }
  ))()

const ContactOwner = props => {
  const { store: { uiStateStore } } = props
  const [focusableElements, setFocusableElements] = useState([])
  const buttonRef = useRef(null)

  const setFocusToButton = () => {
    global.requestAnimationFrame(() => {
      if (buttonRef.current) {
        buttonRef.current.setFocus()
      }
    })
  }

  const setContactFormState = value => {
    uiStateStore.setContactFormState(value)
  }

  return (
    <Fragment>
      <Button
        setContactFormState={uiStateStore.setContactFormState}
        buttonRef={buttonRef}
      />
      {
        <Content
          setContactFormState={setContactFormState}
          focusableElements={focusableElements}
          setFocusableElements={setFocusableElements}
          setFocusToButton={setFocusToButton}
          buttonRef={buttonRef}
          {...props}
        />
      }
    </Fragment>
  )
}

ContactOwner.propTypes = {
  store: PropTypes.shape({
    uiStateStore: PropTypes.shape({
      caller: PropTypes.string,
      setContactFormState: PropTypes.func.isRequired
    }).isRequired
  }).isRequired
}

export default observer(ContactOwner)
