import React from 'react'
import { Provider } from 'mobx-react'
import rootStoreInstance from '../stores/rootStoreInstance'
import PricePeriod from './PricePeriod'
import { withErrorBoundary } from '../../Shared/ErrorBoundary'

const StateFulPricePeriod = props => {
  rootStoreInstance.setInitialDataPricePeriod(props)

  return (
    <Provider store={rootStoreInstance}>
      <PricePeriod/>
    </Provider>
  )
}

export default withErrorBoundary(StateFulPricePeriod)
