import React from 'react'
import { observer } from 'mobx-react'
import { isEmpty } from '../../../lib/utils/collection'
import { useStores } from '../../../shared_components/Hooks'
import ListingDetailsAgent from './ListingDetailsAgent'

const ListingDetails = () => {
  const { store: { hdpStore } } = useStores()

  return (
    <div className='ListingDetails'>
      <div className='ListingDetails-content'>
        {isEmpty(hdpStore.agents) || <div className='ListingDetails-agents'>
          <div className='ListingDetails-title'>
            Agent
          </div>
          {hdpStore.agents.map(agent => (
            <ListingDetailsAgent
              key={agent.id}
              agent={agent}
              professionalMode={hdpStore.policies.professionalMode}
              sendAnalytics={hdpStore.sendShowAgentNumberAnalytics}
            />
          ))}
        </div>}
        <div className='ListingDetails-identifier'>
          <div className='ListingDetails-hash'>
            IN #
          </div>
          <div className='ListingDetails-sourceId'>
            {hdpStore.sourceId}
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(ListingDetails)
