import { compose, withHandlers, withProps } from 'recompose'
import { inject, observer } from 'mobx-react'
import CreateUserForm from '../CreateUserForm'
import {invokeAction} from '../../../../lib/globalActions'

const enhance = compose(
  inject('store'),
  withProps(({ store }) => ({
    userStore: store.userStore
  })),
  withHandlers({
    onFormChange: ({ userStore }) => (value, [name]) => {
      userStore.setField(name, value[name])
    },
    onSignIn: ({deactivateContactForm}) => () => {
      deactivateContactForm()
      invokeAction('AuthModal', 'openModal')
    }
  }),
  observer
)

export default enhance(CreateUserForm)
