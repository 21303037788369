import React from 'react'

const PolicyInfo = () => (
  <div id='terms-info' className='Text Text--a1 Text--modalBottom Text--lineHeight16px'>
    By sending, you agree to Out East’s
    {' '}
    <a
      href='/terms'
      target='_blank'
      className='Link Link--inline Link--simple'
    >
      Terms & Conditions
    </a>
    {' '}
    and
    {' '}
    <a
      href='/terms'
      target='_blank'
      className='Link Link--inline Link--simple'
    >
      Privacy Policy
    </a>
  </div>
)

export default PolicyInfo
