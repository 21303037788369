import React from 'react'
import SearchBarDesktop from './SearchBarDesktop'
import SearchBarMobile from './SearchBarMobile'
import Viewport from '../../Shared/Viewport'

const SearchBar = () => (
  <div>
    <Viewport only='desktop'>
      <SearchBarDesktop />
    </Viewport>
    <Viewport except='desktop'>
      <SearchBarMobile />
    </Viewport>
  </div>
)

export default SearchBar
