/* eslint camelcase: 0 */
/* eslint no-undefined: 0 */

import { observable, action, observe, makeObservable } from 'mobx'
import { CONTACT_FORM_STATE, CONTACT_FORM_TYPE } from '../../../lib/utils/stores'

class UIStateStore {
  @observable isShareListingModalOpen = false
  @observable isShownOpenListings = false
  @observable isPricePeriodModalOpen = false
  @observable contactType = CONTACT_FORM_TYPE.agent
  @observable isZillow3DTourModalOpen = false
  @observable isVideoTourModalOpen = false
  @observable isSubmitDisable = false
  @observable contactFormState = CONTACT_FORM_STATE.inactive

  constructor({ gaEvents, sessionStorage }) {
    makeObservable(this)

    this.gaEvents = gaEvents
    this.sessionStorage = sessionStorage

    this.setObservers()
  }

  @action('[withContactForm] Reset')
  reset = () => {
    this.contactFormState = CONTACT_FORM_STATE.inactive
  }

  @action('[withContactForm] set contactFormState')
  setContactFormState = v => {
    this.contactFormState = v
  }

  @action('[UIStateStore] Toggle isShownOpenListings')
  toggleShownOpenListings = () => {
    this.isShownOpenListings = !this.isShownOpenListings
  }

  @action('[UIStateStore] Toggle isShareListingModalOpen')
  toggleShareListingModal = v => {
    this.isShareListingModalOpen = v
  }

  @action('[UIStateStore] Toggle isPricePeriodModalOpen')
  togglePricePeriodModal = v => {
    this.isPricePeriodModalOpen = v
  }

  @action('[UIStateStore] Set contact type')
  setContactType = v => {
    this.contactType = v
  }

  @action('[UIStateStore] Toggle isZillow3DTourModalOpen')
  toggleZillow3DTourModal = v => {
    this.isZillow3DTourModalOpen = v
  }

  @action('[UIStateStore] Toggle isVideoTourModalOpen')
  toggleVideoTourModal = v => {
    this.isVideoTourModalOpen = v
  }

  @action('[UIStateStore] Toggle isSubmitDisable')
  toggleSubmitDisable = v => {
    this.isSubmitDisable = v
  }

  setObservers() {
    observe(this, 'contactFormState', ({ newValue }) => {
      if (newValue === CONTACT_FORM_STATE.makeContact) {
        this.gaEvents.Hdp.contactFormOpen()
      }
    })
  }
}

export default UIStateStore

