import React from 'react'
import PropTypes from 'prop-types'
import DropDown from '../Shared/Dropdown'

const AgentPageNavDropDown = ({
  linkItems,
  title
}) => {
  const options = linkItems.map(({ url, label }) => {
    return (
      <li className='SimpleList-item' key={label}>
        <a href={url} className='Link--subTitle'>{label}</a>
      </li>
    )
  })

  return (
    <DropDown
      className='Dropdown--appearanceSelect'
      title={title}
    >
      <ul className = 'SimpleList SimpleList--dropdown'>
        {options}
      </ul>
    </DropDown>
  )
}

AgentPageNavDropDown.propTypes = {
  linkItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string
  })).isRequired,
  title: PropTypes.string.isRequired
}

export default AgentPageNavDropDown
