/* eslint camelcase: 0 */
/* eslint no-undefined: 0 */

import { observable, computed, action, makeObservable } from 'mobx'
import { lens } from 'lorgnette'
import { isPresent, isEmpty, fromServerToClient } from '../../../../lib/utils/collection'
import {
  SRP_SEARCH_CRITERIA,
  SRP_SEARCH_VALUES_SCOPE,
  SRP_SEARCH_INFO,
  SRP_SEARCH_INFO_URL
} from '../../../../lib/constants/search'
import { SEARCH_HISTORY_URL } from '../../../../lib/constants'

const getLink = ({ index, pageIndex, searchInfo }) => (
  lens
    .prop('items')
    .prop(pageIndex)
    .at(index)
    .prop('link')
    .get(searchInfo)
    .getOr(null)
)

class ListingsNavigationStore {
  @observable isActive = false
  @observable searchInfo

  fullAddress = null
  searchValuesScope = null
  searchCriteria = null
  searchInfoUrl = null
  searchResultsUrl = null

  constructor({ transport, sessionStorage, location, gaEvents }) {
    makeObservable(this)

    this.transport = transport
    this.sessionStorage = sessionStorage
    this.location = location
    this.gaEvents = gaEvents
  }

  setInitialData({ fullAddress }) {
    this.searchInfo = this.sessionStorage.fetchFromSessionStorage(SRP_SEARCH_INFO)
    this.searchValuesScope = this.sessionStorage.fetchFromSessionStorage(SRP_SEARCH_VALUES_SCOPE)
    this.searchCriteria = this.sessionStorage.fetchFromSessionStorage(SRP_SEARCH_CRITERIA)
    this.searchInfoUrl = this.sessionStorage.fetchFromSessionStorage(SRP_SEARCH_INFO_URL)
    this.searchResultsUrl = this.sessionStorage.fetchFromSessionStorage(SEARCH_HISTORY_URL)

    this.fullAddress = fullAddress

    this.isActive = this.searchResultsUrl
      && isPresent(this.listingIndex)
      && this.listingIndex !== -1

    if (this.shouldLoadMore) {
      this.loadMore()
    }
  }

  gaEventCallback = url => () => { this.location.redirect(url) }

  visitNeighborListing = ({ direction, url }) => {
    if (this.isListingIndexLast
        && direction === 'next'
        && this.currentPage !== this.searchInfo.totalPages) {
      const searchInfo = {
        ...this.searchInfo,
        currentPage: this.currentPage + 1
      }

      this.sessionStorage.saveToSessionStorage(SRP_SEARCH_INFO, searchInfo)
    } else if (this.isListingIndexFirst
        && direction === 'prev'
        && this.currentPage !== 1) {
      const searchInfo = {
        ...this.searchInfo,
        currentPage: this.currentPage - 1
      }

      this.sessionStorage.saveToSessionStorage(SRP_SEARCH_INFO, searchInfo)
    }

    this.gaEvents.Hdp.listingsNavigation({ eventLabel: direction, callback: this.gaEventCallback(url) })
  }

  @computed
  get totalCount() {
    return this.searchInfo && this.searchInfo.totalCount
  }

  @computed
  get currentPage() {
    return this.searchInfo && this.searchInfo.currentPage
  }

  @computed
  get backToSearchResultsUrl() {
    const searchUrl = new URL(this.searchResultsUrl)
    const pageFromUrl = Number(searchUrl.searchParams.get('page') || 1)

    if (this.currentPage !== pageFromUrl) {
      searchUrl.searchParams.set('page', this.currentPage)
    }

    return searchUrl.toString()
  }

  @computed
  get prevListingUrl() {
    const [index, pageIndex] = this.isListingIndexFirst
      ? [-1, this.currentPage - 1]
      : [this.listingIndex - 1, this.currentPage]

    return getLink({ index, pageIndex, searchInfo: this.searchInfo })
  }

  @computed
  get nextListingUrl() {
    const [index, pageIndex] = this.isListingIndexLast
      ? [0, this.currentPage + 1]
      : [this.listingIndex + 1, this.currentPage]

    return getLink({ index, pageIndex, searchInfo: this.searchInfo })
  }

  @computed
  get shouldLoadMore() {
    return (
      (this.isActive
       && this.isListingIndexLast
       && this.currentPage !== this.searchInfo.totalPages
       && isEmpty(this.searchInfo.items[this.currentPage + 1]))
        || (this.isListingIndexFirst
            && this.currentPage !== 1
            && isEmpty(this.searchInfo.items[this.currentPage - 1]))
    )
  }

  @computed
  get listingIndex() {
    return this.searchInfo && this.searchInfo
      .items[this.currentPage]
      .findIndex(({ fullAddress }) => fullAddress === this.fullAddress)
  }

  @computed
  get isListingIndexLast() {
    return this.listingIndex === this.searchInfo.items[this.currentPage].length - 1
  }

  @computed
  get isListingIndexFirst() {
    return this.listingIndex === 0
  }

  @computed
  get displayIndex() {
    return (this.currentPage - 1) * this.searchInfo.perPage + this.listingIndex + 1
  }

  @action('[ListingsNavigationStore] update search info')
  updateSearchInfo(data) {
    this.searchInfo = lens
      .prop('items')
      .prop(data.currentPage)
      .set(this.searchInfo, data.items)

    this.sessionStorage.saveToSessionStorage(SRP_SEARCH_INFO, this.searchInfo)
  }

  loadMore() {
    const page = this.currentPage + (this.isListingIndexFirst ? -1 : 1)

    return this.transport.Search.getSearchInfo(
      this.searchInfoUrl, {
        page,
        [this.searchValuesScope]: this.searchCriteria
      }
    ).then(data => {
      if (!data.error) {
        this.updateSearchInfo(fromServerToClient(data))
      }
    })
  }
}

export default ListingsNavigationStore
