import React from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../../shared_components/Hooks'
import { actsAsForceOpenClick, actsAsLinkMiddleClick } from '../../../lib/utils/dom'
import Viewport from '../../Shared/Viewport'
import { AllHtmlEntities } from 'html-entities'

const TownGuide = () => {
  const { store: townGuide } = useStores()

  return (
    townGuide.isTownGuidePresent
      ? <div className='Hdp-section'>
        <h2 className='Heading Heading--section'> About the area</h2>
        <div className='Hdp-box'>
          <div className='TownGuide'>
            <Viewport only='desktop'>
              <div
                className='TownGuide-imageContainer'
                onClick={actsAsForceOpenClick(townGuide.link)}
                onMouseDown={actsAsLinkMiddleClick(townGuide.link)}
              >
                <div className='TownsCard'>
                  <div className='TownsCard-imageContainer'>
                    <div className='TownsCard-aspectRatioContent'>
                      <img className='TownsCard-image' src={townGuide.imageUrl} alt=''/>
                    </div>
                    <div className='TownsCard-imageOverlay'/>
                  </div>
                  <div className='TownsCard-content'>
                    <a className='TownsCard-contentLink' href={townGuide.link} target='_blank'>{townGuide.townName}</a>
                  </div>
                </div>
              </div>
            </Viewport>
            <div className='TownGuide-content'>
              <div className='TownGuide-title'>
                <h3 className='Heading Heading--small'>
                  {AllHtmlEntities.decode(townGuide.title)}
                </h3>
              </div>
              <div className='TownGuide-description'>
                <div className='Text Text--a1 Text--lineHeight21px'>
                  {AllHtmlEntities.decode(townGuide.description)}
                </div>
              </div>
              <div className='TownGuide-link'>
                <a
                  href={townGuide.link}
                  className='Link Link--accessory Link--iconPostfix Link--iconArrowRight'
                  target='_blank'
                >
                  READ THE GUIDE
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      : null
  )
}

export default observer(TownGuide)
