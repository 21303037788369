import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import cn from 'classnames'

const pointerEventsStart = ['touchstart', 'mousedown']
const pointerEventsStop = ['touchend', 'mouseup']

const DrawSearchActionBar = ({
  header,
  mapStore,
  onSubmitSearch,
  removeSearchPolygon,
  enableDrawingMode,
  disableDrawingMode
}) => {
  const containerRef = useRef(null)

  const trapEvent = e => {
    e.stopPropagation()
  }

  useEffect(() => {
    const instance = containerRef.current
    const events = [...pointerEventsStart, ...pointerEventsStop]
    events.forEach(event => {
      instance.addEventListener(event, trapEvent)
    })

    return () => {
      events.forEach(event => {
        instance.removeEventListener(event, trapEvent)
      })
    }
  }, [])

  return (
    <div ref={containerRef} className={cn('DrawSearchActionBar', { isDrawingMode: mapStore.isDrawingUIMode })}>
      <div className='DrawSearchActionBar-content'>
        {mapStore.isDrawingUIMode && (
          <p className='DrawSearchActionBar-text'>
            <span className='exclude-mobile-viewport'>Draw a shape around the region you would like to search in.</span>
            <span className='mobile-viewport'>Draw a shape.</span>
          </p>
        )}

        <div className='DrawSearchActionBar-actions'>
          {(mapStore.isDrawingUIMode || !mapStore.hasPolygon) && (
            <div className='DrawSearchActionBar-action'>
              <button type='button' className='DrawSearchActionBar-drawButton' onClick={onSubmitSearch}>
                <span className='exclude-mobile-viewport'>Search this area</span>
                <span className='mobile-viewport'>Search</span>
                <span className='sr-only'>
                  Accessible search results can be found after the {header} header.
                </span>
              </button>
            </div>
          )}

          {!mapStore.isDrawingUIMode && (
            <div className='DrawSearchActionBar-action'>
              {mapStore.hasPolygon
                ? (
                  <button type='button' className='DrawSearchActionBar-drawButton' onClick={removeSearchPolygon}>
                    Remove Boundary
                  </button>)
                : (
                  <button type='button' className='DrawSearchActionBar-drawButton' onClick={enableDrawingMode}>
                    Draw
                  </button>)
              }
            </div>
          )}

          {mapStore.isDrawingUIMode && (
            <div className='DrawSearchActionBar-action'>
              <button type='button' onClick={disableDrawingMode} className='DrawSearchActionBar-drawButton'>
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

DrawSearchActionBar.propTypes = {
  header: PropTypes.string,
  mapStore: PropTypes.shape({
    isDrawingUIMode: PropTypes.bool,
    hasPolygon: PropTypes.bool
  }),
  onSubmitSearch: PropTypes.func.isRequired,
  removeSearchPolygon: PropTypes.func.isRequired,
  enableDrawingMode: PropTypes.func.isRequired,
  disableDrawingMode: PropTypes.func.isRequired
}

export default observer(DrawSearchActionBar)
