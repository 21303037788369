import React, { useEffect } from 'react'
import { Provider } from 'mobx-react'
import { setDisplayName } from '../../Hocs'
import rootStoreInstance from '../stores/rootStoreInstance'
import Take3DTourButton from './Button'
import ErrorBoundary from '../../Shared/ErrorBoundary'

const StateFullTake3DTourButton = props => {
  useEffect(() => {
    rootStoreInstance.setInitialData3DTourButton(props)

    const resetModal = () => rootStoreInstance.uiStateStore.toggleZillow3DTourModal(false)
    document.addEventListener('turbolinks:before-cache', resetModal)

    return () => {
      document.removeEventListener('turbolinks:before-cache', resetModal)
    }
  }, [props])

  return (
    <ErrorBoundary>
      <Provider store={rootStoreInstance}>
        <Take3DTourButton/>
      </Provider>
    </ErrorBoundary>
  )
}

export default setDisplayName('StateFullTake3DTourButton')(StateFullTake3DTourButton)
