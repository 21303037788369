import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'mobx-react'
import ErrorBoundary from '../../Shared/ErrorBoundary'
import { RootStore } from './stores'
import { transport, location } from '../../../lib/services'
import CompactSearchBar from './CompactSearchBar'

const rootStore = new RootStore({ transport, location })

const StatefulCompactSearchBar = (props) => {
  rootStore.setInitialData(props.currentListingType, props)
  rootStore.setInitialProps(props)

  return (
    <ErrorBoundary>
      <Provider store={rootStore}>
        <CompactSearchBar/>
      </Provider>
    </ErrorBoundary>
  )
}

StatefulCompactSearchBar.propTypes = {
  currentListingType: PropTypes.string
}

export default StatefulCompactSearchBar
