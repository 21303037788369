import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes, observer } from 'mobx-react'
import { GoogleMap } from '@react-google-maps/api'
import { childrenPropType } from '../../../../lib/propTypes'
import { useGoogleMap } from '../../../../shared_components/Hooks/useGoogleMap'
import { defaultZoom, commonGoogleMapOptions, DEFAULT_CENTER } from '../../../../shared_components/Map/constants'
import mapStyles from '../../../../shared_components/Map/mapStyles'
import useMarkersBounds from '../hooks/useMarkersBounds'

const containerStyle = { height: '100%', position: 'relative', overflow: 'hidden' }

const Map = ({
  markers,
  mapBounds,
  onBoundsChanged,
  onZoomChanged,
  googleMapOptions = {},
  children
}) => {
  const { map, isLoaded, onMapLoad, onMapUnmount } = useGoogleMap()

  const rotateControlOptions = global.google && global.google.maps.ControlPosition.RIGHT_BOTTOM

  const handleBoundsChanged = useCallback(() => {
    if (map && onBoundsChanged) {
      onBoundsChanged(map.getBounds())
    }
  }, [map])

  const handleZoomChanged = useCallback(() => {
    if (map) {
      onZoomChanged(map.getZoom())
    }
  }, [map])

  useMarkersBounds({ map, mapBounds, markers })

  return (
    isLoaded && (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={DEFAULT_CENTER}
        tilt={0}
        zoom={defaultZoom}
        onLoad={onMapLoad}
        onUnmount={onMapUnmount}
        onBoundsChanged={handleBoundsChanged}
        onZoomChanged={handleZoomChanged}
        panTo={DEFAULT_CENTER}
        options={{
          ...commonGoogleMapOptions,
          streetViewControl: false,
          styles: mapStyles,
          ...googleMapOptions,
          rotateControlOptions: { position: rotateControlOptions }
        }}
      >
        {children && children}
      </GoogleMap>
    )
  )
}

Map.propTypes = {
  markers: MobxPropTypes.arrayOrObservableArray,
  mapBounds: MobxPropTypes.objectOrObservableObject,
  onBoundsChanged: PropTypes.func,
  onZoomChanged: PropTypes.func.isRequired,
  googleMapOptions: PropTypes.object,
  children: childrenPropType
}
export default observer(Map)
