import React, { Fragment, useCallback } from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../../shared_components/Hooks'
import VideoTourModal from '../../../shared_components/VideoTourModal'

const Button = () => {
  const { store: { uiStateStore, hdpStore } } = useStores()

  const handleClick = useCallback(() => {
    uiStateStore.toggleVideoTourModal(true)
  })

  const deactivateModal = useCallback(() => {
    uiStateStore.toggleVideoTourModal(false)
  })

  return (
    hdpStore.videoTour && (
      <Fragment>
        <button className='Button Button--secondary' onClick={handleClick}>TAKE VIDEO TOUR</button>
        <VideoTourModal
          videoTour={hdpStore.videoTour}
          deactivateModal={deactivateModal}
          isVideoTourModalOpen={uiStateStore.isVideoTourModalOpen}
        />
      </Fragment>
    )
  )
}

export default observer(Button)
