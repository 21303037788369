import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import cn from 'classnames'
import { PriceArrowDownUrl, PriceArrowUpUrl } from '../../Svg'

const PriceHistoryTable = ({ collection, isContentHidden }) => (
  <table className='Table'>
    <thead>
      <tr>
        <th className='Table-heading'>
          <span className='Table-headingText'>Date</span>
        </th>
        <th className='Table-heading'>
          <span className='Table-headingText'>Event</span>
        </th>
        <th className='Table-heading'>
          <span className='Table-headingText Table-headingText--withoutBorder'>Price</span>
        </th>
      </tr>
    </thead>
    <tbody>
      {collection.map(item => {
        const isHidden = item.isHidden && isContentHidden
        const alt = `Price ${item.arrowDirection === 'up' ? 'increased' : 'reduced'}`

        return (
          <tr id={item.id} key={item.id} className={cn({ isHidden: isHidden })}>
            <th className='Table-field'>
              <span className='Table-fieldText'>{item.date}</span>
            </th>
            <td className='Table-field'>
              <span className='Table-fieldText'>{item.eventLabel}</span>
            </td>
            <td className='Table-field'>
              <span className='Table-fieldText'>{item.price}</span>
              {item.arrowDirection &&
                <span>
                  <img
                    alt={alt}
                    src={item.arrowDirection === 'up' ? PriceArrowUpUrl : PriceArrowDownUrl}
                    className='Table-arrowIcon'
                  />
                </span>
              }
            </td>
          </tr>
        )
      })}
    </tbody>
  </table>
)

PriceHistoryTable.propTypes = {
  collection: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({
    arrowDirection: PropTypes.string,
    date: PropTypes.string.isRequired,
    eventLabel: PropTypes.string.isRequired,
    price: PropTypes.string
  })),
  isContentHidden: PropTypes.bool
}

export default PriceHistoryTable
