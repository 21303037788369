import React, { Fragment, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Form from 'react-formal'
import { observer, PropTypes as MobxPropTypes } from 'mobx-react'
import { isPresent } from '../../../lib/utils/collection'
import { CalendarFull as CalendarSvg, CicleX as Close } from '../../../shared_components/Svg'
import Modal from '../Modal'
import DateRangePicker from './DateRangePicker'
import DateRangePickerFooter from './DateRangePickerFooter'

const DateRangeFormField = ({
  contactFormStore,
  labelId = 'select-rental-period-label'
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const datesInputButtonRef = useRef(null)

  const openDatePicker = () => {
    setIsOpen(true)
  }

  const closeDatePicker = () => {
    contactFormStore.resetDatesRange()
    setIsOpen(false)
  }

  const onChange = dates => {
    const [start, end] = dates

    contactFormStore.setDatesRange({ ...contactFormStore.datesRange, start, end })
    contactFormStore.validateAt('datesRange')

    if (isPresent(contactFormStore.formErrors)) {
      contactFormStore.setDatesRange({ ...contactFormStore.datesRange, start, end: null })
    }
  }

  const selectOffset = e => {
    const value = Number(e.target.value)
    const label = e.target.dataset.label

    contactFormStore.setDatesRange({ ...contactFormStore.datesRange, offset: { value, label } })
  }

  const applyDateRange = () => {
    contactFormStore.validateAt('datesRange')
    if (!contactFormStore.hasErrors) { setIsOpen(false) }
  }

  const mapToValue = ({ datesRange: { start, end, offset } }) => {
    if (start && end) {
      return `${moment(start).format('L')} - ${moment(end).format('L')} (${offset.label} )`
    }

    return ''
  }

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeDatePicker}
        ariaHideApp={false}
        portalClassName='ReactModalPortal'
        aria={{ labelledby: labelId }}
      >
        <DateRangePicker
          className='DateRangePicker--contactForm'
          value={contactFormStore.datesRange}
          onChange={onChange}
          datesInputButtonRef={datesInputButtonRef}
          title='Select Rental Period'
          labelId={labelId}
        >
          <Form.Message className='ErrorText' for={['datesRange.start', 'datesRange.end']}/>
          <DateRangePickerFooter
            applyDateRange={applyDateRange}
            datesRange={contactFormStore.datesRange}
            selectOffset={selectOffset}
          />
        </DateRangePicker>
      </Modal>

      <label className='Label' htmlFor='datesRange'>Select Dates (optional)</label>
      <div className='TextField-wrapper'>
        <Form.Field
          type='text'
          name='datesRange'
          id='datesRange'
          readOnly
          errorClass='isInvalid'
          className='TextField'
          onClick={openDatePicker}
          tabIndex='-1'
          mapToValue={mapToValue}
        />
        <button
          type='button'
          className='TextField-button'
          onClick={contactFormStore.hasDatesRange ? contactFormStore.resetDatesRange : openDatePicker}
          ref={datesInputButtonRef}
        >
          {
            contactFormStore.hasDatesRange ? (
              <Fragment>
                <Close className='TextField-icon'/>
                <span className='sr-only'>Clear selected dates</span>
              </Fragment>
            ) : (
              <Fragment>
                <CalendarSvg className='TextField-icon'/>
                <span className='sr-only'>Open date picker</span>
              </Fragment>
            )}
        </button>
      </div>

      <Form.Message className='ErrorText' for={['datesRange.start', 'datesRange.end']}/>
    </Fragment>
  )
}

DateRangeFormField.propTypes = {
  labelId: PropTypes.string,
  contactFormStore: PropTypes.shape({
    validateAt: PropTypes.func.isRequired,
    hasDatesRange: PropTypes.bool,
    setDatesRange: PropTypes.func.isRequired,
    resetDatesRange: PropTypes.func.isRequired,
    formErrors: MobxPropTypes.objectOrObservableObject.isRequired,
    hasErrors: PropTypes.bool,
    datesRange: PropTypes.shape({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      offset: PropTypes.shape({
        value: PropTypes.number,
        text: PropTypes.string
      })
    })
  }).isRequired
}

export default observer(DateRangeFormField)
