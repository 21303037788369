/* eslint react/no-unknown-property: 0 */
/* eslint no-shadow: 0 */

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Form from 'react-formal'
import cn from 'classnames'
import * as R from 'ramda'
import routes from '../../../lib/config/routes'
import { noop } from '../../../lib/utils/common'
import { signInUserSchema } from '../../../schemas/user'
import { Page } from '../stores/UIStateStore'
import { Captcha, ErrorMessage, InfoMessage } from '../../Shared/Form'
import { useStores } from '../../../shared_components/Hooks'
import useCaptchaHandlers from '../../Shared/Form/Captcha/useCaptchaHandlers'
import { onFormChange } from './handlers'

const SignIn = ({ tabs }) => {
  const { store: { userStore, uiStateStore, signInCaptchaStore } } = useStores()
  const { captchaRef, resetCaptcha } = useCaptchaHandlers()
  const emailFieldRef = useRef(null)
  const passwordFieldRef = useRef(null)

  useEffect(() => {
    signInCaptchaStore.checkCaptchaActive()
  }, [])

  const tabConfig = R.find(R.propEq('page', Page.signin))(tabs)

  const handleFormChange = onFormChange({ userStore })

  const onFormSubmit = () => {
    userStore
      .handleLogin({ captcha: signInCaptchaStore.toForm })
      .then(() => {
        signInCaptchaStore.checkCaptchaActive()
      })
      .then(() => {
        signInCaptchaStore.resetRecaptchaResponse()
        resetCaptcha()
      })
      .then(() => {
        if (userStore.formErrors.unknownUser) {
          emailFieldRef.current.focus()
        }
      })
  }

  const setFocus = errors => {
    if (errors.email) {
      emailFieldRef.current.focus()
    } else if (errors.password) {
      passwordFieldRef.current.focus()
    }
  }

  const onForgotPassword = () => {
    uiStateStore.updateCurrentView(Page.forgot)
  }

  return (
    <div role='tabpanel' id={tabConfig.panelId} aria-labelledby={tabConfig.tabId}>
      <Form className='Form Form--authentication'
        onSubmit={onFormSubmit}
        schema={signInUserSchema}
        value={userStore.toForm}
        onChange={handleFormChange}
        errors={userStore.formErrors}
        onError={noop}
        onInvalidSubmit={errors => {
          userStore.setErrors(errors)
          setFocus(errors)
        }}
      >
        <div className='Form-wrap Form-wrap--extraSpace'>
          <Form.Message for='unknownUser'>
            {errors => <ErrorMessage id='errorMessage'>{errors}</ErrorMessage>}
          </Form.Message>

          <InfoMessage isActive={!userStore.formErrors.unknownUser}>
            Sign in to your Out East account to stay up to date on your searches,
            save your favorite listings, and more.
          </InfoMessage>

          <div className='Form-row'>
            <label className='Label' htmlFor='email'>Email</label>
            <Form.Field
              id='email'
              type='email'
              name='email'
              errorClass='isInvalid'
              className='TextField'
              placeholder='your@email'
              ref={emailFieldRef}
              aria-describedby='errorMessage signInEmailError'
              aria-invalid={'email' in userStore.formErrors || userStore.formErrors.unknownUser || null}
              aria-required='true'
              autoComplete='email'
            />
            <Form.Message id='signInEmailError' className='ErrorText' for='email'/>
          </div>

          <div className='Form-row Form-row--smallSpace'>
            <label className='Label' htmlFor='password'>Password</label>
            <Form.Field
              id='password'
              type='password'
              name='password'
              errorClass='isInvalid'
              className='TextField TextField--defaultFont'
              placeholder='your password'
              ref={passwordFieldRef}
              aria-describedby='errorMessage signInPasswordError'
              aria-invalid={'password' in userStore.formErrors || userStore.formErrors.unknownUser || null}
              aria-required='true'
              autoComplete='password'
            />
            <Form.Message id='signInPasswordError' className='ErrorText' for='password'/>
          </div>

          <div className='Form-row Form-row--smallSpace'>
            <button type='button' className='Button Button--formHelper' onClick={onForgotPassword}>
              Forgot Password?
            </button>
          </div>

          <Captcha
            isCaptchaActive={signInCaptchaStore.isCaptchaActive}
            onChange={signInCaptchaStore.updateRecaptchaResponse}
            setCaptchaRef={captchaRef}
          />

          <Form.Submit
            type='submit'
            className={cn('Button Button--primary Button--withoutBorder Button--size13', {
              isDisabled: !signInCaptchaStore.isCaptchaValid
            })}
            disabled={!signInCaptchaStore.isCaptchaValid}
          >
            Sign In
          </Form.Submit>

          <div className='Form-alternativeLabel'>
            <span className='Text Text--a1 Text--lineHeight19px'>OR</span>
          </div>

          <a className='Button Button--google' data-method='post' href={routes.googleAuthPath}>
            Continue With Google
          </a>

          {uiStateStore.withAppleAuth &&
            <a className='Button Button--apple' data-method='post' href={routes.appleAuthPath}>
              Continue With Apple
            </a>}
        </div>
      </Form>
    </div>
  )
}

SignIn.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default observer(SignIn)
