import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Modal from '../../../Shared/Modal'
import { AreaMapSelector, enhanceWithMap } from '../../../Shared/Form/TownSelect'
import SearchBarFooterActions from './SearchBarFooterActions'
import { compose, setPropTypes, withProps, lifecycle } from 'recompose'
import FOCUSABLE_ELEMENT_SELECTORS from '../../../../lib/constants/focusable'

const enhance = compose(
  setPropTypes({
    setFocusableElements: PropTypes.func.isRequired
  }),
  withProps(() => ({
    contentRef: React.createRef()
  })),
  lifecycle({
    componentDidMount() {
      const contentNode = this.props.contentRef.current
      const focusableElements = contentNode.querySelectorAll(FOCUSABLE_ELEMENT_SELECTORS)
      this.props.setFocusableElements(Array.from(focusableElements))
    }
  }),
  enhanceWithMap
)

const AreaSelectorActions = ({
  children,
  cancelHandler,
  applyResultsHandler,
  countResults,
  contentRef,
  onKeyDown,
  ...props
}) => (
  <Fragment>
    {children && children}

    <div className='Multiselect-actions' role='presentation' ref={contentRef} onKeyDown={onKeyDown}>
      <div className='Multiselect-action'>
        <button type='button'
          onClick={props.openMapView}
          className='Button Button--secondary Button--withoutBorder Button--autoSized Button--height40'
        >
            View map
        </button>
      </div>

      <SearchBarFooterActions
        cancelHandler={cancelHandler}
        applyResultsHandler={applyResultsHandler}
        countResults={countResults}
      />
    </div>

    <Modal
      isOpen={props.isMapOpen}
      onRequestClose={() => {
        props.setMapOpen(false)
      }}
      ariaHideApp={false}
      portalClassName='ReactModalPortal'
    >
      <AreaMapSelector
        {...props}
        areaMapSelectorActions={() => (
          <div className='AreaMapSelector-actions'>
            <div className='AreaMapSelector-action'>
              <button type='button' className='Button Button--secondary Button--width250'
                onClick={e => {
                  e.stopPropagation()
                  cancelHandler(e)
                }}
              >
                    Cancel
              </button>
            </div>
            <div className='AreaMapSelector-action'>
              <button type='button' className='Button Button--primary Button--width250'
                onClick={applyResultsHandler}>
                    See results {countResults && `(${countResults})`}
              </button>
            </div>
          </div>
        )}
      />
    </Modal>
  </Fragment>
)

AreaSelectorActions.propTypes = {
  openMapView: PropTypes.func.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  applyResultsHandler: PropTypes.func.isRequired,
  countResults: PropTypes.number,
  isMapOpen: PropTypes.bool.isRequired,
  setMapOpen: PropTypes.func.isRequired,
  children: PropTypes.any,
  contentRef: PropTypes.object,
  onKeyDown: PropTypes.func.isRequired
}

export default enhance(AreaSelectorActions)
