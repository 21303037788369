import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import Form from 'react-formal'
import cn from 'classnames'
import { isPresent } from '../../../lib/utils/collection'
import { noop } from '../../../lib/utils/common'
import { userInfoSchema } from '../../../schemas/user'
import { useStores } from '../../../shared_components/Hooks'
import { FillCheckUrl } from '../../Svg'

const UserInfoForm = () => {
  const { store: formStore } = useStores()

  const emailRef = useRef(null)

  const handleSubmit = () => {
    formStore.submit()
  }

  const fieldWithError = fieldName => {
    return [fieldName] in formStore.formErrors
  }

  const focusOnErrorField = errors => {
    if (isPresent(errors)) {
      emailRef.current.focus()
    }
  }

  const handleChange = (value, [name]) => {
    formStore.setField(name, value[name])
  }

  const handleMapFromValue = e => {
    return formStore.getFormattedName(e.target.value)
  }

  const handleMapFromPhoneValue = e => {
    return formStore.getFormattedPhoneValue(e.target.value)
  }

  useEffect(() => {
    focusOnErrorField(formStore.formErrors)
  }, [formStore.formErrors])

  return (
    <div className='PrimaryBox'>
      <div className='PrimaryBox-title'>
        <h3 className='Text Text--a2 Text--noSpace'>
          Basic Information
        </h3>
        <div className={cn('UpdatedCheck', { isShown: formStore.isShowUpdatedCheck })}>
          <div className='UpdatedCheck-content UpdatedCheck-content--static'>
            <img src={FillCheckUrl} className='UpdatedCheck-icon' alt='' />
            <span className='Text Text--a1 Text--slateColor'>
              Updated
            </span>
          </div>
        </div>
      </div>
      <div className='PrimaryBox-content'>
        <Form
          className='Form'
          onSubmit={handleSubmit}
          schema={userInfoSchema}
          value={formStore.toForm}
          onChange={handleChange}
          errors={formStore.formErrors}
          onError={noop}
          onInvalidSubmit={errors => {
            focusOnErrorField(errors)
            formStore.setErrors(errors)
          }}
        >
          <div className='Form-row'>
            <label className='Label' htmlFor='name'>Name</label>
            <Form.Field
              id='name'
              name='name'
              type='text'
              autoComplete='name'
              aria-describedby='nameError'
              aria-invalid={fieldWithError('name') || null}
              className={cn('TextField', { isInvalid: formStore.formErrors.name })}
              mapFromValue={handleMapFromValue}
            />
            <Form.Message id='nameError' className='ErrorText' for='name'/>
          </div>
          <div className='Form-row'>
            <label className='Label' htmlFor='email'>Email</label>
            <Form.Field
              id='email'
              name='email'
              type='email'
              autoComplete='email'
              aria-describedby='emailError'
              aria-invalid={fieldWithError('email') || null}
              className={cn('TextField', { isInvalid: formStore.formErrors.email })}
              ref={emailRef}
            />
            <Form.Message id='emailError' className='ErrorText' for='email'/>
          </div>
          <div className='Form-row'>
            <label className='Label' htmlFor='phone'>Phone</label>
            <Form.Field
              id='phone'
              name='phone'
              type='tel'
              autoComplete='tel'
              aria-describedby='phoneError'
              aria-invalid={fieldWithError('phone') || null}
              className={cn('TextField', { isInvalid: formStore.formErrors.phone })}
              mapFromValue={handleMapFromPhoneValue}
            />
            <Form.Message id='phoneError' className='ErrorText' for='phone'/>
          </div>
          <div className='Form-row'>
            <Form.Submit
              type='submit'
              className='Button Button--primary Button--withoutBorder'
              data-qa-user-base-info-save
            >
              Save
            </Form.Submit>
          </div>
        </Form>
      </div>
    </div>
  )}

export default observer(UserInfoForm)
