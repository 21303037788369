import { action, runInAction, makeObservable } from 'mobx'
import {
  CONTACT_EMAIL,
  CONTACT_NAME,
  SIGNIN_EMAIL,
  PHONE_NUMBER
} from '../../../lib/services/userInfo'
import BaseContactFormStore from '../../Shared/stores/BaseContactFormStore'
import { fromServerToClient, sanitizedParams } from '../../../lib/utils/collection'

class ContactAgentFormStore extends BaseContactFormStore {
  eventCategory = 'listing'

  constructor(context) {
    super(context)

    makeObservable(this)

    this.root = context.root
  }

  @action('[ContactAgentStore] toggle listingId')
  toggleListingId = id => {
    this.listingId = id
  }

  @action('[ContactAgentStore] setPricePeriodText')
  setPricePeriodText = value => {
    this.pricePeriodText = value
  }

  @action('[ContactAgentFormStore] set agent info')
  setAgentInfo = ({email, firstName, fullName, companyName, city}) => {
    this.agentEmail = email
    this.agentName = firstName
    this.fullName = fullName
    this.companyName = companyName
    this.city = city
  }

  @action('[ContactAgentStore] submit')
  submit = (additionalData = {}) => {
    const data = {
      ...additionalData,
      contactForm: sanitizedParams({
        ...this.toForm,
        listingId: this.listingId,
        listingClass: this.listingClass,
        listorEmail: this.agentEmail,
        datesRange: this.sanitizedRange
      })
    }

    return this.transport.Listing.sendContactAgent(data)
      .then(response => {
        if (response.success) {
          const { savedListing } = fromServerToClient(response)

          this.setContactMessage({ text: 'Message has been sent', type: 'success' })

          if (savedListing && !this.root.hdpStore.isListingSaved) {
            runInAction(() => {
              this.root.hdpStore.isListingSaved = true
            })
            this.root.hdpStore.updateSaveStatus()
          }
        }

        if (response.errors) {
          this.setErrors(response.errors)
        }

        if (response.error) {
          this.setErrors({ error: response.error })
        }

        return response
      })
      .then(response => {
        this.gaEvents.Common.contactAgent(this.eventCategory)
        return response
      })
      .then(response => {
        this.userInfo
          .setInfo(CONTACT_EMAIL, this.email)
          .setInfo(SIGNIN_EMAIL, this.email)
          .setInfo(CONTACT_NAME, this.name)
          .setInfo(PHONE_NUMBER, this.phone)
        return response
      })
      .then(response => {
        if (response.success) {
          this.resetFields()
        }

        return response
      })
  }
}

export default ContactAgentFormStore
