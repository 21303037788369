import { action, makeObservable } from 'mobx'
import * as R from 'ramda'
import { CONTACT_EMAIL, CONTACT_NAME, SIGNIN_EMAIL, PHONE_NUMBER } from '../../../lib/services/userInfo'
import { sanitizedParams } from '../../../lib/utils/collection'
import { dispatchEventSendContact } from '../../Shared/stores/utils'
import BaseContactFormStore from '../../Shared/stores/BaseContactFormStore'

class ContactAgentFormStore extends BaseContactFormStore {
  eventCategory = 'agent'

  constructor(context) {
    super(context)

    makeObservable(this)
  }

  @action('[ContactAgentFormStore] submit')
  submit(additionalData = {}) {
    let request
    let data

    if (this.hasListing) {
      data = {
        ...additionalData,
        contactForm: sanitizedParams({
          ...this.toForm,
          listingId: this.listingId,
          listingClass: this.listingClass,
          listorEmail: this.agentEmail,
          datesRange: this.sanitizedRange
        })
      }
      request = this.transport.Listing.sendContactAgent
    } else {
      data = {
        ...R.omit(['datesRange'], this.toForm),
        listorEmail: this.agentEmail
      }

      request = this.transport.Agent.sendMessage
    }

    return request(data)
      .then(response => {
        if (response.success && this.hasListing) {
          dispatchEventSendContact(data)
        }

        if (response.errors) {
          this.setErrors(response.errors)
        }

        return response
      })
      .then(response => {
        this.gaEvents.Common.contactAgent(this.eventCategory)
        return response
      })
      .then(response => {
        this.userInfo
          .setInfo(CONTACT_EMAIL, this.email)
          .setInfo(SIGNIN_EMAIL, this.email)
          .setInfo(CONTACT_NAME, this.name)
          .setInfo(PHONE_NUMBER, this.phone)

        return response
      })
      .then(response => {
        if (response.success) {
          this.resetFields()
        }

        return response
      })
  }
}

export default ContactAgentFormStore
