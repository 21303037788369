import PropertyAddressFormStore from '../../../shared_components/Shared/stores/PropertyAddressFormStore'
import { getResponseErrors } from '../../../lib/utils/common'

class DraftListingStore extends PropertyAddressFormStore {
  get formData() {
    return {
      propertyId: this.selectedAddressItem.id,
      subarea: this.value.subarea
    }
  }

  submit = listingType => {
    let request

    if (listingType === 'Sale') {
      request = this.transport.ManageOwnersSale.create({
        ownersSaleForm: this.formData
      })
    } else if (listingType === 'Land') {
      request = this.transport.ManageOwnersSale.create({
        ownersSaleForm: {
          ...this.formData,
          unitType: 'land'
        }
      })
    } else if (listingType === 'Rental') {
      request = this.transport.ManageOwnersRental.create({
        ownersRentalForm: this.formData
      })
    }

    request.then(response => {
      const errors = getResponseErrors(response)

      if (!errors) {
        this.location.redirect(response.location)
      } else {
        this.setServerErrors(errors)
      }
    })
  }
}

export default DraftListingStore
