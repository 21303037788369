import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withHandlers, setPropTypes, compose } from 'recompose'
import Dropdown from '../../Shared/Dropdown'
import { pageType } from '../../../lib/propTypes'

const enhance = compose(
  setPropTypes({
    onChange: PropTypes.func.isRequired
  }),
  withHandlers(() => {
    let dropdownEl

    return {
      setDropdownRef: () => el => {
        dropdownEl = el
      },

      changeHandler: ({ onChange }) => name => {
        onChange(name)
        dropdownEl.stateUpdaters.toggleActive()
      }
    }
  })
)

const ListingTypeSelector = ({
  currentListingType,
  listingTypes,
  setDropdownRef,
  changeHandler
}) => (
  <Dropdown
    id='listing-type'
    title={
      <Fragment>
        <div className='Dropdown-label'>Listing Type</div>
        <div>{listingTypes[currentListingType].title}</div>
      </Fragment>
    }
    className='Dropdown--listingType Dropdown--searchBar'
    ref={setDropdownRef}
  >
    <ul className='SimpleList SimpleList--dropdown'>
      <li className='SimpleList-item'>
        <button className='SimpleList-button' onClick={() => changeHandler('sales')}>
          {listingTypes.sales.title}
        </button>
      </li>
      <li className='SimpleList-item'>
        <button className='SimpleList-button' onClick={() => changeHandler('rentals')}>
          {listingTypes.rentals.title}
        </button>
      </li>
      <li className='SimpleList-item'>
        <button className='SimpleList-button' onClick={() => changeHandler('lands')}>
          {listingTypes.lands.title}
        </button>
      </li>
    </ul>
  </Dropdown>
)

ListingTypeSelector.propTypes = {
  currentListingType: PropTypes.string.isRequired,
  listingTypes: PropTypes.shape({
    sales: pageType.isRequired,
    rentals: pageType.isRequired
  }).isRequired,
  changeHandler: PropTypes.func.isRequired,
  setDropdownRef: PropTypes.func.isRequired
}

export default enhance(ListingTypeSelector)
