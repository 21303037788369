import { observable, action, computed, toJS, makeObservable } from 'mobx'
import * as R from 'ramda'
import { mapSimpleSearchDataToServer } from '../../../../lib/dataMappings/search'
import { buildSelectedOptionsWithParents, buildTreeTownOptions } from '../../../../lib/dataMappings/treeUtils'
import { getId } from '../../../../lib/utils/selectors'
import {
  SALES_TYPE,
  SALES_VALUE_SCOPE,
  SALES_SEARCH_URL,
  RENTALS_VALUE_SCOPE,
  RENTALS_SEARCH_URL
} from '../../../Shared/stores/constants'

class SearchStore {
  @observable searchType = ''
  @observable selectedOptionsGroup = []
  @observable defaultValues = {}
  @observable towns = null
  @observable prices = []
  @observable beds
  @observable pricePeriod = ''
  @observable townOptions = {
    collection: [],
    additional: []
  }
  @observable year

  optionsById = {}

  constructor({ transport, location }) {
    makeObservable(this)

    this.transport = transport
    this.location = location
  }

  @action('[SearchStore] Set Initial State')
  setInitialState({ initialState }) {
    const valueStateStore = initialState[this.searchType].valueStateStore

    this.towns = this.towns || valueStateStore.values.townNames || []
    this.prices = [valueStateStore.values.priceFrom, valueStateStore.values.priceTo]
    this.beds = valueStateStore.values.beds
    this.pricePeriod = valueStateStore.values.pricePeriod || valueStateStore.defaultValues.pricePeriod
    this.year = valueStateStore.values.year || valueStateStore.defaultValues.year

    this.defaultValues = { ...valueStateStore.defaultValues, pricePeriod: valueStateStore.values.pricePeriod }
    this.townOptions = valueStateStore.towns
  }

  @computed
  get toForm() {
    return {
      towns: toJS(this.towns),
      prices: toJS(this.prices),
      beds: this.beds,
      pricePeriod: this.pricePeriod,
      year: this.year
    }
  }

  @computed
  get primarySelectedOptions() {
    return this.treeTownOptions.filter(o => (
      this.selectedOptionsGroup.includes(getId(o))
    ))
  }

  @computed
  get selectedOptionsWithParents() {
    return buildSelectedOptionsWithParents(this.treeTownOptions, this.primarySelectedOptions, this.optionsById)
  }

  @computed
  get searchUrl() {
    return this.searchType === SALES_TYPE ? SALES_SEARCH_URL : RENTALS_SEARCH_URL
  }

  @computed
  get searchScope() {
    return this.searchType === SALES_TYPE ? SALES_VALUE_SCOPE : RENTALS_VALUE_SCOPE
  }

  @action('[SearchStore] Set search type')
  setSearchType = value => {
    this.searchType = value
  }

  @action('[SearchStore] Reset field')
  resetFieldToInitialValue = name => {
    this[name] = this.defaultValues[name]
  }

  @action('[SearchStore] Set selected options group')
  setSelectedOptionsGroup = value => {
    this.selectedOptionsGroup = value
  }

  @action('[SearchStore] setField')
  setField = (name, value) => {
    if(name === 'beds') {
      this[name] = Number(value)
      return
    }

    this[name] = value
  }

  @action('[SearchStore] submit')
  submit = values => {
    const data = mapSimpleSearchDataToServer({
      values,
      defaultValues: this.defaultValues,
      scope: this.searchScope
    })

    this.transport.Search
      .submit(this.searchUrl, data)
      // eslint-disable-next-line camelcase
      .then(({ redirect_to }) => {
        this.location.redirect(redirect_to)
      })
  }

  @computed get treeTownOptions() {
    const sortedCollection = R.sortBy(R.prop('id'), this.townOptions.collection)
    const options = buildTreeTownOptions(sortedCollection)

    this.optionsById = R.indexBy(R.prop('id'), options)
    return options
  }
}

export default SearchStore
