import { useGoogleMap } from '@react-google-maps/api'

const pointerEventsStart = ['touchstart', 'mousedown']
const pointerEventsStop = ['touchend', 'mouseup']

const useFreehandDrawing = containerRef => {
  const map = useGoogleMap()
  const mapContainer = containerRef.current

  const setFreehandDrawingOptions = ({ handleStart, handleStop }) => {
    map.setOptions(
      {
        draggable: false,
        zoomControl: false,
        scrollwheel: false,
        draggableCursor: 'crosshair',
        streetViewControl: false,
        fullscreenControl: false,
        rotateControl: false
      }
    )

    pointerEventsStart.forEach(e => {
      mapContainer.addEventListener(e, handleStart)
    })

    pointerEventsStop.forEach(e => {
      mapContainer.addEventListener(e, handleStop)
    })
  }

  const resetFreehandDrawingOptions = ({ handleStart, handleStop }) => {
    map.setOptions({
      draggable: true,
      zoomControl: true,
      scrollwheel: true,
      draggableCursor: null,
      streetViewControl: false,
      fullscreenControl: false,
      rotateControl: false
    })

    pointerEventsStart.forEach(e => {
      mapContainer.removeEventListener(e, handleStart)
    })

    pointerEventsStop.forEach(e => {
      mapContainer.removeEventListener(e, handleStop)
    })
  }

  return ({
    setFreehandDrawingOptions,
    resetFreehandDrawingOptions
  })
}

export default useFreehandDrawing
