import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useGoogleMap } from '@react-google-maps/api'
import { transport } from '../../lib/services'
import { zoomBreakPoint } from './constants'
import MAP_TYPES from '../../lib/constants/mapTypes'
import PropertyPolygonsStore from '../../components/Shared/stores/PropertyPolygonsStore'

const propertyPolygonsStore = new PropertyPolygonsStore({ transport })

const PropertyPolygons = () => {
  const map = useGoogleMap()

  const setDataLayerStyle = () => {
    const mapType = map.getMapTypeId()
    const strokeColor = mapType === MAP_TYPES.roadmap ? '#000' : '#fff'

    const style = {
      fillColor: 'transparent',
      strokeWeight: 2,
      strokeColor
    }

    map.data.setStyle(style)
  }

  const handleBounds = () => {
    const zoom = map.getZoom()

    if (zoom < zoomBreakPoint) {
      map.data.setMap(null)
      return
    }

    const mapBounds = map.getBounds().toJSON()
    map.data.setMap(map)
    setDataLayerStyle()
    propertyPolygonsStore.fetchPropertyPolygons(mapBounds)
  }

  useEffect(() => {
    const boundsListener = map.addListener('bounds_changed', handleBounds)
    return () => {
      boundsListener.remove()
    }
  }, [])

  useEffect(() => {
    if (propertyPolygonsStore.hasPolygons) {
      map.data.addGeoJson(propertyPolygonsStore.propertyPolygons)
    }
  }, [propertyPolygonsStore.propertyPolygons])

  return null
}

PropertyPolygons.propTypes = {
  propertyPolygonsStore: PropTypes.shape({
    hasPolygons: PropTypes.bool,
    fetchPropertyPolygons: PropTypes.func.isRequired,
    propertyPolygons: PropTypes.object.isRequired
  })
}

export default observer(PropertyPolygons)
