import { makeObservable, observable, action, computed } from 'mobx'
import GDouglasPeucker from '../../../../lib/utils/googleMapsDouglasPeuker'
import {callThrottler} from '../../../../lib/utils/common'
import { defaultZoom } from '../../../../shared_components/Map/constants'
const throttle = callThrottler(2)

class MapStore {
  zoom = defaultZoom
  @observable isFreehandDrawing = false
  @observable isDrawingUIMode = false
  @observable rawPolyline = []
  @observable polygon = []

  constructor() {
    makeObservable(this)
  }

  setZoom = value => {
    this.zoom = value
  }

  @action
  setDrawingUIMode = value => {
    this.isDrawingUIMode = value
  }

  @action
  setFreehandDrawing = value => {
    this.isFreehandDrawing = value
  }

  @action
  setRawPolyline = latLng => {
    throttle(() => { this.rawPolyline.push(latLng) })
  }

  @action
  resetRawPolyline = () => {
    this.rawPolyline = []
  }

  @action
  calculatePolygon = () => {
    this.polygon = this.polyline
  }

  @action
  setPolygon = value => {
    this.polygon = value
  }

  @action
  resetPolygon = () => {
    this.polygon = []
  }

  @computed
  get polyline() {
    return GDouglasPeucker(this.rawPolyline, 100 / Math.exp(this.zoom))
  }

  @computed
  get hasPolygon() {
    return Boolean(this.polygon.length)
  }
}

export default MapStore
