import { PlaceHolderHouseUrl } from '../../components/Svg'

const storyToClient = story => ({
  title: story.title,
  url: story.post_link,
  imageUrl: story.featured_image || PlaceHolderHouseUrl,
  category: story.category
})

export const mapStoriesToClient = stories => stories.map(storyToClient)
