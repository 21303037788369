import React from 'react'
import PropTypes from 'prop-types'
import { Select } from '../../../../Shared/Form'
import { identity } from '../../../../../lib/utils/selectors'

const HouseRules = ({ valueStateStore, view }) => (
  <fieldset className='ListingForm-groupFieldset'>
    <legend className='ListingForm-groupLabel'>
      House Rules
    </legend>
    <div className='ListingForm-fieldRow ListingForm-fieldRow--withColumns'>
      <div className='ListingForm-fieldColumn ListingForm-fieldColumn--mobileRow'>
        <div className='Label'>
          Smoking Policy
        </div>
        <div className='ListingForm-input' data-qa-advanced-smoking-policy-checkbox>
          <Select
            id={`smoking-policy-${view}`}
            name='smokingPolicy'
            options={valueStateStore.smokingPolicies}
            optionIdentity={identity}
            selectedOptionTemplate={identity}
            optionTemplate={identity}
            value={valueStateStore.values.smokingPolicy || 'Any'}
            className={'Select--stoneBorder'}
            onChange={value => { valueStateStore.setInputValue('smokingPolicy', value) }}
            withScrollIntoView
          />
        </div>
      </div>
      <div className='ListingForm-fieldColumn ListingForm-fieldColumn--mobileColumn'>
        <div className='Label'>
          Pet Policy
        </div>
        <div className='ListingForm-input' data-qa-advanced-pet-policy-checkbox>
          <Select
            id={`pet-policy-${view}`}
            name='petPolicy'
            options={valueStateStore.petPolicies}
            optionIdentity={identity}
            selectedOptionTemplate={identity}
            optionTemplate={identity}
            value={valueStateStore.values.petPolicy || 'Any'}
            className={'Select--stoneBorder'}
            onChange={value => { valueStateStore.setInputValue('petPolicy', value) }}
            withScrollIntoView
          />
        </div>
      </div>
    </div>
  </fieldset>
)

HouseRules.propTypes = {
  valueStateStore: PropTypes.object.isRequired,
  view: PropTypes.string
}

export default HouseRules
