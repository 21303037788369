import React from 'react'
import { Provider } from 'mobx-react'
import rootStoreInstance from '../stores/rootStoreInstance'
import MapLink from './MapLink'
import { withErrorBoundary } from '../../Shared/ErrorBoundary'

const StateFulMapLink = () => (
  <Provider store={rootStoreInstance}>
    <MapLink />
  </Provider>
)

export default withErrorBoundary(StateFulMapLink)
