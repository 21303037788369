import { observable, action, computed, makeObservable } from 'mobx'

export const Page = {
  signin: 'signin',
  signup: 'signup',
  confirm: 'confirm',
  forgot: 'forgot',
  edit: 'edit',
  setPassword: 'setPassword'
}

class UIStateStore {
  @observable currentView = Page.signin
  @observable isModalOpen = false
  @observable isShowErrors = false

  modalSettings = {}

  constructor() {
    makeObservable(this)
  }

  @action('[UIStateStore] setInitialData')
  setInitialData(data) {
    this.withAppleAuth = data.withAppleAuth
  }

  @action('[UIStateStore] Update current tab') updateCurrentView = v => {
    this.currentView = v
  }

  @action('[UIStateStore] open modal') openModal = (currentView = Page.signin, modalSettings = {}) => {
    this.isModalOpen = true
    this.modalSettings = modalSettings
    this.currentView = currentView
  }

  @action('[UIStateStore] close modal') closeModal = () => {
    this.isModalOpen = false
  }

  @action('[UIStateStore] show reset password') resetPassword = () => {
    this.currentView = Page.confirm
  }

  @computed
  get currentModalLabel() {
    switch (this.currentView) {
    case Page.signin || Page.signup:
      return 'Sign In/Sign Up'
    case Page.confirm:
      return 'Confirm password reset'
    case Page.forgot:
      return 'Password reset'
    case Page.edit:
      return 'Reset your password'
    case Page.setPassword:
      return 'Set your password'
    default:
      return 'Sign In/Sign Up'
    }
  }
}

export default UIStateStore
