import * as yup from 'yup'

export const simpleSalesSearchSchema = yup.object({
  towns: yup.array(),
  prices: yup.array(),
  beds: yup.number()
})

export const simpleRentalsSearchSchema = yup.object({
  towns: yup.array(),
  pricePeriod: yup.string(),
  prices: yup.array(),
  beds: yup.number(),
  year: yup.number()
})
