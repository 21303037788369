import React, { memo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { useStores } from '../../../../shared_components/Hooks'
import { PlaceHolderHouseUrl } from '../../../Svg'

const countSlideShow = 5

const Carousel = ({ registerPhotoCarouselRef, photoSlider }) => {
  const { store: { hdpStore, photoGalleryUIStateStore } } = useStores()
  const carouselRef = useRef(null)

  useEffect(() => {
    const slickTrack = carouselRef.current.querySelector('.slick-track')
    const slides = carouselRef.current.querySelectorAll('.slick-slide')
    slickTrack.setAttribute('role', 'list')
    slides.forEach(slide => {
      slide.setAttribute('role', 'listitem')
    })
  }, [])

  const settings = {
    arrows: false,
    lazyLoad: true,
    speed: 500,
    slidesToShow: countSlideShow,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    asNavFor: photoSlider,
    initialSlide: 0,
    infinite: hdpStore.images.length > countSlideShow,
    afterChange: () => {
      const currentSlide = carouselRef.current.querySelector('.slick-current')

      const { activeElement } = document
      const isSlide = activeElement.classList.contains('slick-slide')

      if (isSlide) {
        const slideButton = currentSlide.querySelector('[data-slide]')
        slideButton.focus()
      }
    },
    className: 'Carousel-content'
  }

  return (
    <div
      className='Carousel'
      role='region'
      aria-label='Listing photo'
      aria-roledescription='carousel'
      ref={carouselRef}
    >
      <Slider ref={registerPhotoCarouselRef} {...settings}>
        {photoGalleryUIStateStore.images.map((media, index) => (
          <div
            key={media.id}
            role='group'
            aria-label={`${hdpStore.addressOrTitle} ${media.caption || ''}`}
            aria-roledescription='slide'
          >
            <button data-slide='true' className='Carousel-slideButton'>
              <div tabIndex='-1' className='Carousel-imageContainer'>
                <picture>
                  <source srcSet={media.thumb.webp} type='image/webp'/>
                  <img
                    src={media.thumb.jpeg}
                    className='Carousel-image'
                    onError={() => {
                      photoGalleryUIStateStore.replaceImageUrl(index, PlaceHolderHouseUrl)
                    }}
                    alt={`${hdpStore.addressOrTitle} ${media.caption || ''}`}
                  />
                </picture>
              </div>
            </button>
          </div>
        ))}
      </Slider>
    </div>
  )
}

Carousel.propTypes = {
  registerPhotoCarouselRef: PropTypes.func,
  photoSlider: PropTypes.object
}

export default memo(Carousel)
