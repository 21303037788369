import React from 'react'
import { Provider } from 'mobx-react'
import rootStoreInstance from '../stores/rootStoreInstance'
import FlashMessages from '../../FlashMessages/FlashMessages'
import { withErrorBoundary } from '../../Shared/ErrorBoundary'

const StateFulFlashMessages = props => (
  <Provider store={rootStoreInstance}>
    <FlashMessages {...props} />
  </Provider>
)

export default withErrorBoundary(StateFulFlashMessages)
