/* eslint react/no-unknown-property: 0 */
/* eslint no-shadow: 0 */
/* eslint jsx-a11y/no-autofocus: 0 */

import React, { useRef } from 'react'
import { observer } from 'mobx-react'
import Form from 'react-formal'
import cn from 'classnames'
import { onFormChange } from './handlers'
import { Captcha, ErrorMessage, InfoMessage } from '../../Shared/Form'
import { authResetUserSchema } from '../../../schemas/user'
import { noop } from '../../../lib/utils/common'
import { Page } from '../stores/UIStateStore'
import { useStores } from '../../../shared_components/Hooks'
import useCaptchaHandlers from '../../Shared/Form/Captcha/useCaptchaHandlers'

const PasswordReset = () => {
  const { store: { userStore, uiStateStore, resetPasswordCaptchaStore } } = useStores()
  const emailFieldRef = useRef(null)
  const { captchaRef, resetCaptcha } = useCaptchaHandlers()

  const handleFormChange = onFormChange({ userStore })

  const onFormSubmit = () => {
    userStore
      .handleResetPassword({ captcha: resetPasswordCaptchaStore.toForm })
      .then(() => {
        resetPasswordCaptchaStore.checkCaptchaActive()
      })
      .then(() => {
        resetPasswordCaptchaStore.resetRecaptchaResponse()
        resetCaptcha()
      })
  }

  const setFocusOnError = errors => {
    if (errors.email) {
      emailFieldRef.current.focus()
    }
  }

  return (
    <Form className='Form'
      onSubmit={onFormSubmit}
      schema={authResetUserSchema}
      value={userStore.toForm}
      onChange={handleFormChange}
      onError={noop}
      errors={userStore.formErrors}
      onInvalidSubmit={errors => {
        userStore.setErrors(errors)
        setFocusOnError(errors)
      }}
    >
      <div className='Form-wrap Form-wrap--extraSpace'>
        <div className='Text Text--modalTitle Text--alignCenter'>
          Password Reset
        </div>

        <Form.Message for='unknownUser'>
          {errors => <ErrorMessage>{errors}</ErrorMessage>}
        </Form.Message>

        <InfoMessage isActive={true}>
          Enter your email address and we’ll send you a link to reset your password.
        </InfoMessage>

        <div className='Form-row'>
          <label className='Label' htmlFor='email'>Email</label>
          <Form.Field
            id='email'
            type='email'
            name='email'
            errorClass='isInvalid'
            className='TextField'
            placeholder='your@email'
            ref={emailFieldRef}
            aria-describedby='emailError'
            aria-invalid={'email' in userStore.formErrors || null}
            autoComplete='email'
            autoFocus
          />
          <Form.Message id='emailError' className='ErrorText' for='email'/>
        </div>

        <Captcha
          isCaptchaActive={resetPasswordCaptchaStore.isCaptchaActive}
          onChange={resetPasswordCaptchaStore.updateRecaptchaResponse}
          setCaptchaRef={captchaRef}
        />

        <div className='Form-row Form-row--mediumSpace'>
          <Form.Submit
            type='submit'
            className={cn('Button Button--primary Button--withoutBorder Button--size13', {
              isDisabled: !resetPasswordCaptchaStore.isCaptchaValid
            })}
          >
            Submit
          </Form.Submit>
        </div>

        <div className='Form-row Form-row--centredText'>
          <button
            type='button'
            className='Button Button--link'
            onClick={() => {
              uiStateStore.updateCurrentView(Page.signin)
            }}
          >
            Back to sign in
          </button>
        </div>
      </div>
    </Form>
  )
}

export default observer(PasswordReset)
