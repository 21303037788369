import React, { Fragment, useCallback } from 'react'
import PropTypes from 'prop-types'
import { observer, PropTypes as MobxPropTypes } from 'mobx-react'
import { isPresent } from '../../../../lib/utils/collection'
import { invokeAction } from '../../../../lib/globalActions'
import { CONTACT_FORM_STATE } from '../../../../lib/utils/stores'
import ContactForm from '../ContactForm'
import { useFormSubmitHandler, useStores } from '../../../../shared_components/Hooks'

const defaultRenderHeader = contactFormStore => (
  <Fragment>
    <div className='ContactForm-title'>Contact Agent</div>
    <div className='Text Text--a2 Text--lineHeight24px'>
      {contactFormStore.agentName} - {contactFormStore.companyName}
    </div>
  </Fragment>
)

const ContactFormWrapper = ({
  contactFormStore,
  renderHeader = defaultRenderHeader,
  deactivateContactForm,
  setContactFormState
}) => {
  const { store: { contactCaptchaStore, userStore } } = useStores()
  const { setFormRef, performFormSubmit } = useFormSubmitHandler()

  const handleSubmit = useCallback(() => {
    return contactFormStore.submit({ captcha: contactCaptchaStore.toForm })
      .then(response => {
        if (response.success) {
          contactCaptchaStore.resetRecaptchaResponse()
          invokeAction('FlashMessages', 'addFlashMessage', {
            text: 'Message has been sent',
            type: 'success'
          })

          if (isPresent(contactFormStore.userId)) {
            deactivateContactForm()
          } else {
            userStore.setInitialData()
            setContactFormState(CONTACT_FORM_STATE.createUser)
          }
        }
      })
  }, [contactFormStore, contactCaptchaStore, userStore])

  return (
    <div className='ContactForm ContactForm--modal'>
      <div className='ContactForm-content'>

        {renderHeader(contactFormStore)}

        <div className='ContactForm-form'>
          <ContactForm
            handleSubmit={handleSubmit}
            contactFormStore={contactFormStore}
            contactCaptcha={contactCaptchaStore}
            messagePlaceholder='Add note...'
            setFormRef={setFormRef}
          />
        </div>

        <div className='ContactForm-actions'>
          <div className='ContactForm-action'>
            <button
              type='submit'
              className='Button Button--primary'
              onClick={performFormSubmit}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

ContactFormWrapper.propTypes = {
  contactFormStore: PropTypes.shape({
    submit: PropTypes.func.isRequired,
    toForm: MobxPropTypes.objectOrObservableObject.isRequired,
    agentName: PropTypes.string,
    companyName: PropTypes.string,
    userId: PropTypes.number
  }).isRequired,
  deactivateContactForm: PropTypes.func.isRequired,
  renderHeader: PropTypes.func,
  setContactFormState: PropTypes.func.isRequired
}

export default observer(ContactFormWrapper)
