import React from 'react'
import { observer } from 'mobx-react'
import Slider from 'react-slick'
import Arrow from '../../../shared_components/Arrow'
import AgentInfo from './AgentInfo'
import OwnerInfo from './OwnerInfo'
import { useStores } from '../../../shared_components/Hooks'

const OpenListingSlider = () => {
  const { store: { hdpStore } } = useStores()

  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow screenReaderText='Next listing'/>,
    prevArrow: <Arrow screenReaderText='Previous listing'/>,
    className: 'OpenListingSlider-container',
    dots: true,
    dotsClass: 'OpenListingSlider-dots',
    initialSlide: hdpStore.currentIndexOpenListing,
    afterChange: index => {
      hdpStore.updateListingInfo(index)
    }
  }

  return (
    <div className='OpenListingSlider'>
      <Slider {...settings}>
        {hdpStore.openListings.map(listing => (
          <div key={listing.id} className='OpenListingCard'>
            <div className='OpenListingCard-items'>
              {listing.byOwner ? <OwnerInfo/> : <AgentInfo {...listing.listor} />}
              <div className='OpenListingCard-item OpenListingCard-item--width90px'>
                <div className='OpenListingCard-title'>Last Updated</div>
                <div className='OpenListingCard-text'>{listing.lastUpdated}</div>
              </div>
              <div className='OpenListingCard-item OpenListingCard-item--width50px'>
                <div className='OpenListingCard-title'>Photos</div>
                <div className='OpenListingCard-text'>{listing.photos.length}</div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default observer(OpenListingSlider)
