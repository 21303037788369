import React from 'react'
import { Provider } from 'mobx-react'
import rootStoreInstance from '../stores/rootStoreInstance'
import ListingBy from './ListingBy'
import ErrorBoundary from '../../Shared/ErrorBoundary'

const StateFulListingBy = props => {
  rootStoreInstance.setInitialDataListingBy(props)
  rootStoreInstance.setInitialDataOfficeAddress(props)

  return (
    <ErrorBoundary>
      <Provider store={rootStoreInstance}>
        <ListingBy/>
      </Provider>
    </ErrorBoundary>
  )
}

export default StateFulListingBy
