import { PlaceHolderHouseUrl } from '../../components/Svg'

const guideToClient = guide => ({
  title: guide.name,
  url: guide.guide_link,
  description: guide.description,
  imageUrl: guide.featured_image || PlaceHolderHouseUrl,
  category: {
    name: 'guides',
    link: '/guides'
  }
})

export const mapGuidesToClient = guides => guides.map(guideToClient)
