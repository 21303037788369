import React from 'react'
import PropTypes from 'prop-types'
import { Observer, PropTypes as MobxPropTypes } from 'mobx-react'
import { Marker, MarkerClusterer, InfoBox, useGoogleMap } from '@react-google-maps/api'
import { buildMarkerSvgBinary, isActiveMarker } from './utils'
import { MIN_DISTANCE } from './constants'
import ListingCardPreview from '../../../shared_components/Map/ListingCardPreview'

const ListingsMarkerClusterer = ({ store, cardPreviewClass }) => {
  const map = useGoogleMap()

  const markAsActive = listing => () => {
    store.markAsActive(listing)
  }

  const markAsNoActive = () => {
    store.markAsNoActive()
  }

  const handleOnSaveClick = e => {
    e.stopPropagation()
    e.preventDefault()
    store.updateSaveStatus(store.activeItem)
  }

  const onClusteringEnd = markerClusterer => {
    const allClusters = markerClusterer.getClusters()
    const clusters = allClusters.filter(cls => cls.getMarkers().length > 1)
    const zoom = map.getZoom()

    clusters.forEach(cluster => {
      cluster.getMarkers().forEach((marker, index) => {
        marker.setPosition({
          lat: marker.lat + index * MIN_DISTANCE * Math.log10(zoom),
          lng: marker.lng + index * MIN_DISTANCE * Math.log10(zoom)
        })
      })
    })
  }

  const isCardVisible = listing => listing.id === store.activeItemID

  return (
    <MarkerClusterer
      defaultMaxZoom={0}
      gridSize={0}
      onClusteringEnd={onClusteringEnd}
    >
      {() => (
        store.collection.map(listing => (
          <Observer key={listing.id}>
            {() => (
              <Marker
                position={{ lat: listing.lat, lng: listing.lon }}
                icon={{ url: buildMarkerSvgBinary({ store, listing }) }}
                zIndex={isActiveMarker(store, listing) ? 1 : 0}
                onClick={markAsActive(listing)}
              >
                {isCardVisible(listing)
                  ? <InfoBox
                    options={{
                      boxStyle: { overflow: 'unset' },
                      infoBoxClearance: global.google && new global.google.maps.Size(10, 10),
                      closeBoxURL: '',
                      pixelOffset: global.google && new global.google.maps.Size(-93, -287),
                      enableEventPropagation: true
                    }}
                  >
                    <ListingCardPreview
                      listing={listing}
                      handleOnSaveClick={handleOnSaveClick}
                      handleSetInactive={markAsNoActive}
                      cardPreviewClass={cardPreviewClass}
                    />
                  </InfoBox>
                  : null
                }
              </Marker>
            )}
          </Observer>
        ))
      )}
    </MarkerClusterer>
  )
}

ListingsMarkerClusterer.propTypes = {
  store: PropTypes.shape({
    collection: MobxPropTypes.arrayOrObservableArray,
    activeItemID: PropTypes.number,
    updateSaveStatus: PropTypes.func.isRequired,
    markAsActive: PropTypes.func.isRequired,
    markAsNoActive: PropTypes.func.isRequired,
    activeItem: PropTypes.object
  }).isRequired,
  cardPreviewClass: PropTypes.string
}

export default ListingsMarkerClusterer
