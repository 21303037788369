import { compose, lifecycle, withHandlers, withProps } from 'recompose'
import { inject, observer } from 'mobx-react'
import { CONTACT_FORM_STATE } from '../../../lib/utils/stores'
import { withPortal } from '../../Hocs'

export const withContactFormHandlers = (additionalHandlers = {}) => compose(
  inject('store'),
  withProps(({ store }) => ({
    uiState: store.uiStateStore,
    contactFormStore: store.contactAgentFormStore,
    contactCaptcha: store.contactCaptchaStore,
    userStore: store.userStore
  })),
  withHandlers({
    deactivateContactForm: ({ uiState }) => () => {
      uiState.setContactFormState(CONTACT_FORM_STATE.inactive)
    }
  }),
  withHandlers(additionalHandlers),
  lifecycle({
    componentDidMount() {
      document.getElementById('site-scroller').classList.add('isModalActive')
      this.props.contactCaptcha.checkCaptchaActive()
    },
    componentWillUnmount() {
      document.getElementById('site-scroller').classList.remove('isModalActive')
    }
  }),
  withPortal('[data-contact-form=container]'),
  observer
)
