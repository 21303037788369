import React from 'react'
import PropTypes from 'prop-types'

const NoSavedItems = ({ type }) => (
  <div className='CardsPreview'>
    <div className='CardsPreview-textContainer'>
      <div className='CardsPreview-textContainerRow'>
        <div className='Text Text--a1 Text--fontWeightMedium Text--slateColor'>
          No Saves Yet!
        </div>
      </div>
      <div className='Text Text--a1 Text--lineHeight19px Text--slateColor'>
        {
          type === 'listings'
            ? `Save ${type} that catch your eye as a favorite so you can find it later.
             We’ll let you know the second there are updates.`
            : `Save ${type} as a favorite so you can find it later. We’ll let you know the second there are updates.`
        }
      </div>
    </div>
  </div>
)

NoSavedItems.propTypes = {
  type: PropTypes.string.isRequired
}

export default NoSavedItems
