import React from 'react'
import PropTypes from 'prop-types'
import { compose, withHandlers, withProps, withState } from 'recompose'
import SavedListings from './SavedListings'
import SavedSearches from './SavedSearches'
import routes from '../../../lib/config/routes'
import Tabs from '../Tabs/Tabs'

const initialTab = {
  tabId: 'saved-listings-tab',
  panelId: 'saved-listings-panel',
  type: 'listings',
  ref: React.createRef(),
  label: 'Listings',
  url: routes.saveListing
}

const enhance = compose(
  withState('activeTab', 'setActiveTab', initialTab),
  withProps(props => ({
    tabs: [
      {
        tabId: 'saved-listings-tab',
        panelId: 'saved-listings-panel',
        type: 'listings',
        ref: React.createRef(),
        label: 'Listings',
        url: routes.saveListing,
        isActive: props.activeTab.type === 'listings'
      },
      {
        tabId: 'saved-searches-tab',
        panelId: 'saved-searches-panel',
        type: 'searches',
        ref: React.createRef(),
        label: 'Searches',
        url: routes.saveSearch,
        isActive: props.activeTab.type === 'searches'
      }
    ],
    setIsActiveDropdown: props.setIsActiveDropdown
  })),
  withHandlers({
    tabAction: ({ setActiveTab }) => tab => setActiveTab(tab)
  })
)

const SavedItemsContent = ({ tabs, activeTab, listings, searches, tabAction }) => (
  <div className='SimpleTabs'>
    <Tabs
      tabs={tabs}
      tabAction={tabAction}
      tabsWrapperClassName='SimpleTabs-header'
      tabClassName='SimpleTabs-title'
      tabClassNameModifier='SimpleTabs-title--savedItems'
      ariaLabel='Saved Items'
      withTabIndex={false}
    />
    <div
      className='SimpleTabs-content'
      id={activeTab.panelId}
      aria-labelledby={activeTab.tabId}
    >
      {activeTab.type === tabs[0].type ? (
        <SavedListings
          listings={listings}
          type={activeTab.type}
        />
      ) : (
        <SavedSearches
          searches={searches}
          type={activeTab.type}
        />
      )}
      <div className='SimpleTabs-action'>
        <a className='SimpleTabs-link' href={activeTab.url}>
            VIEW ALL
        </a>
      </div>
    </div>
  </div>
)

SavedItemsContent.propTypes = {
  activeTab: PropTypes.object.isRequired,
  tabAction: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    tabId: PropTypes.string.isRequired,
    panelId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    ref: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired),
  listings: PropTypes.array.isRequired,
  searches: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired)
}

export default enhance(SavedItemsContent)
