import { renderComponent, compose, setDisplayName, withProps } from 'recompose'
import { cond } from '../../../../components/Hocs'
import { LANDS_TYPE, RENTALS_TYPE, SALES_TYPE } from '../../../Shared/stores/constants'
import SaleForm from './SaleForm'
import RentalForm from './RentalForm'
import LandForm from './LandForm'

const AdvancedFilterContent = compose(
  setDisplayName('AdvancedFilterContent'),
  withProps(({ tabs }) => ({
    tabConfig: tabs.find(tab => tab.isActive) || {}
  })),
  cond(
    ({ currentListingType }) => currentListingType,
    {
      [SALES_TYPE]: renderComponent(SaleForm),
      [RENTALS_TYPE]: renderComponent(RentalForm),
      [LANDS_TYPE]: renderComponent(LandForm)
    }
  )
)

export default AdvancedFilterContent()
