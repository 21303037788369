import { observable, action, makeObservable } from 'mobx'

class UIStateStore {
  @observable isModalOpen = false
  @observable listingType = null
  @observable selectedItem = null

  constructor() {
    makeObservable(this)
  }

  submitText = 'CREATE LISTING'
  modalTitle = 'Create a New Listing'

  @action('[UIStateStore] Set Initial Data')
  setInitialData = ({ options: { selectedItem } }) => {
    this.selectedItem = selectedItem
  }

  @action('[UIStateStore] open modal')
  openModal = type => {
    this.listingType = type
    this.isModalOpen = true
  }

  @action('[UIStateStore] close create listing modal')
  closeModal = () => {
    this.listingType = null
    this.isModalOpen = false
  }
}

export default UIStateStore
