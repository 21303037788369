import React from 'react'
import { CalendarAvailableUrl } from '../../../Svg'

const AvailableIcon = () => (
  <div className='PricePeriodTable-iconContainer'>
    <img src={CalendarAvailableUrl} className='PricePeriodTable-icon' alt='' aria-hidden='true'/>
    <span className='PricePeriodTable-iconText'>Available</span>
  </div>
)

export default AvailableIcon
