import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { withProps, withHandlers, compose } from 'recompose'
import Modal from '../../../Shared/Modal'
import ShareListingForm from './ShareListingForm'
import SocialLinks from './SocialLinks'

const enhance = compose(
  inject('store'),
  withProps(({ store }) => ({
    uiState: store.uiStateStore,
    hdpStore: store.hdpStore,
    labelId: 'dialog-head',
    describedId: 'property-address'
  })),
  withHandlers({
    closeModal: props => () => {
      props.uiState.toggleShareListingModal(false)
    }
  }),
  observer
)

const ShareListingModal = ({ uiState, hdpStore, closeModal, labelId, describedId }) => (
  <Modal
    isOpen={uiState.isShareListingModalOpen}
    onRequestClose={closeModal}
    ariaHideApp={false}
    portalClassName='ReactModalPortal ReactModalPortal--share'
    aria={{
      labelledby: labelId,
      describedby: describedId
    }}
  >
    <div className='ShareListing'>
      <div className='ShareListing-content'>
        <div className='ShareListing-header'>
          <h2 className='ShareListing-title' id={labelId}>Share listing</h2>
          <div className='ShareListing-text'>
            <h3 className='Text Text--a2 Text--lineHeight24px Text--ellipsis Text--margin0' id={describedId}>
              {hdpStore.addressOrTitle}
            </h3>
          </div>
        </div>
        <ShareListingForm />
        <div className='ShareListing-footer'>
          Share via link
          <SocialLinks listingUrl={hdpStore.listingUrl}/>
        </div>
      </div>
    </div>
  </Modal>
)

ShareListingModal.propTypes = {
  uiState: PropTypes.shape({
    isShareListingModalOpen: PropTypes.bool.isRequired
  }),
  hdpStore: PropTypes.shape({
    addressOrTitle: PropTypes.string.isRequired,
    listingUrl: PropTypes.string.isRequired
  }),
  closeModal: PropTypes.func.isRequired,
  labelId: PropTypes.string.isRequired,
  describedId: PropTypes.string.isRequired

}

export default enhance(ShareListingModal)


