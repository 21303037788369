import { observe } from 'mobx'
import UIStateStore from './UIStateStore'
import SearchStore from './SearchStore'

class RootStore {
  constructor(context) {
    this.uiStateStore = new UIStateStore()
    this.searchStore = new SearchStore(context)
    this.setObservers()
  }

  setInitialData(searchType, props) {
    this.uiStateStore.setSearchType(searchType)
    this.searchStore.setSearchType(searchType)

    this.uiStateStore.setInitialState(props)
    this.searchStore.setInitialState(props)
  }

  setInitialProps(props) {
    this.props = props
  }

  setObservers() {
    observe(this.searchStore, 'beds', ({ newValue }) => {
      this.uiStateStore.updateBedsTitle(newValue)
    })

    observe(this.searchStore, 'prices', ({ newValue }) => {
      this.uiStateStore.updatePriceTitle(newValue)
    })

    observe(this.uiStateStore, 'searchType', ({ newValue, oldValue }) => {
      if (this.props && oldValue) {
        this.searchStore.setSearchType(newValue)
        this.uiStateStore.setInitialState(this.props)
        this.searchStore.setInitialState(this.props)
      }
    })
  }
}

export default RootStore
