import PropTypes from 'prop-types'

export default {
  uiStateStore: PropTypes.shape({
    priceExtremum: PropTypes.object.isRequired
  }),
  valueStateStore: PropTypes.shape({
    values: PropTypes.object,
    treeTownOptions: PropTypes.array
  }),
  tabConfig: PropTypes.shape({
    panelId: PropTypes.string.isRequired,
    tabId: PropTypes.string.isRequired
  }).isRequired
}
