import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useStores } from '../../../../shared_components/Hooks'
import { CONTACT_FORM_STATE } from '../../../../lib/utils/stores'
import Modal from '../../Modal'
import ContactModalContent from './ContactModalContent'

const ContactModal = ({
  contactFormStore,
  renderHeader,
  children
}) => {
  const { store: { contactCaptchaStore, uiStateStore} } = useStores()

  const deactivateContactForm = () => {
    uiStateStore.setContactFormState(CONTACT_FORM_STATE.inactive)
  }

  const openModal = e => {
    e.preventDefault()
    e.stopPropagation()
    contactCaptchaStore.checkCaptchaActive()
    uiStateStore.setContactFormState(CONTACT_FORM_STATE.makeContact)
  }

  return (
    <Fragment>
      {children({ openModal })}
      <Modal
        isOpen={uiStateStore.contactFormState !== CONTACT_FORM_STATE.inactive}
        onRequestClose={deactivateContactForm}
        ariaHideApp={false}
        portalClassName='ReactModalPortal'
        className='Modal-content Modal-content--withScroll'
        shouldReturnFocusAfterClose={true}
      >
        <ContactModalContent
          deactivateContactForm={deactivateContactForm}
          contactFormStore={contactFormStore}
          renderHeader={renderHeader}
          className='ContactForm--modal'
          contactFormState={uiStateStore.contactFormState}
          setContactFormState={uiStateStore.setContactFormState}
        />
      </Modal>
    </Fragment>
  )
}

ContactModal.propTypes = {
  uiStateStore: PropTypes.shape({
    setContactFormState: PropTypes.func.isRequired,
    contactFormState: PropTypes.string.isRequired
  }),
  contactCaptcha: PropTypes.shape({
    checkCaptchaActive: PropTypes.func.isRequired
  }),
  contactFormStore: PropTypes.object.isRequired,
  renderHeader: PropTypes.func,
  children: PropTypes.func.isRequired
}

export default observer(ContactModal)
