import React from 'react'
import { branch, renderComponent, renderNothing } from 'recompose'
import PropTypes from 'prop-types'

const Indicators = ({ children = [], navContainerRef }) => (
  <ul className='SimpleSlider-dots SimpleSlider-dots--centered' ref={navContainerRef}>
    {children.map(child => (
      <li className='SimpleSlider-dot' key={child.key}>
        <button type='button' className='SimpleSlider-dotButton'/>
      </li>
    ))}
  </ul>
)

Indicators.propTypes = {
  children: PropTypes.array,
  navContainerRef: PropTypes.object.isRequired
}

export default branch(
  ({ settings }) => settings.nav,
  renderComponent(Indicators),
  renderNothing
)()
