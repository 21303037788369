import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import cn from 'classnames'
import { useStores } from '../../../shared_components/Hooks'

const SavedButton = ({ variant = '' }) => {
  const { store: { hdpStore } } = useStores()

  const handleClick = () => {
    hdpStore.updateSaveStatus()
  }

  return (
    <button role='switch' aria-checked={hdpStore.isListingSaved}
      className={cn(
        'Button Button--secondary Button--iconPrefix Button--iconHeart Button--focusVisible',
        variant,
        { isActive: hdpStore.isListingSaved }
      )}
      onClick={handleClick}
    >

      {hdpStore.isListingSaved ? 'Saved' : 'Save'}

    </button>
  )
}

SavedButton.propTypes = {
  variant: PropTypes.string
}

export default observer(SavedButton)
