import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes, observer } from 'mobx-react'
import { Polygon } from '@react-google-maps/api'
import { useRegisterGlobalActions } from '../../../shared_components/Hooks'
import Map from './MapContainer'
import ListingsMarkerClusterer from './ListingsMarkerClusterer'
import MapControls from '../../../shared_components/Map/MapControls'
import DrawLayer from './DrawLayer'
import useCloseCardHandler from './hooks/useCloseCardHandler'
import { polygonBaseStyle } from './constants'
import polygons from '../../../lib/config/polygons.json'
import PropertyPolygons from '../../../shared_components/Map/PropertyPolygons'

const SearchResultsMapDesktop = ({
  header,
  cardPreviewClass,
  layout,
  mapBounds,
  searchPolygon,
  onSubmitSearch,
  onBoundsChanged,
  onSearchPolygonChanged,
  useHandDrawing,
  store,
  mapStore,
  usePropertyPolygons
}) => {
  // layout = undefined for the SRP || 'fixedHeight' for the agents page
  const containerElementStyle = layout === 'fixedHeight'
    ? { height: '450px' }
    : { height: 'calc(100vh - 148px)' }

  const { containerRef } = useCloseCardHandler(store.markAsNoActive)

  useRegisterGlobalActions({
    context: store,
    key: 'SearchResultsMapDesktop',
    actions: ['setHoveredListingId']
  })

  return (
    <div className='MapContainer' ref={containerRef} style={containerElementStyle}>
      <Map
        markers={store.collection}
        mapBounds={mapBounds}
        onBoundsChanged={onBoundsChanged}
        onZoomChanged={mapStore.setZoom}
      >
        {store.highlightedAreas.map((polygon, i) => {
          return polygons[polygon]
            ? <Polygon
              key={i}
              options={polygonBaseStyle}
              path={polygons[polygon].points}
            />
            : null
        })}

        <ListingsMarkerClusterer
          store={store}
          cardPreviewClass={cardPreviewClass}
        />

        <MapControls className='MapControls-mapView'/>

        {useHandDrawing && (
          <DrawLayer
            mapStore={mapStore}
            header={header}
            searchPolygon={searchPolygon}
            onSubmitSearch={onSubmitSearch}
            onSearchPolygonChanged={onSearchPolygonChanged}

            containerRef={containerRef}
          />
        )}

        {usePropertyPolygons && <PropertyPolygons/>}
      </Map>
    </div>
  )
}

SearchResultsMapDesktop.propTypes = {
  header: PropTypes.string,
  cardPreviewClass: PropTypes.string,
  layout: PropTypes.string,
  mapBounds: MobxPropTypes.objectOrObservableObject,
  searchPolygon: MobxPropTypes.arrayOrObservableArray,
  onSubmitSearch: PropTypes.func,
  onBoundsChanged: PropTypes.func,
  onSearchPolygonChanged: PropTypes.func,
  useHandDrawing: PropTypes.bool,
  usePropertyPolygons: PropTypes.bool,
  store: PropTypes.shape({
    collection: MobxPropTypes.arrayOrObservableArray,
    markAsNoActive: PropTypes.func.isRequired,
    highlightedAreas: MobxPropTypes.arrayOrObservableArray
  }).isRequired,
  mapStore: PropTypes.shape({
    setZoom: PropTypes.func.isRequired
  }).isRequired
}

export default observer(SearchResultsMapDesktop)
