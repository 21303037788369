import React, { Fragment, useCallback, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import * as R from 'ramda'
import { isPresent } from '../../../lib/utils/collection'
import { onKey, watchKey } from '../../../lib/utils/dom'
import { useFormSubmitHandler } from '../../../shared_components/Hooks'
import { withPortal } from '../../Hocs'
import { withContactFormHandlers } from '../../Hocs'
import FOCUSABLE_ELEMENT_SELECTORS from '../../../lib/constants/focusable'
import { CONTACT_FORM_STATE } from '../../../lib/utils/stores'
import KEY from '../../../lib/constants/key'
import { CloseUrl } from '../../Svg'
import ContactForm from '../../Shared/ContactForm'

const AgentName = ({ value }) => (
  value
    ? <span className='ContactForm-title--strong'>{value}</span>
    : 'an agent'
)

AgentName.propTypes = {
  value: PropTypes.string
}

const BrokerageName = ({ value }) => (
  value
    ? <Fragment>{' '} from <span className='ContactForm-title--strong'>{value}</span></Fragment>
    : null
)

BrokerageName.propTypes = {
  value: PropTypes.string
}

const enhance = R.compose(withPortal('[data-contact-form=container]'), observer)

const ContactAgentForm = ({
  setFocusableElements,
  setFocusToButton,
  deactivateContactForm,
  uiState,
  contactAgentFormStore,
  contactCaptcha,
  userStore
}) => {
  const contentRef = useRef(null)
  let focusableElements = useRef(null)
  const { setFormRef, performFormSubmit } = useFormSubmitHandler()

  const onKeyDownContent = e => {
    const keyTabCallback = () => {
      e.stopPropagation()
      global.requestAnimationFrame(() => {
        const { activeElement } = document

        if (!focusableElements.includes(activeElement)) {
          focusableElements[0].focus()
        }
      })
    }
    watchKey(e, KEY.TAB, keyTabCallback)
    onKey(e, KEY.ESC, () => {
      deactivateContactForm()
    })
  }

  useEffect(() => {
    const contentNode = contentRef.current
    focusableElements = contentNode.querySelectorAll(FOCUSABLE_ELEMENT_SELECTORS)
    setFocusableElements(Array.from(focusableElements))
    contentNode.addEventListener('keydown', onKeyDownContent)

    return () => {
      contentNode.removeEventListener('keydown', onKeyDownContent)

      global.requestAnimationFrame(() => {
        setFocusToButton()
      })
    }
  }, [])

  const handleSubmit = useCallback(() => {
    return contactAgentFormStore.submit({ captcha: contactCaptcha.toForm })
      .then(response => {
        if (response.success) {
          contactCaptcha.resetRecaptchaResponse()

          if (isPresent(contactAgentFormStore.userId)) {
            uiState.setContactFormState(CONTACT_FORM_STATE.inactive)
          } else {
            userStore.setInitialData()
            uiState.setContactFormState(CONTACT_FORM_STATE.createUser)
          }
        }
      })
  }, [contactAgentFormStore, contactCaptcha, uiState, userStore])

  const handleKeyDownCloseForm = e => {
    onKey(e, KEY.RETURN, () => {
      deactivateContactForm()
    })
  }

  return (
    <div
      className='ContactForm'
      ref={contentRef}
      role='dialog'
      aria-modal='true'
      tabIndex='-1'
    >
      <div className='ContactForm-content'>
        <div className='ContactForm-title'>
          {'Use this form to get in touch with '}
          <AgentName value={contactAgentFormStore.agentName}/>
          <BrokerageName value={contactAgentFormStore.companyName}/>
          {' about showings and property details.'}
        </div>
        <button
          type='button'
          className='ContactForm-closeButton'
          onClick={deactivateContactForm}
          onKeyDown={handleKeyDownCloseForm}
        >
          <img className='ContactForm-closeImage' src={CloseUrl} alt='close form'/>
        </button>
        <ContactForm
          handleSubmit={handleSubmit}
          contactFormStore={contactAgentFormStore}
          contactCaptcha={contactCaptcha}
          setFormRef={setFormRef}
        />
      </div>
      <div className='ContactForm-actions'>
        <div className='ContactForm-action'>
          <button
            className='Button Button--secondary'
            onClick={deactivateContactForm}
            onKeyDown={handleKeyDownCloseForm}
          >
            Cancel
          </button>
        </div>
        <div className='ContactForm-action'>
          <button
            type='submit'
            aria-describedby='terms-info'
            className='Button Button--primary'
            onClick={performFormSubmit}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  )
}

ContactAgentForm.propTypes = {
  contactCaptcha: PropTypes.shape({
    isCaptchaValid: PropTypes.bool,
    checkCaptchaActive: PropTypes.func,
    resetRecaptchaResponse: PropTypes.func,
    toForm: MobxPropTypes.objectOrObservableObject.isRequired
  }),
  uiState: PropTypes.shape({
    setContactFormState: PropTypes.func
  }),
  userStore: PropTypes.shape({
    setInitialData: PropTypes.func
  }),
  contactAgentFormStore: PropTypes.any.isRequired,
  deactivateContactForm: PropTypes.func.isRequired,
  setFocusableElements: PropTypes.func.isRequired,
  setFocusToButton: PropTypes.func.isRequired
}

export default withContactFormHandlers()(enhance(ContactAgentForm))
