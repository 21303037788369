/* eslint valid-jsdoc: 0 */
/* eslint consistent-return: 0 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { tns } from 'tiny-slider/src/tiny-slider'
import { equals } from 'ramda'
import KEY from '../../../lib/constants/key'
import { onKey } from '../../../lib/utils/dom'

/**
 * Compare two children by keys
 * @returns {Boolean}
 */
const ChildrenEqual = (val1, val2) => {
  const keys1 = val1.map(child => child.key)
  const keys2 = val2.map(child => child.key)

  // different size
  if (keys1.length !== keys2.length) {
    return false
  }

  // check each key
  for (let i = 0; i < keys1.length; i = i + 1) {
    if (keys1[i] !== keys2[i]) { return false }
  }

  return true
}

class TinySliderReact extends Component {
  static propTypes = {
    children: PropTypes.node,
    settings: PropTypes.object,
    onClick: PropTypes.func,
    startIndex: PropTypes.number,
    onIndexChanged: PropTypes.func,
    onTransitionStart: PropTypes.func,
    onTransitionEnd: PropTypes.func,
    onTouchStart: PropTypes.func,
    onTouchMove: PropTypes.func,
    onTouchEnd: PropTypes.func,
    onInit: PropTypes.func,
    className: PropTypes.string.isRequired,
    prevButton: PropTypes.object,
    nextButton: PropTypes.object,
    simpleSliderRef: PropTypes.object
  }

  static defaultProps = {
    onInit: () => {}
  }

  constructor(props) {
    super(props)

    this.state = {
      defaultSettings: {}
    }

    this.slider = null
    this.build = this.build.bind(this)
    this.onClick = this.onClick.bind(this)
    this.dragging = false
    this.mergedSettings = 0
    this.count = 0
    this.prevActiveElement = null
    this.liveRegion = null
  }

  /**
   * Fire click on carousel when no draggin and call the onClick callback on prop
   */
  onClick(event) {
    const { onClick } = this.props
    if (this.dragging || !onClick) {return}

    // when only one element the slider doesnt init
    if (!this.slider) {
      return onClick(null, null, event)
    }

    const info = this.slider.getInfo()
    const slideClicked = info.slideItems[info.index]

    // call click callback with info and slide clicked
    onClick(slideClicked, info, event)
  }

  // set textContent in liveregion node for infinite sliders
  // TODO when in tiny-slider will be solve bug and text in node will be right this decision can be removed
  getActiveSlidesIndexes = info => {
    const activeSlides = Array.from(info.slideItems)
      .filter(slide => slide.classList.contains('tns-slide-active'))
      .map(s => s.dataset.index)

    return activeSlides.length === info.items
      ? [activeSlides[0], activeSlides[activeSlides.length - 1]]
      : [activeSlides[1], activeSlides[activeSlides.length - 2]]
  }

  setLiveRegionText = info => {
    const [from, to] = this.getActiveSlidesIndexes(info)
    this.liveRegion.textContent = from === to
      ? `Slide ${from} of ${info.slideCount}`
      : `Slide from ${from} to ${to} of ${info.slideCount}`
  }

  lazyload() {
    [].forEach.call(this.ref.querySelectorAll('img'), img => {
      if (img.dataset.src) {
        img.src = img.dataset.src
        img.className = img.className + ' loaded'
      }
    })
  }

  /** * Initialize the carousel plugin with new settings */
  build(settings = {}) {
    if (this.slider) {
      this.slider.destroy()
    }

    this.mergedSettings = {
      ...this.state.defaultSettings,
      ...settings,
      container: this.ref,
      onInit: info => {
        this.postInit()
        setTimeout(() => {
          this.liveRegion = this.props.simpleSliderRef.current.querySelector('.tns-liveregion')
          if(this.liveRegion && this.props.settings.loop) {
            this.setLiveRegionText(info)
          }
        }, 500)

        info.nextButton.removeAttribute('tabindex')
        info.prevButton.removeAttribute('tabindex')
      }
    }

    this.slider = tns(this.mergedSettings)

    // call events binding
    if (this.slider) {return}

    // if the slider doesn't load because there is just a child so add class to normal behavior
    if (this.ref) {
      this.ref.className = this.ref.className + ' tns-item'
    }

    // so if there is lazy load active load src manually
    if (this.mergedSettings.lazyload) {
      this.lazyload()
    }
  }

  /**
   * Once the slider plugins has been initialized
  */
  postInit() {
    if (!this.slider) {
      if (this.count >= 4) {
        // call initMethod anyway
        return this.props.onInit(false)
      }
      this.count = this.count + 1
      return setTimeout(this.postInit.bind(this), 100)
    }
    this.count = 0

    const { events, goTo } = this.slider

    const {
      onIndexChanged,
      onTransitionStart,
      onTransitionEnd,
      onTouchStart,
      onTouchMove,
      onTouchEnd,
      startIndex,
      onInit
    } = this.props

    /* BIND EVENTS */

    // change dragging value to purge onClick event.
    events.on('transitionStart', info => {
      if(this.liveRegion && this.props.settings.loop) {
        this.setLiveRegionText(info)
      }

      this.dragging = true
      if (onTransitionStart) {onTransitionStart(info)}
    })

    events.on('transitionEnd', info => {
      this.dragging = false
      if (onTransitionEnd) {onTransitionEnd(info)}
    })

    if (onIndexChanged) {events.on('indexChanged', onIndexChanged)}
    if (onTouchStart) {events.on('touchStart', onTouchStart)}
    if (onTouchMove) {events.on('touchMove', onTouchMove)}
    if (onTouchEnd) {events.on('touchEnd', onTouchEnd)}

    /* GO TO START SLIDE */
    if (startIndex) {
      goTo(startIndex)
    }

    // call on Init
    onInit(true)
  }

  goToSlide = direction => {
    this.slider.goTo(direction)

    setTimeout(() => {
      const { slideItems, index } = this.slider.getInfo()

      slideItems[index].querySelector('a').focus()
    }, this.props.settings.speed + 50)
  }

  onKeyDownMoveSlider = e => {
    if(e.key !== KEY.LEFT && e.key !== KEY.RIGHT) {
      return
    }

    onKey(e, KEY.LEFT, () => this.goToSlide('prev'))
    onKey(e, KEY.RIGHT, () => this.goToSlide('next'))
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.ref.addEventListener('keydown', this.onKeyDownMoveSlider)
    this.ref.addEventListener('click', this.onClick)
  }

  componentDidUpdate(prevProps) {
    const isSettingsEquals = equals(prevProps.settings, this.props.settings)
    const isChildrenEquals = ChildrenEqual(prevProps.children, this.props.children)

    if (isSettingsEquals && isChildrenEquals) {
      return
    }

    // prepare to reinitialization
    if (this.slider) {
      this.slider.rebuild()
    } else {
      this.build(this.props.settings)
    }
  }

  componentWillUnmount() {
    if (this.slider) {
      this.slider.destroy()
      this.ref.removeEventListener('keydown', this.onKeyDownMoveSlider)
      this.ref.removeEventListener('click', this.onClick)
    }
  }

  render() {
    const { children, className } = this.props
    return (
      <ul className={className} ref={ele => (this.ref = ele)}>
        {children}
      </ul>
    )
  }
}

export default TinySliderReact
