import React from 'react'
import PropTypes from 'prop-types'
import { isLast, isPresent } from '../../../lib/utils/collection'
import { sanitizeID } from '../../../lib/utils/string'
import { gaEvents, location } from '../../../lib/services'
import { noop } from '../../../lib/utils/common'

const eventChooser = eventFor => {
  const mapper = {
    srp: gaEvents.Srp.breadcrumbs,
    hdp: gaEvents.Hdp.breadcrumbs
  }
  return mapper[eventFor] || noop
}

const Breadcrumbs = ({ breadcrumbs, gaEvent }) => {
  const ariaCurrent = (index, collection) => isLast(index, collection) ? 'page' : null

  const onClick = url => e => {
    e.preventDefault()
    eventChooser(gaEvent)(() => { location.redirect(url) })
  }

  const items = breadcrumbs.map((item, i) => (
    <li className='Breadcrumbs-item' key={`${sanitizeID(item.label)}`}>
      {isPresent(item.url)
        ? <a className='Breadcrumbs-link'
          onClick={onClick(item.url)}
          href={item.url} aria-current={ariaCurrent(i, breadcrumbs)}>{item.label}</a>
        : item.label
      }
    </li>
  ))

  return (<nav aria-label='Breadcrumbs'>
    <ol className='Breadcrumbs'>
      {items}
    </ol>
  </nav>)
}

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string
    }).isRequired
  ).isRequired,
  gaEvent: PropTypes.string
}

export default Breadcrumbs
