/* eslint-disable camelcase */
import { observable, action, computed, makeObservable } from 'mobx'

class TownGuideStore {
  @observable imageUrl = ''
  @observable title = ''
  @observable link = ''
  @observable description = ''
  @observable townName = ''

  constructor({ transport }) {
    makeObservable(this)

    this.transport = transport
  }

  fetchData = (blogApiDomain, townShortName) => {
    this.transport.Guides.get(blogApiDomain, townShortName).then(response => this.setData(response || {}))
  }

  @computed get isTownGuidePresent() {
    return this.imageUrl !== '' && this.title !== '' && this.description !== '' && this.townName !== ''
  }

  @action('[TownGuideStore] Set data')
  setData = ({ hero_image = '', hero_blurb = '', hero_tagline = '', guide_link = '', name = '' }) => {
    this.imageUrl = hero_image
    this.title = hero_tagline
    this.link = guide_link
    this.description = hero_blurb
    this.townName = name
  }
}

export default TownGuideStore
