import React from 'react'
import { CalendarUnAvailableUrl } from '../../../Svg'

const NotAvailableIcon = () => (
  <div className='PricePeriodTable-iconContainer'>
    <img src={CalendarUnAvailableUrl} className='PricePeriodTable-icon' alt='' aria-hidden='true'/>
    <span className='PricePeriodTable-iconText'>Not Available</span>
  </div>
)

export default NotAvailableIcon
