import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Avatar from '../../../agent_components/Shared/Avatar'

const ListingDetailsAgent = ({ agent, professionalMode, sendAnalytics }) => {
  const [isVisiblePhoneNumber, setIsVisiblePhoneNumber] = useState(false)

  const showPhoneNumber = () => {
    setIsVisiblePhoneNumber(true)
    sendAnalytics()
  }

  return (
    <div className='ListingDetails-agent'>
      <div className='AgentBox'>
        <div className='AgentBox-content'>
          <div className='AgentBox-inner'>
            {
              agent.published ?
                <a href={agent.url}>
                  <div className='AgentBox-avatar'>
                    <Avatar {...agent} />
                  </div>
                </a>
                :
                <div className='AgentBox-avatar'>
                  <Avatar {...agent} />
                </div>
            }
          </div>
          <div className='AgentBox-inner'>
            <div className='AgentBox-fullName'>
              {agent.published ?
                <a className='Link Link--hoverUnderline Link--charcoalColor' href={agent.url}>
                  {agent.fullName}
                </a>
                :
                agent.fullName
              }
            </div>

            <div className='AgentBox-license'>
              {`Licensed ${agent.licenseType}`}
            </div>

            <div className='AgentBox-company'>
              {agent.companyName} - {agent.city}
            </div>
            {professionalMode && (
              <div className='AgentBox-email'>{agent.email}</div>
            )}
            {
              isVisiblePhoneNumber ?
                <div className='AgentBox-phoneNumber'>
                  {agent.phoneNumber}
                </div>
                :
                <button className='AgentBox-phoneNumber AgentBox-showPhoneNumber' onClick={showPhoneNumber}>
                  Show phone number
                </button>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

ListingDetailsAgent.propTypes = {
  agent: PropTypes.shape({
    fullName: PropTypes.string,
    companyName: PropTypes.string,
    townName: PropTypes.string,
    phoneNumber: PropTypes.string,
    url: PropTypes.string,
    published: PropTypes.bool,
    licenseType: PropTypes.string,
    city: PropTypes.string,
    email: PropTypes.string
  }),
  professionalMode: PropTypes.bool,
  sendAnalytics: PropTypes.func
}

export default ListingDetailsAgent
