import * as yup from 'yup'
import Messages from './messages'
import moment from 'moment'

yup.addMethod(yup.date, 'dateGreaterThan', function (ref, message) {
  return this.test('dateGreaterThan', message, function (value) {
    const other = this.resolve(ref)

    return !(moment(other).isSame(moment(value)))
  })
})

export const contactAgentSchema = yup.object({
  name: yup.string().nullable().required(Messages.name.required),
  email: yup.string().nullable().email(Messages.email.valid).required(Messages.email.required),
  phone: yup.string().nullable(),
  message: yup.string(),
  datesRange: yup.object({
    start: yup.date().nullable(),
    end: yup.date().nullable().dateGreaterThan(yup.ref('start'), 'End date must be after start date'),
    offset: yup.object({
      value: yup.number(),
      label: yup.string()
    })
  })
})
