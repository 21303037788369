import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../../shared_components/Hooks'
import Zillow3DTourModal from './Zillow3DTourModal'

const Button = () => {
  const { store: { uiStateStore, hdpStore } } = useStores()

  const handleClick = () => {
    uiStateStore.toggleZillow3DTourModal(true)
  }

  return (hdpStore.zillow3dTour && (
    <Fragment>
      <button className='Button Button--secondary' onClick={handleClick}>TAKE 3D TOUR</button>
      <Zillow3DTourModal/>
    </Fragment>
  ))
}

export default observer(Button)
