import { fromClientToServer, fromServerToClient } from '../../../lib/utils/collection'
import filtersDescriptionMapping, {
  filtersToDescriptionsSchema, filtersToJSONSchema
} from '../../../lib/utils/filtersDescriptionsMapping'

export const mapProps = props => {
  const clientProps = fromServerToClient(props)

  const initialState = clientProps.initialState
  const listingType = clientProps.currentListingType
  const uiState = initialState[listingType].uiStateStore
  const searchState = initialState[listingType].searchStateStore
  const valueState = initialState[listingType].valueStateStore

  return {
    initialState: {
      UIStateStore: {
        priceExtremum: uiState.priceExtremum,
        orderOptions: uiState.orderOptions,
        pricePeriods: uiState.pricePeriods,
        pricePeriodYears: uiState.pricePeriodYears,
        unitTypesCollection: uiState.unitTypesCollection,
        statusesCollection: uiState.statusesCollection,
        bedsBathsOptions: uiState.bedsBathsOptions,
        pricePeriodsDictionary: fromClientToServer(uiState.pricePeriodsDictionary)
      },
      SearchStateStore: {
        search: searchState.search,
        listingClass: searchState.listingClass
      },
      ListingTypeStore: {
        current: listingType
      },
      ValueStateStore: valueState,
      DepotStore: initialState
    }
  }
}

const mapSearchValues = schema => ({uiStateStore, listingTypeStore, valueStateStore}) => value => (
  filtersDescriptionMapping(schema)(value, {
    statusesCollection: uiStateStore.statusesCollection,
    unitTypesCollection: uiStateStore.unitTypesCollection,
    pricePeriods: uiStateStore.pricePeriods,
    valueStateStore: valueStateStore,
    listingType: listingTypeStore.currentName
  })
)

export const humanizeSearchValues = mapSearchValues(filtersToDescriptionsSchema)
export const serializeSearchValues = mapSearchValues(filtersToJSONSchema)
export const toLatLngLiteral = polygon => polygon.map(point => ({ lat: point.lat(), lng: point.lng() }))
