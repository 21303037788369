import React from 'react'
import { Provider } from 'mobx-react'
import rootStoreInstance from '../stores/rootStoreInstance'
import VideoLink from './VideoLink'
import { withErrorBoundary } from '../../Shared/ErrorBoundary'

const StateFulVideoLink = () => (
  <Provider store={rootStoreInstance}>
    <VideoLink />
  </Provider>
)

export default withErrorBoundary(StateFulVideoLink)
