import React from 'react'
import { Provider } from 'mobx-react'
import rootStoreInstance from '../stores/rootStoreInstance'
import ListingTitle from './ListingTitle'
import ErrorBoundary from '../../Shared/ErrorBoundary'

const StateFulListingTitle = (props) => {
  rootStoreInstance.setInitialDataListingTitle(props)

  return (
    <ErrorBoundary>
      <Provider store={rootStoreInstance}>
        <ListingTitle/>
      </Provider>
    </ErrorBoundary>
  )
}

export default StateFulListingTitle
