import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { transport, location } from '../../lib/services'
import ErrorBoundary from '../Shared/ErrorBoundary'
import { isPresent } from '../../lib/utils/collection'
import { isRegistered, invokeAction } from '../../lib/globalActions'
import { useRegisterGlobalActions } from '../../shared_components/Hooks'
import RootStore from './stores/RootStore'
import SelectPropertyAddressModal from '../../shared_components/SelectPropertyAddressModal/SelectPropertyAddressModal'

const rootStore = new RootStore({ transport, location })

const CreateDraftListing = props => {
  rootStore.setInitialData(props)

  const isAuthenticated = isPresent(props.userInfo)

  const openAuthModal = () => {
    if (isRegistered('AuthModal', 'openModal')) {
      invokeAction('AuthModal', 'openModal')
    }
  }

  const openSaleModal = () => {
    if (isAuthenticated) {
      rootStore.uiStateStore.openModal('Sale')
      return
    }

    openAuthModal()
  }

  const openRentalModal = () => {
    if (isAuthenticated) {
      rootStore.uiStateStore.openModal('Rental')
      return
    }

    openAuthModal()
  }

  useRegisterGlobalActions({ context: { openSaleModal, openRentalModal },
    key: 'SubmitYourListing',
    actions: ['openSaleModal', 'openRentalModal'] }
  )

  return (
    <ErrorBoundary>
      <SelectPropertyAddressModal
        propertyStore={rootStore.draftListingStore}
        modalStore={rootStore.uiStateStore}
      />
    </ErrorBoundary>
  )
}

CreateDraftListing.propTypes = {
  rootStore: PropTypes.object,
  userInfo: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    phone: PropTypes.string
  }).isRequired
}

export default observer(CreateDraftListing)
