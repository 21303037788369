import React from 'react'
import DatePicker from 'react-datepicker'
import cn from 'classnames'
import { compose, lifecycle, withProps } from 'recompose'
import PropTypes from 'prop-types'
import { isViewport } from '../../../../lib/utils/viewport'

const enhance = compose(
  withProps(() => ({
    datePickerRef: React.createRef()
  })),
  lifecycle({
    componentDidMount() {
      const { datePickerRef } = this.props
      const day = datePickerRef.current.querySelector('.react-datepicker__day[tabindex="0"]')

      global.requestAnimationFrame(() => {
        if (day) {
          day.focus()
        }
      })
    },
    componentWillUnmount() {
      this.props.datesInputButtonRef.current.focus()
    }
  })
)

const DateRangePicker = ({ className, value, datePickerRef, onChange, children, title, labelId }) => (
  <div
    className={cn('DateRangePicker', className)}
    ref={datePickerRef}
  >
    <div className='DateRangePicker-content'>
      {title && <h2 className='DateRangePicker-title' id={labelId}>{title}</h2>}
      <DatePicker
        selected={value.start || new Date()}
        minDate={new Date()}
        onChange={onChange}
        startDate={value.start}
        endDate={value.end}
        selectsRange
        inline
        monthsShown={isViewport('mobile') ? 1 : 2}
      />
      {children}
    </div>
  </div>
)

DateRangePicker.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }),
  datePickerRef: PropTypes.object,
  children: PropTypes.node,
  title: PropTypes.string,
  labelId: PropTypes.string
}

export default enhance(DateRangePicker)
