import React from 'react'
import PropTypes from 'prop-types'
import { MapStaticUrl } from '../../../Svg'
import { useStores } from '../../../../shared_components/Hooks'

const MapButton = ({ mapButtonRef }) => {
  const { store: { photoGalleryUIStateStore } } = useStores()

  const activateMapView = () => {
    photoGalleryUIStateStore.changeContentType('map')
  }

  return (
    <button
      className='MapButton MapButton--kbdOnlyFocus'
      onClick={activateMapView}
      ref={mapButtonRef}
    >
      <div className='MapButton-container' tabIndex='-1'>
        <div className='MapButton-title'>
          Map View
        </div>
        <div className='MapButton-content'>
          <img src={MapStaticUrl} className='MapButton-image' alt=''/>
        </div>
      </div>
    </button>
  )
}

MapButton.propTypes = {
  mapButtonRef: PropTypes.object
}

export default MapButton
