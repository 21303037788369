import React, { Fragment, useState } from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'
import PriceHistoryTable from './PriceHistoryTable'
import { useStores } from '../../../shared_components/Hooks'

const tableWrapperStyle = {
  position: 'relative',
  marginBottom: '60px'
}

const buttonClassnames = 'Button--withoutBorder Button--withoutPadding Button--autoSized Button--focusVisible'

const PriceHistory = () => {
  const { store: { priceHistoryStore } } = useStores()
  const [isContentHidden, setContentHidden] = useState(true)

  const handleShowFullHistory = () => {
    setContentHidden(false)
    priceHistoryStore.sendShowHistoryAnalytics()
  }
  const ariaControlsId = priceHistoryStore.collection.reduce((prev, current) => {
    if (current.isHidden) {
      return `${prev}${current.id} `
    }
    return prev
  }, '')

  return (
    priceHistoryStore.hasHistory
      ? <Fragment>
        <div className='Hdp-section' key='Hdp-section'>
          <h2 className='Heading Heading--section'>Price history</h2>
          <div className='Hdp-box'>
            <div style={tableWrapperStyle}>
              <PriceHistoryTable collection={priceHistoryStore.collection} isContentHidden={isContentHidden}/>

              <div className={cn(
                'ToggableText ToggableText--table',
                { isShown: priceHistoryStore.hasDisclosure && isContentHidden}
              )}>
                <div className='ToggableText-button'>
                  <button
                    className={cn('Button Button--link', buttonClassnames)}
                    onClick={handleShowFullHistory}
                    aria-controls={ariaControlsId}
                    aria-expanded={false}
                  >
                    VIEW FULL HISTORY <span role='presentation'>+</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='Hdp-horizontalRule' key='Hdp-horizontalRule'/>
      </Fragment>
      : null
  )
}

export default observer(PriceHistory)
