import React from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../../../shared_components/Hooks'
import { isPresent } from '../../../lib/utils/collection'

const ListingBy = () => {
  const { store: { hdpStore } } = useStores()
  const hasData = isPresent(hdpStore.listingBy) || isPresent(hdpStore.officeAddress)

  return (
    hasData && (
      <div className='Text Text--a1 Text--lineHeight21px Text--mobileSize14px' data-test-id='listing-by-text'>
        {`${hdpStore.listingBy} ${hdpStore.officeAddress}`}
      </div>
    )
  )
}

export default observer(ListingBy)
