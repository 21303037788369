import { reaction, observe } from 'mobx'
import UIStateStore from './UIStateStore'
import { CaptchaStore, UserStore } from '../../Shared/stores'
import { SIGN_IN_CAPTCHA_KEY, SIGN_UP_CAPTCHA_KEY, RESET_PASSWORD_CAPTCHA_KEY } from '../../../lib/config/recaptcha'

class RootStore {
  constructor(context) {
    this.uiStateStore = new UIStateStore(context)
    this.userStore = new UserStore(context)
    this.signInCaptchaStore = new CaptchaStore({...context, captchaKey: SIGN_IN_CAPTCHA_KEY})
    this.signUpCaptchaStore = new CaptchaStore({...context, captchaKey: SIGN_UP_CAPTCHA_KEY})
    this.resetPasswordCaptchaStore = new CaptchaStore({...context, captchaKey: RESET_PASSWORD_CAPTCHA_KEY})
    this.setReactions()
    this.setObservers()
  }

  setInitialData(data) {
    this.userStore.setInitialData()
    this.uiStateStore.setInitialData(data)
  }

  setReactions() {
    reaction(
      () => this.userStore.responseResetPassword,
      resetPassword => {
        if (resetPassword) {
          this.uiStateStore.resetPassword()
        }
      }
    )

    reaction(
      () => this.uiStateStore.isModalOpen,
      isModalOpen => {
        if (!isModalOpen) {
          this.userStore.resetPassword(isModalOpen)
          this.userStore.resetErrors()
        }
      }
    )
  }

  setObservers() {
    observe(this.uiStateStore, 'currentView', () => {
      this.userStore.resetPassword(false)
      this.userStore.resetErrors()
    })

    observe(this.uiStateStore, 'isModalOpen', ({ newValue }) => {
      if (!newValue) {
        this.userStore.resetAfterAuthenticatedPath()
      }
    })
  }
}

export default RootStore
