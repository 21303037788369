import React from 'react'
import { Provider } from 'mobx-react'
import { transport, location, gaEvents, sessionStorage, userInfo } from '../../lib/services'
import { RootStore } from './stores'
import startRouter from './router'
import ErrorBoundary from '../Shared/ErrorBoundary'
import AuthModal from './AuthModal'

const rootStoreInstance = new RootStore({ transport, location, gaEvents, sessionStorage, userInfo })
startRouter(rootStoreInstance)

const Auth = props => {
  rootStoreInstance.setInitialData(props)

  return (
    <ErrorBoundary>
      <Provider store={rootStoreInstance}>
        <AuthModal/>
      </Provider>
    </ErrorBoundary>
  )
}

export default Auth
