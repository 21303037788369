import React from 'react'
import PropTypes from 'prop-types'
import { withProps, withHandlers, compose, lifecycle } from 'recompose'
import cn from 'classnames'
import { inject, observer } from 'mobx-react'
import { CSSTransition } from 'react-transition-group'
import { humanizeSearchValues } from '../utils'
import AdvancedFilter from '../AdvancedFilter'

const saveSearchButtonClassName = 'Button Button--primary Button--oval Button--saveSearchMobile'

const enhance = compose(
  inject('store'),
  withProps(({store}) => ({
    uiStateStore: store.uiStateStore,
    searchStateStore: store.searchStateStore,
    listingTypeStore: store.listingTypeStore,
    valueStateStore: store.valueStateStore,
    mobileContentRef: React.createRef()
  })),
  withHandlers({
    saveSearch: ({ valueStateStore, searchStateStore }) => () => {
      searchStateStore.saveSearch({ values: valueStateStore.valuesForSubmit }, 'mobile')
    }
  }),
  lifecycle({
    componentDidMount() {
      document.body.classList.add('isFixedBottomBox')
      this.props.mobileContentRef.current.addEventListener('click', this.props.uiStateStore.openModal)
    },
    componentWillUnmount() {
      document.body.classList.remove('isFixedBottomBox')
      this.props.mobileContentRef.current.removeEventListener('click', this.props.uiStateStore.openModal)
    }
  }),
  observer
)

const SearchBarMobile = ({
  store, valueStateStore, listingTypeStore, mobileContentRef, searchStateStore, saveSearch
}) => (
  <div className='SearchBar'>
    <div className='SearchBar-mobileContent' ref={mobileContentRef}>
      <div className='SearchBar-iconContainer'>
        <AdvancedFilter
          submit={() => { valueStateStore.submit(listingTypeStore) }}
          reset={() => { valueStateStore.resetAllInputs() }}
        />
      </div>
      <div className='SearchBar-pillsContainer'>
        {
          humanizeSearchValues(store)(valueStateStore.valuesForSubmit).map(filterDescription => (
            <div key={filterDescription} className='FilterPill FilterPill--sidesSpace'>
              <div className='FilterPill-content'>
                <span className='FilterPill-label'>
                  {filterDescription}
                </span>
              </div>
            </div>
          ))
        }
      </div>
    </div>
    <CSSTransition
      in={!searchStateStore.search.saved}
      timeout={300}
      classNames='SearchBar-saveSearch'
      unmountOnExit
    >
      <div className='SearchBar-saveSearch'>
        {searchStateStore.search.saving || searchStateStore.search.saved
          ? <div className={saveSearchButtonClassName}>Search saved!</div>
          : <button
            onClick={saveSearch}
            className={cn(saveSearchButtonClassName, 'Button--iconPrefix Button--blackBell')}
          >
            Save search
          </button>
        }
      </div>
    </CSSTransition>
  </div>
)

SearchBarMobile.propTypes = {
  store: PropTypes.any.isRequired,
  mobileContentRef: PropTypes.object,
  searchStateStore: PropTypes.shape({
    search: PropTypes.object.isRequired
  }),
  listingTypeStore: PropTypes.shape({
    listingTypes: PropTypes.object.isRequired,
    current: PropTypes.string.isRequired,
    setCurrent: PropTypes.func.isRequired
  }),
  valueStateStore: PropTypes.shape({
    values: PropTypes.object.isRequired,
    submit: PropTypes.func.isRequired,
    resetAllInputs: PropTypes.func.isRequired,
    valuesForSubmit: PropTypes.object.isRequired
  }),
  saveSearch: PropTypes.func.isRequired
}

export default enhance(SearchBarMobile)
