import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { withProps, withHandlers, compose } from 'recompose'
import { FilterUrl } from '../../../Svg/index'
import Modal from '../../../Shared/Modal'
import { RENTALS_TYPE, SALES_TYPE, LANDS_TYPE } from '../../../Shared/stores/constants'
import AdvancedFilterContent from './AdvancedFilterContent'
import Tabs from '../../../Shared/Tabs/Tabs'

const enhance = compose(
  inject('store'),
  withProps(({ store }) => ({
    listingTypeStore: store.listingTypeStore,
    uiStateStore: store.uiStateStore,
    valueStateStore: store.valueStateStore
  })),
  withProps(({ listingTypeStore }) => ({
    tabs: [
      {
        tabId: 'advanced-filters-sales-tab',
        panelId: 'advanced-filters-sales-panel',
        type: SALES_TYPE,
        ref: React.createRef(),
        label: 'Sales',
        isActive: listingTypeStore.current === SALES_TYPE
      },
      {
        tabId: 'advanced-filters-rentals-tab',
        panelId: 'advanced-filters-rentals-panel',
        type: RENTALS_TYPE,
        ref: React.createRef(),
        label: 'Rentals',
        isActive: listingTypeStore.current === RENTALS_TYPE
      },
      {
        tabId: 'advanced-filters-lands-tab',
        panelId: 'advanced-filters-lands-panel',
        type: LANDS_TYPE,
        ref: React.createRef(),
        label: 'Lands',
        isActive: listingTypeStore.current === LANDS_TYPE
      }
    ]
  })),
  withHandlers({
    tabAction: ({ listingTypeStore, valueStateStore }) => tab => {
      listingTypeStore.setCurrent(tab.type)
      valueStateStore.resetToDefaultValues()
    },
    closeModal: ({ uiStateStore }) => e => {
      e.stopPropagation()
      uiStateStore.closeModal()
    }
  }),
  observer
)

const AdvancedFilter = ({
  listingTypeStore,
  tabAction,
  submit,
  reset,
  closeModal,
  uiStateStore,
  valueStateStore,
  tabs
}) => (
  <div className='AdvancedFilter'>
    <button className='Button Button--link Button--height40 desktop-viewport' onClick={uiStateStore.openModal}>
        Advanced Filters
    </button>
    <button
      className='Button Button--link AdvancedFilter-mobileIcon tablet-viewport'
      onClick={uiStateStore.openModal}
    >
      <img src={FilterUrl} alt='Advanced Search' style={{ aspectRatio: '20 / 17' }}/>
    </button>
    <Modal
      isOpen={uiStateStore.isActive}
      onRequestClose={closeModal}
      ariaHideApp={false}
      mainAction={submit}
      aria={{
        label: 'Advanced Filters'
      }}
    >
      <div className='AdvancedFilter-modal'>
        <div className='AdvancedFilter-wrapper'>
          <Tabs
            tabs={tabs}
            tabAction={tabAction}
            tabsWrapperClassName='AdvancedFilter-modalHeader'
            tabClassName='AdvancedFilter-tab'
            ariaLabel='Advanced Filters Tabs'
          />
          <div className='AdvancedFilter-modalContent'>
            <AdvancedFilterContent
              tabs={tabs}
              currentListingType={listingTypeStore.current}
            />
          </div>
          <div className='AdvancedFilter-modalFooter'>
            <div className='AdvancedFilter-buttonHolder'>
              <button className='Button Button--secondary' onClick={reset}>
                Reset All
              </button>
            </div>
            <div className='AdvancedFilter-buttonHolder'>
              <button className='Button Button--primary' onClick={submit}>
                See results ({valueStateStore.countResults})
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
)

AdvancedFilter.propTypes = {
  listingTypeStore: PropTypes.shape({
    current: PropTypes.string.isRequired,
    setCurrent: PropTypes.func.isRequired
  }).isRequired,
  uiStateStore: PropTypes.shape({
    isActive: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired
  }).isRequired,
  valueStateStore: PropTypes.shape({
    countResults: PropTypes.number
  }).isRequired,
  submit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    tabId: PropTypes.string.isRequired,
    panelId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    ref: PropTypes.object.isRequired,
    label: PropTypes.string
  }).isRequired),
  activeTab: PropTypes.object,
  tabAction: PropTypes.func
}

export default enhance(AdvancedFilter)
