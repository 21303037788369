import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'mobx-react'
import { compose, withProps, lifecycle } from 'recompose'
import { mapProps } from './utils'
import SearchBar from './SearchBar'
import { withErrorBoundary } from '../../Shared/ErrorBoundary'
import { rootStoreInstance } from './stores'
import { withTurbolinksReload } from '../../Hocs'

const enhance = compose(
  withTurbolinksReload({ store: rootStoreInstance, event: 'turbolinks:load' }),
  withProps(props => {
    rootStoreInstance.setInitialState(mapProps(props))
    return {
      rootStore: rootStoreInstance
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.rootStore.saveSearchInfo()
    }
  }),
  withErrorBoundary
)

const StatefulSearchBar = ({rootStore}) => (
  <Provider store={rootStore}>
    <SearchBar />
  </Provider>
)

StatefulSearchBar.propTypes = {
  rootStore: PropTypes.object.isRequired
}

export default enhance(StatefulSearchBar)
