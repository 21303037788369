import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import selectedBarStoreInstance from '../../../agent_components/Shared/SelectedBar/stores/selectedBarStoreInstance'
import AddToBasket from './AddToBasket'
import { withErrorBoundary } from '../../Shared/ErrorBoundary'

const StateFulAddToCart = ({ listingId, listingClass }) => {
  useEffect(() => {
    selectedBarStoreInstance.setInitialData({})
  }, [])

  return <AddToBasket store={selectedBarStoreInstance} listingId={listingId} listingClass={listingClass}/>
}

StateFulAddToCart.propTypes = {
  listingId: PropTypes.number.isRequired,
  listingClass: PropTypes.string.isRequired
}

export default withErrorBoundary(StateFulAddToCart)
