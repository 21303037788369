import { action, observable, computed, toJS, makeObservable } from 'mobx'
import { toFormErrors } from 'react-formal'
import {
  CONTACT_EMAIL,
  CONTACT_NAME,
  SHARE_EMAIL,
  PHONE_NUMBER,
  USER_ID
} from '../../../lib/services/userInfo'
import { convertLocalToUTCDate } from '../../../lib/utils/date'
import { isNull, isDefined, noop, debounce } from '../../../lib/utils/common'
import { isPresent } from '../../../lib/utils/collection'
import { contactAgentSchema } from '../../../schemas/contactAgent'

const RANGE_PERIODS = [
  'April to May',
  'April to Labor Day',
  'May to Labor Day',
  'Memorial Day to Labor Day',
  'August through Labor Day',
  'July/August through Labor Day'
]

const AVERAGE_MONTHLY_PRICE = 'Average Monthly Price'

const initialDatesRange = {
  start: null,
  end: null,
  offset: {
    value: 0,
    label: 'Exact Dates'
  }
}

class BaseContactFormStore {
  @observable name = ''
  @observable email = ''
  @observable phone = ''
  @observable message = null
  @observable address = ''
  @observable townName = ''
  @observable pricePeriodText = ''
  @observable listingId = ''
  @observable listingClass = ''
  @observable agentName = ''
  @observable companyName = ''
  @observable agentEmail = ''
  @observable datesRange = { ...initialDatesRange }
  @observable errors = {}
  @observable domainError = ''
  @observable contactMessage = {}

  constructor({ transport, userInfo, gaEvents }) {
    makeObservable(this)

    this.transport = transport
    this.gaEvents = gaEvents
    this.userInfo = userInfo
  }

  @computed
  get fullMessage() {
    if(!isNull(this.message)) {
      return this.message
    } else if (!this.hasListing) {
      return ''
    }

    let text
    if (this.pricePeriodText === '' || this.pricePeriodText === AVERAGE_MONTHLY_PRICE) {
      text = ''
    } else if (RANGE_PERIODS.includes(this.pricePeriodText)) {
      text = ` from ${this.pricePeriodText}`
    } else {
      text = ` for ${this.pricePeriodText}`
    }

    const fullAddress = isNull(this.address) ? 'this home' : `${this.address} in ${this.townName}`

    return `${this.agentName ? 'Hi ' + this.agentName + ',\n' : ''}\
I’m interested in getting more info about ${fullAddress}\
${text}. Please let me know, thanks!`
  }

  @computed
  get toForm() {
    return {
      name: this.name,
      email: this.email,
      phone: this.phone,
      message: this.fullMessage,
      datesRange: this.datesRange
    }
  }

  @computed
  get formErrors() {
    return toJS(this.errors)
  }

  @computed
  get hasListing() {
    return isDefined(this.listingId) && isDefined(this.listingClass)
  }

  @computed
  get hasErrors() {
    return isPresent(this.errors)
  }

  @computed
  get hasDatesRange() {
    return isPresent(this.datesRange.start) && isPresent(this.datesRange.end)
  }

  @computed
  get sanitizedRange() {
    const hasStart = isPresent(this.datesRange.start)
    const hasEnd = isPresent(this.datesRange.end)
    const offset = this.datesRange.offset.value

    if (hasStart && hasEnd) {
      return {
        start: convertLocalToUTCDate(this.datesRange.start),
        end: convertLocalToUTCDate(this.datesRange.end),
        offset
      }
    }

    return null
  }

  @action('[BaseContactFormStore] Set Initial Data')
  setInitialData({
    userInfo,
    address,
    townName,
    listingId,
    listingClass,
    pricePeriod,
    agentInfo = {}
  }) {
    this.name = userInfo.name
      || this.userInfo.getInfo(CONTACT_NAME)
    this.email = userInfo.email
      || this.userInfo.getInfo(CONTACT_EMAIL)
      || this.userInfo.getInfo(SHARE_EMAIL)
    this.phone = userInfo.phone
      || this.userInfo.getInfo(PHONE_NUMBER)
    this.userId = userInfo.id
      || this.userInfo.getInfo(USER_ID)
    this.address = isDefined(address) ? address : null
    this.townName = townName

    this.listingId = listingId
    this.listingClass = listingClass
    this.pricePeriodText = isDefined(pricePeriod) ? pricePeriod : ''

    this.agentName = agentInfo.agentName
    this.companyName = agentInfo.companyName
    this.agentEmail = agentInfo.email
  }

  @action('[BaseContactFormStore] Reset Fields')
  resetFields = () => {
    this.name = ''
    this.email = ''
    this.phone = ''
    this.datesRange = { ...initialDatesRange }
    this.message = null
  }

  @action('[BaseContactFormStore] Reset datesRange')
  resetDatesRange = () => {
    this.datesRange = { ...initialDatesRange }
  }

  @action('[BaseContactFormStore] setErrors')
  setErrors = v => {
    this.errors = v
  }

  @action('[BaseContactFormStore] setField')
  setField = (name, value) => {
    this[name] = value
    this.errors = {}
  }

  @action('[BaseContactFormStore] setDatesRange')
  setDatesRange = value => {
    this.datesRange = value
  }

  @action('[BaseContactFormStore] setDomainErrors')
  setDomainError = v => {
    this.domainError = v
  }

  @action('[BaseContactFormStore] validateDomain')
  validateEmailDomain = value => {
    this.transport.User.validateEmailDomain(value)
      .then(response => {
        if (response.errors) {
          this.setDomainError(response.errors.domain)
        } else {
          this.setDomainError('')
        }
      })
      .catch(noop)
  }

  @action('[BaseContactFormStore] set contact message')
  setContactMessage = ({ text, type }) => {
    this.contactMessage = { text: text, type: type }
  }

  validateDomain = debounce(value => {
    this.validateEmailDomain(value)
  }, 500)

  isFieldInvalid = fieldName => {
    return fieldName in this.formErrors
  }

  validateAt = path => {
    this.setErrors({})
    try {
      contactAgentSchema.validateSyncAt(path, this.toForm)
    }
    catch (e) {
      this.setErrors(toFormErrors(e))
    }
  }
}

export default BaseContactFormStore
