import { observer } from 'mobx-react'
import { compose, renderComponent } from 'recompose'
import { cond } from '../../components/Hocs'
import MapViewType from './MapViewType'
import VideoViewType from './VideoViewType'

const ViewTypes = compose(
  observer,
  cond(({ contentType }) => contentType,
    {
      map: renderComponent(MapViewType),
      video: renderComponent(VideoViewType)
    }
  )
)

export default ViewTypes()
