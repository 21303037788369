import React from 'react'
import PropTypes from 'prop-types'
import { inject } from 'mobx-react'
import { withProps, withHandlers, compose, branch, renderNothing, renderComponent, setDisplayName } from 'recompose'

const Button = ({ activateMapView }) => (
  <button
    className='Button Button--link Button--iconPrefix Button--iconVideo'
    onClick={activateMapView}
  >
    Video Tour
  </button>
)

Button.propTypes = {
  photoGalleryUIState: PropTypes.shape({
    changeContentType: PropTypes.func.isRequired
  }),
  activateMapView: PropTypes.func.isRequired
}

const VideoLink = compose(
  setDisplayName('VideoLink'),
  inject('store'),
  withProps(({ store }) => ({
    photoGalleryUIState: store.photoGalleryUIStateStore,
    hdpStore: store.hdpStore
  })),
  withHandlers({
    activateMapView: props => () => {
      props.photoGalleryUIState.changeContentType('video')
      document.body.classList.add('isModalActive')
    }
  }),
  branch(
    ({ hdpStore }) => hdpStore.videoTour,
    renderComponent(Button),
    renderNothing
  ),
)

export default VideoLink()
