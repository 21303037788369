import React, { Fragment, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes, observer } from 'mobx-react'
import { useGoogleMap, Polygon, Polyline } from '@react-google-maps/api'
import { refProp } from '../../../lib/propTypes'
import DrawSearchActionBar from './DrawSearchActionBar'
import useFreehandDrawing from './hooks/useFreehandDrawing'
import { searchPolygonStyle } from './constants'

const DrawLayer = ({
  mapStore,
  header,
  searchPolygon,
  onSubmitSearch,
  onSearchPolygonChanged,
  containerRef
}) => {
  const map = useGoogleMap()

  const polyline = useRef(null)
  const { setFreehandDrawingOptions, resetFreehandDrawingOptions } = useFreehandDrawing(containerRef)

  const handeSubmit = e => {
    e.stopPropagation()
    onSubmitSearch()
  }

  const handlePolylineLoad = polylineInstance => {
    polyline.current = polylineInstance
  }

  const onMouseMove = e => {
    if (mapStore.isFreehandDrawing) {
      const latLng = e.latLng
      polyline.current.getPath().push(latLng)
      mapStore.setRawPolyline(latLng)
    }
  }

  const startFreeHandDrawing = () => {
    mapStore.setFreehandDrawing(true)
    mapStore.resetRawPolyline()
  }

  const stopFreehandDrawing = () => {
    mapStore.setFreehandDrawing(false)
    mapStore.calculatePolygon()
    onSearchPolygonChanged(mapStore.polygon)
  }

  const removeSearchPolygon = () => {
    mapStore.resetPolygon()
    onSearchPolygonChanged(mapStore.polygon)
  }

  const enableDrawingMode = () => {
    setFreehandDrawingOptions({
      handleStart: startFreeHandDrawing,
      handleStop: stopFreehandDrawing
    })

    mapStore.setDrawingUIMode(true)
  }

  const disableDrawingMode = () => {
    resetFreehandDrawingOptions({
      handleStart: startFreeHandDrawing,
      handleStop: stopFreehandDrawing
    })

    mapStore.resetPolygon()
    mapStore.resetRawPolyline()
    onSearchPolygonChanged(mapStore.polygon)
    mapStore.setDrawingUIMode(false)
  }

  useEffect(() => {
    mapStore.setPolygon(searchPolygon || [])
  }, [searchPolygon])

  useEffect(() => {
    const listener = map.addListener('mousemove', onMouseMove)

    return () => {
      listener.remove()
    }
  }, [])

  return (
    <Fragment>
      <DrawSearchActionBar
        header={header}
        mapStore={mapStore}
        onSubmitSearch={handeSubmit}
        removeSearchPolygon={removeSearchPolygon}
        enableDrawingMode={enableDrawingMode}
        disableDrawingMode={disableDrawingMode}
      />
      <Polyline
        visible={mapStore.isFreehandDrawing}
        onLoad={handlePolylineLoad}
        options={{ strokeWeight: 2 }}
        path={mapStore.polyline}
      />
      <Polygon
        visible={!mapStore.isFreehandDrawing}
        path={mapStore.polygon}
        options={searchPolygonStyle}
      />
    </Fragment>
  )
}

DrawLayer.propTypes = {
  mapStore: PropTypes.shape({
    polyline: MobxPropTypes.arrayOrObservableArray,
    setRawPolyline: PropTypes.func.isRequired,
    resetRawPolyline: PropTypes.func.isRequired,
    setPolygon: PropTypes.func.isRequired,
    resetPolygon: PropTypes.func.isRequired,
    polygon: MobxPropTypes.arrayOrObservableArray,
    calculatePolygon: PropTypes.func.isRequired,
    setDrawingUIMode: PropTypes.func.isRequired,
    isFreehandDrawing: PropTypes.bool,
    setFreehandDrawing: PropTypes.func.isRequired
  }).isRequired,
  header: PropTypes.string,
  searchPolygon: MobxPropTypes.arrayOrObservableArray,
  onSubmitSearch: PropTypes.func.isRequired,
  onSearchPolygonChanged: PropTypes.func.isRequired,
  containerRef: refProp
}
export default observer(DrawLayer)
