import React from 'react'
import { observer } from 'mobx-react'
import { setDisplayName } from '../../Hocs'
import Modal from '../../Shared/Modal'
import { useStores } from '../../../shared_components/Hooks'

const Zillow3DTourModal = () => {
  const { store: { hdpStore, uiStateStore } } = useStores()
  const deactivateModal = () => {
    uiStateStore.toggleZillow3DTourModal(false)
  }

  return (
    <Modal
      isOpen={uiStateStore.isZillow3DTourModalOpen}
      onRequestClose={deactivateModal}
      ariaHideApp={false}
      portalClassName='ReactModalPortal'
      overlayClassName='Modal Modal--fullScreen'
    >
      <div className='Zillow3DTour Zillow3DTour--modal'>
        <div className='Zillow3DTour-content'>
          <iframe
            title='3D TOUR'
            src={hdpStore.zillow3dTour}
            height='100%'
            width='100%'
            allowFullScreen='1'/>
          />
        </div>
      </div>
    </Modal>
  )
}

export default setDisplayName('Zillow3DTourModal')(observer(Zillow3DTourModal))
