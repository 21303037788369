import React from 'react'
import PropTypes from 'prop-types'
import { CheckyOptionTemplate, MapOptionsTemplate, TreeTownSelect } from '../../../../Shared/Form/TownSelect'
import { toJS } from 'mobx'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import Checkbox from '../../../../Shared/Checkbox'

const Location = ({ valueStateStore }) => (
  <fieldset className='ListingForm-groupFieldset'>
    <legend className='ListingForm-groupLabel'>
      Location
    </legend>
    <div className='ListingForm-fields ListingForm-fields--highZindex'>
      <div className='ListingForm-fieldRow'>
        <div
          className='ListingForm-input ListingForm-input--fullWidth ListingForm-input--height50'
          data-qa-advanced-town-input
        >
          <TreeTownSelect
            id='location'
            className='Multiselect--noBorders Multiselect--checky Multiselect--noPaddings'
            placeholder='Where are you searching?'
            height={50}
            options={toJS(valueStateStore.treeTownOptions)}
            optionsById={valueStateStore.optionsById}
            selectedOptionsWithParents={valueStateStore.selectedOptionsWithParents}
            setSelectedOptionsGroup={valueStateStore.setSelectedOptionsGroup}
            selectedOptionsGroup={toJS(valueStateStore.selectedOptionsGroup)}
            value={toJS(valueStateStore.values.townNames)}
            wrapperOptionsComponent={MapOptionsTemplate}
            onChange={({value}) => { valueStateStore.setInputValue('townNames', value) }}
            optionTemplate={CheckyOptionTemplate}
          />
        </div>
      </div>
      <div className='ListingForm-fieldRow ListingForm-fieldRow--pb0'>
        <Checkbox
          name='videoTour'
          wrapperClassName='ListingForm-highlight'
          onChange={({target}) => { valueStateStore.setInputValue('videoTour', target.checked) }}
          checked={valueStateStore.values.videoTour || false}
          label='Listings with video tour'
          additionalLabel='NEW'
        />
      </div>
      <div className='ListingForm-fieldRow'>
        <Checkbox
          name='zillow3dTour'
          wrapperClassName='ListingForm-highlight'
          onChange={({target}) => { valueStateStore.setInputValue('zillow3dTour', target.checked) }}
          checked={valueStateStore.values.zillow3dTour || false}
          label='Listings with 3D tour'
          additionalLabel='NEW'
        />
      </div>
    </div>
  </fieldset>
)

Location.propTypes = {
  valueStateStore: PropTypes.shape({
    values: PropTypes.object,
    treeTownOptions: PropTypes.array,
    setInputValue: PropTypes.func,
    selectedOptionsWithParents: PropTypes.array,
    selectedOptionsGroup: MobxPropTypes.arrayOrObservableArray,
    setSelectedOptionsGroup: PropTypes.func,
    optionsById: PropTypes.object.isRequired
  })
}

export default Location
